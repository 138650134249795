@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}
.auth0-lock-input-show-password .auth0-lock-show-password {
  top: calc(50% - 7px) !important;
}
.auth0-global-message-success {
  background: #54c0e8 !important;
  text-transform: none !important;
}
.auth0-global-message-error {
  background: #f5333f !important;
  text-transform: none !important;
}
.auth0-lock.auth0-lock .auth0-lock-error .auth0-lock-input-wrap {
  border-color: #ad2a00 !important;
  transition: 0.3s ease-in-out;
}
.auth0-lock-submit {
  width: 60%;
  max-width: 60% !important;
  margin: 0px auto !important;
  margin-bottom: 2rem !important;
  border-radius: 5px !important;
  padding: 5px !important;
  background: #021d49 !important;
}
.auth0-lock.auth0-lock .auth0-lock-header-bg {
  background: #ffffff !important;
}
.auth0-lock.auth0-lock-opened {
  background: -prefix-linear-gradient(top, #014cbc, #0b192e);
  background: linear-gradient(to bottom, #014cbc, #0b192e);
}
.auth0-lock.auth0-lock .auth0-lock-overlay {
  background: #cacaca00 !important;
  opacity: 1 !important;
}

.auth0-lock.auth0-lock .auth0-lock-widget {
  min-width: 360px !important;
}
.auth0-lock-header-bg-blur {
  opacity: 0 !important;
}
.auth0-label-submit {
  text-transform: capitalize !important;
  width: 90% !important;
}
.auth0-label-submit svg {
}
.auth0-lock-alternative-link {
  color: #54c0e8 !important;
}
.auth0-lock-input-wrap {
  padding-left: 0 !important;
}
svg.auth0-lock-icon.auth0-lock-icon-box,
svg.icon-text {
  display: none !important;
}
.auth0-lock-header-logo {
  height: 100px !important;
}
.auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
  font-size: 16px !important;
  height: 60px !important;
  border: 1px solid #97a3ae !important;
  border-radius: 5px !important;
}
.auth0-lock-input-wrap {
  border: 0px !important;
  border-radius: 5px !important;
}
.auth0-lock.auth0-lock .auth0-lock-input-block {
  margin-bottom: 25px !important;
}

label.input-label-smbc {
  position: absolute;
  left: 10px;
  top: calc(50% - 0.5rem);
  font-size: 14px;
  color: #cacaca;
  /*transform: translateY(0px);*/
  transition: transform 0.5s, color 0.3s, top 0.3s;
  cursor: pointer;
  pointer-events: none;
  background: #fff;
  padding: 0px 1rem;
}
input.auth0-lock-input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input.auth0-lock-input:focus + label.input-label-smbc {
  /*transform: translateY(-50%) scale(.85);*/
  transform: scale(0.85);
  top: -0.5rem;
  transform-origin: top left;
  color: #c0c0c0;
}
input.auth0-lock-input:not(:placeholder-shown) + label.input-label-smbc {
  /*transform: translateY(-50%) scale(.85);*/
  transform: scale(0.85);
  top: -0.5rem;
  transform-origin: top left;
  color: #c0c0c0;
}
.btn-show-password-smbc {
  position: absolute;
  width: 22px;
  height: 22px;
  background: #fff;
  z-index: 1;
  border-radius: 10px;
  right: 10px;
  top: calc(50% - 11px);
  cursor: pointer !important;
}
.icon-eye-smbc {
  display: block !important;
  filter: invert(85%);
  display: block !important;
  display: block;
}
.btn-show-password-smbc.show .icon-eye-smbc {
  filter: invert(50%);
}

.icon-arrow {
  width: 20px;
  height: 20px;
  display: inline-block;
  filter: invert(1);
  transform: rotate(180deg);
  float: right;
  display: block !important;
  position: relative;
  top: calc(50% - 10px);
}
.text-light-blue {
  color: #54c0e8 !important;
}
