c-wiz {
  contain: style;
}
c-wiz > c-data {
  display: none;
}
c-wiz.rETSD {
  contain: none;
}
c-wiz.Ubi8Z {
  contain: layout style;
}
.zOO9Bf {
  display: flex;
  height: 24px;
  justify-content: center;
}
.QqG8ie {
  justify-content: flex-start;
}
.zOO9Bf.KWNUGc {
  height: auto;
  min-height: 24px;
  padding-top: 24px;
}
.pTbIkd {
  align-items: center;
  border-bottom: 1px solid #ccc;
  display: flex;
  height: 36px;
  left: 0;
  padding: 0 16px;
  position: absolute;
  right: 0;
  top: 0;
}
.pTbIkd .gl8sfb {
  height: 14px;
  margin-right: 12px;
}
.pTbIkd .WM3GF {
  display: block;
  position: relative;
  top: 1px;
  height: 14px;
  width: 14px;
}
.Bw8v1 {
  color: #5f6368;
  font-size: 14px;
  height: 14px;
  margin-top: -2px;
}
.QZdAxd {
  color: #1a73e8;
}
.YNY4de {
  height: 24px;
  margin: 0 0;
  overflow: visible;
  position: relative;
  width: 75px;
}
.Bym39d .xduoyf {
  display: block;
}
@keyframes quantumWizBoxInkSpread {
  0% {
    transform: translate(-50%, -50%) scale(0.2);
  }
  to {
    transform: translate(-50%, -50%) scale(2.2);
  }
}
@keyframes quantumWizIconFocusPulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(2);
    opacity: 1;
  }
}
@keyframes quantumWizRadialInkSpread {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(2.5);
    opacity: 1;
  }
}
@keyframes quantumWizRadialInkFocusPulse {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  to {
    transform: scale(2.5);
    opacity: 1;
  }
}
.stUf5b.WS4XDd {
  border: 0;
  max-height: 1.3333333em;
  padding: 0 2px;
  vertical-align: middle;
  width: auto;
}
.G5XIyb {
  border: 0;
  object-fit: contain;
}
.G5XIyb.WS4XDd {
  border: 0;
  max-height: 1.3333333em;
  padding: 0 2px;
  vertical-align: middle;
  width: auto;
}
.QHyIAd {
  display: block;
  height: 37px;
  width: 37px;
}
:root {
  --wf-tfs: calc(((var(--c-tfs, 32)) * 0.0625) * 1rem);
  --wf-tfs-bp3: calc(((var(--c-tfs, 36)) * 0.0625) * 1rem);
  --wf-tfs-bp5: calc(((var(--c-tfs, 44)) * 0.0625) * 1rem);
  --wf-stfs: calc(((var(--c-stfs, 16)) * 0.0625) * 1rem);
  --wf-stfs-bp5: calc(((var(--c-stfs, 18)) * 0.0625) * 1rem);
}
.Wf6lSd {
  display: flex;
  justify-content: flex-start;
  height: 48px;
  height: var(--c-brsz, 48px);
}
.bEbGY {
  justify-content: center;
}
.njAxm {
  height: auto;
  padding-top: 24px;
  min-height: 48px;
  min-height: var(--c-brsz, 48px);
}
.no7ij {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  height: 36px;
  left: 0;
  padding: 0 16px;
  position: absolute;
  right: 0;
  top: 0;
}
.WHUyzc {
  color: #444746;
  font-size: 14px;
  height: 14px;
  margin-top: -2px;
}
.no7ij .PDfDtf {
  height: 14px;
  margin-right: 12px;
}
.no7ij .th8JXc {
  display: block;
  height: 14px;
  position: relative;
  top: 1px;
  width: 14px;
}
.D9CCrb {
  color: #0b57d0;
}
.Qk3oof.WS4XDd {
  border: 0;
  max-height: 1.42857143em;
  padding: 0 2px;
  vertical-align: middle;
  width: auto;
}
.uHLU0 {
  border: 0;
  object-fit: contain;
}
.uHLU0.WS4XDd {
  border: 0;
  max-height: 1.42857143em;
  padding: 0 2px;
  vertical-align: middle;
  width: auto;
}
.dJRqtd {
  display: block;
  height: 48px;
  height: var(--c-brsz, 48px);
  width: 48px;
  width: var(--c-brsz, 48px);
}
.PrDSKc {
  padding-bottom: 3px;
  padding-top: 9px;
}
.v42QC {
  padding-bottom: 3px;
  padding-top: 9px;
  margin: 0;
}
.PrDSKc:empty,
.v42QC:empty {
  display: none;
}
.dMNVAe {
  padding-bottom: 3px;
  padding-top: 9px;
}
.FSdAW {
  padding-bottom: 3px;
  padding-top: 9px;
  margin: 0;
}
.dMNVAe:empty,
.FSdAW:empty {
  display: none;
}
@keyframes mdc-ripple-fg-radius-in {
  0% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0))
      scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  0% {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  0% {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.VfPpkd-ksKsZd-XxIAqe {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  outline: none;
  overflow: hidden;
}
.VfPpkd-ksKsZd-XxIAqe::before,
.VfPpkd-ksKsZd-XxIAqe::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.VfPpkd-ksKsZd-XxIAqe::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}
.VfPpkd-ksKsZd-XxIAqe::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-mWPk3d::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-mWPk3d::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-ksKsZd-XxIAqe::before,
.VfPpkd-ksKsZd-XxIAqe::after {
  top: calc(50% - 100%);
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-mWPk3d::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded],
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd {
  overflow: visible;
}
.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded]::before,
.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded]::after,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd::before,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd::after {
  top: calc(50% - 50%);
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded].VfPpkd-ksKsZd-mWPk3d::before,
.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded].VfPpkd-ksKsZd-mWPk3d::after,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd.VfPpkd-ksKsZd-mWPk3d::before,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd.VfPpkd-ksKsZd-mWPk3d::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded].VfPpkd-ksKsZd-mWPk3d::after,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd.VfPpkd-ksKsZd-mWPk3d::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-ksKsZd-XxIAqe::before,
.VfPpkd-ksKsZd-XxIAqe::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, #000);
}
.VfPpkd-ksKsZd-XxIAqe:hover::before,
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe::before,
.VfPpkd-ksKsZd-XxIAqe:not(.VfPpkd-ksKsZd-mWPk3d):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.VfPpkd-ksKsZd-XxIAqe:not(.VfPpkd-ksKsZd-mWPk3d)::after {
  transition: opacity 150ms linear;
}
.VfPpkd-ksKsZd-XxIAqe:not(.VfPpkd-ksKsZd-mWPk3d):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-ksKsZd-XxIAqe.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-dgl2Hf-ppHlrf-sM5MNb {
  display: inline;
}
.VfPpkd-Bz112c-LgbsSe {
  font-size: 24px;
  width: 48px;
  height: 48px;
  padding: 12px;
}
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-J1Ukfc-LhBDec {
  max-height: 48px;
  max-width: 48px;
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-Bz112c-LgbsSe-OWXEXe-e5LLRc-SxQuSe
  .VfPpkd-Bz112c-Jh9lGc {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-Bz112c-LgbsSe-OWXEXe-e5LLRc-SxQuSe
  .VfPpkd-Bz112c-J1Ukfc-LhBDec {
  max-height: 40px;
  max-width: 40px;
}
.VfPpkd-Bz112c-LgbsSe:disabled {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}
.VfPpkd-Bz112c-LgbsSe svg,
.VfPpkd-Bz112c-LgbsSe img {
  width: 24px;
  height: 24px;
}
.VfPpkd-Bz112c-LgbsSe {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  z-index: 0;
  overflow: visible;
}
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-RLmnJb {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
@media screen and (forced-colors: active) {
  .VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
    .VfPpkd-Bz112c-J1Ukfc-LhBDec,
  .VfPpkd-Bz112c-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):focus
    .VfPpkd-Bz112c-J1Ukfc-LhBDec {
    display: block;
  }
}
.VfPpkd-Bz112c-LgbsSe:disabled {
  cursor: default;
  pointer-events: none;
}
.VfPpkd-Bz112c-LgbsSe[hidden] {
  display: none;
}
.VfPpkd-Bz112c-LgbsSe-OWXEXe-KVuj8d-Q3DXx {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.VfPpkd-Bz112c-J1Ukfc-LhBDec {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-Bz112c-J1Ukfc-LhBDec {
    border-color: CanvasText;
  }
}
.VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
    border-color: CanvasText;
  }
}
.VfPpkd-Bz112c-kBDsod {
  display: inline-block;
}
.VfPpkd-Bz112c-kBDsod.VfPpkd-Bz112c-kBDsod-OWXEXe-IT5dJd,
.VfPpkd-Bz112c-LgbsSe-OWXEXe-IT5dJd .VfPpkd-Bz112c-kBDsod {
  display: none;
}
.VfPpkd-Bz112c-LgbsSe-OWXEXe-IT5dJd
  .VfPpkd-Bz112c-kBDsod.VfPpkd-Bz112c-kBDsod-OWXEXe-IT5dJd {
  display: inline-block;
}
.VfPpkd-Bz112c-mRLv6 {
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.VfPpkd-Bz112c-LgbsSe {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd
  .VfPpkd-Bz112c-Jh9lGc::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc
  .VfPpkd-Bz112c-Jh9lGc::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf
  .VfPpkd-Bz112c-Jh9lGc::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, #000);
}
.VfPpkd-Bz112c-LgbsSe:hover .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-Bz112c-Jh9lGc::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-Bz112c-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.VfPpkd-Bz112c-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Bz112c-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-Bz112c-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-Bz112c-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-Bz112c-LgbsSe:disabled:hover .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe:disabled.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-Bz112c-Jh9lGc::before {
  opacity: 0;
  opacity: var(--mdc-ripple-hover-opacity, 0);
}
.VfPpkd-Bz112c-LgbsSe:disabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-Bz112c-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-focus-opacity, 0);
}
.VfPpkd-Bz112c-LgbsSe:disabled:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-Bz112c-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-Bz112c-LgbsSe:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-Bz112c-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-press-opacity, 0);
}
.VfPpkd-Bz112c-LgbsSe:disabled.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0);
}
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.VfPpkd-LgbsSe {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  background: transparent;
}
.VfPpkd-LgbsSe .VfPpkd-BFbNVe-bF1uUb {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.VfPpkd-LgbsSe::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.VfPpkd-LgbsSe:active {
  outline: none;
}
.VfPpkd-LgbsSe:hover {
  cursor: pointer;
}
.VfPpkd-LgbsSe:disabled {
  cursor: default;
  pointer-events: none;
}
.VfPpkd-LgbsSe[hidden] {
  display: none;
}
.VfPpkd-LgbsSe .VfPpkd-kBDsod {
  margin-left: 0;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
[dir="rtl"] .VfPpkd-LgbsSe .VfPpkd-kBDsod,
.VfPpkd-LgbsSe .VfPpkd-kBDsod[dir="rtl"] {
  margin-left: 8px;
  margin-right: 0;
}
.VfPpkd-LgbsSe .VfPpkd-UdE5de-uDEFge {
  font-size: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  line-height: normal;
}
.VfPpkd-LgbsSe .VfPpkd-vQzf8d {
  position: relative;
}
.VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  display: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec {
    border-color: CanvasText;
  }
}
.VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec::after {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
    .VfPpkd-J1Ukfc-LhBDec,
  .VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-J1Ukfc-LhBDec {
    display: block;
  }
}
.VfPpkd-LgbsSe .VfPpkd-RLmnJb {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.VfPpkd-vQzf8d + .VfPpkd-kBDsod {
  margin-left: 8px;
  margin-right: 0;
}
[dir="rtl"] .VfPpkd-vQzf8d + .VfPpkd-kBDsod,
.VfPpkd-vQzf8d + .VfPpkd-kBDsod[dir="rtl"] {
  margin-left: 0;
  margin-right: 8px;
}
svg.VfPpkd-kBDsod {
  fill: currentColor;
}
.VfPpkd-LgbsSe-OWXEXe-dgl2Hf {
  margin-top: 6px;
  margin-bottom: 6px;
}
.VfPpkd-LgbsSe {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}
.VfPpkd-LgbsSe {
  padding: 0 8px 0 8px;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ.VfPpkd-LgbsSe-OWXEXe-Bz112c-UbuQg {
  padding: 0 12px 0 16px;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ.VfPpkd-LgbsSe-OWXEXe-Bz112c-M1Soyc {
  padding: 0 16px 0 12px;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb.VfPpkd-LgbsSe-OWXEXe-Bz112c-UbuQg {
  padding: 0 12px 0 16px;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb.VfPpkd-LgbsSe-OWXEXe-Bz112c-M1Soyc {
  padding: 0 16px 0 12px;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc {
  border-style: solid;
  transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc .VfPpkd-Jh9lGc {
  border-style: solid;
  border-color: transparent;
}
.VfPpkd-LgbsSe {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
  z-index: 0;
}
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Jh9lGc::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Jh9lGc::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd .VfPpkd-Jh9lGc::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc .VfPpkd-Jh9lGc::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf .VfPpkd-Jh9lGc::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Jh9lGc::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-Jh9lGc {
  position: absolute;
  box-sizing: content-box;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.VfPpkd-LgbsSe {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0892857143em;
  font-weight: 500;
  text-transform: uppercase;
  height: 36px;
  border-radius: 4px;
}
.VfPpkd-LgbsSe:not(:disabled) {
  color: #6200ee;
}
.VfPpkd-LgbsSe:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-LgbsSe .VfPpkd-kBDsod {
  font-size: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
}
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
  background-color: #6200ee;
}
.VfPpkd-LgbsSe:hover .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
}
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(
    --mdc-text-button-pressed-state-layer-opacity,
    0.12
  );
}
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc {
  border-radius: 4px;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0892857143em;
  font-weight: 500;
  text-transform: uppercase;
  height: 36px;
  border-radius: 4px;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(:disabled) {
  background-color: #6200ee;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(:disabled) {
  color: #fff;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-kBDsod {
  font-size: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-Jh9lGc::after {
  background-color: #fff;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ:hover .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-k8QpJ.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-Jh9lGc::before {
  opacity: 0.08;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(
    --mdc-filled-button-pressed-state-layer-opacity,
    0.24
  );
}
.VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-Jh9lGc {
  border-radius: 4px;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0892857143em;
  font-weight: 500;
  text-transform: uppercase;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:not(:disabled) {
  background-color: #6200ee;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:not(:disabled) {
  color: #fff;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb .VfPpkd-kBDsod {
  font-size: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-MV7yeb .VfPpkd-Jh9lGc::after {
  background-color: #fff;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:hover .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-MV7yeb.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-Jh9lGc::before {
  opacity: 0.08;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(
    --mdc-protected-button-pressed-state-layer-opacity,
    0.24
  );
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb .VfPpkd-Jh9lGc {
  border-radius: 4px;
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe,
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:not(.VfPpkd-ksKsZd-mWPk3d):focus {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:not(:disabled):active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-MV7yeb:disabled {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0892857143em;
  font-weight: 500;
  text-transform: uppercase;
  height: 36px;
  border-radius: 4px;
  padding: 0 15px 0 15px;
  border-width: 1px;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc:not(:disabled) {
  color: #6200ee;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc .VfPpkd-kBDsod {
  font-size: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-INsAgc .VfPpkd-Jh9lGc::after {
  background-color: #6200ee;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc:hover .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-INsAgc.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe-OWXEXe-INsAgc:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(
    --mdc-outlined-button-pressed-state-layer-opacity,
    0.12
  );
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc .VfPpkd-Jh9lGc {
  border-radius: 4px;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc:disabled {
  border-color: rgba(0, 0, 0, 0.12);
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc.VfPpkd-LgbsSe-OWXEXe-Bz112c-UbuQg {
  padding: 0 11px 0 15px;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc.VfPpkd-LgbsSe-OWXEXe-Bz112c-M1Soyc {
  padding: 0 15px 0 11px;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc .VfPpkd-Jh9lGc {
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-width: 1px;
}
.VfPpkd-LgbsSe-OWXEXe-INsAgc .VfPpkd-RLmnJb {
  left: -1px;
  width: calc(100% + 2px);
}
.nCP5yc {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0107142857em;
  font-weight: 500;
  text-transform: none;
  transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: none;
}
.nCP5yc .VfPpkd-Jh9lGc {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}
.nCP5yc:not(:disabled) {
  background-color: rgb(26, 115, 232);
  background-color: var(--gm-fillbutton-container-color, rgb(26, 115, 232));
}
.nCP5yc:not(:disabled) {
  color: #fff;
  color: var(--gm-fillbutton-ink-color, #fff);
}
.nCP5yc:disabled {
  background-color: rgba(60, 64, 67, 0.12);
  background-color: var(
    --gm-fillbutton-disabled-container-color,
    rgba(60, 64, 67, 0.12)
  );
}
.nCP5yc:disabled {
  color: rgba(60, 64, 67, 0.38);
  color: var(--gm-fillbutton-disabled-ink-color, rgba(60, 64, 67, 0.38));
}
.nCP5yc .VfPpkd-Jh9lGc::before,
.nCP5yc .VfPpkd-Jh9lGc::after {
  background-color: rgb(32, 33, 36);
  background-color: var(--gm-fillbutton-state-color, rgb(32, 33, 36));
}
.nCP5yc:hover .VfPpkd-Jh9lGc::before,
.nCP5yc.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.16;
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
.nCP5yc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-Jh9lGc::before,
.nCP5yc:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.nCP5yc:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.nCP5yc:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.2;
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
.nCP5yc.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
.nCP5yc .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.nCP5yc .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
.nCP5yc .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: #fff;
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .nCP5yc .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
  .nCP5yc .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.nCP5yc:hover {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0
      var(--gm-fillbutton-keyshadow-color, rgba(60, 64, 67, 0.3)),
    0 1px 3px 1px
      var(--gm-fillbutton-ambientshadow-color, rgba(60, 64, 67, 0.15));
}
.nCP5yc:hover .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.nCP5yc:active {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0
      var(--gm-fillbutton-keyshadow-color, rgba(60, 64, 67, 0.3)),
    0 2px 6px 2px
      var(--gm-fillbutton-ambientshadow-color, rgba(60, 64, 67, 0.15));
}
.nCP5yc:active .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.nCP5yc:disabled {
  box-shadow: none;
}
.nCP5yc:disabled:hover .VfPpkd-Jh9lGc::before,
.nCP5yc:disabled.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0;
  opacity: var(--mdc-ripple-hover-opacity, 0);
}
.nCP5yc:disabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.nCP5yc:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-focus-opacity, 0);
}
.nCP5yc:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.nCP5yc:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-press-opacity, 0);
}
.nCP5yc:disabled.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0);
}
.nCP5yc:disabled .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.Rj2Mlf {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0107142857em;
  font-weight: 500;
  text-transform: none;
  transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: none;
}
.Rj2Mlf .VfPpkd-Jh9lGc {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}
.Rj2Mlf:not(:disabled) {
  color: rgb(26, 115, 232);
  color: var(--gm-hairlinebutton-ink-color, rgb(26, 115, 232));
}
.Rj2Mlf:not(:disabled) {
  border-color: rgb(218, 220, 224);
  border-color: var(--gm-hairlinebutton-outline-color, rgb(218, 220, 224));
}
.Rj2Mlf:not(:disabled):hover {
  border-color: rgb(218, 220, 224);
  border-color: var(--gm-hairlinebutton-outline-color, rgb(218, 220, 224));
}
.Rj2Mlf:not(:disabled).VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe,
.Rj2Mlf:not(:disabled):not(.VfPpkd-ksKsZd-mWPk3d):focus {
  border-color: rgb(23, 78, 166);
  border-color: var(
    --gm-hairlinebutton-outline-color--stateful,
    rgb(23, 78, 166)
  );
}
.Rj2Mlf:not(:disabled):active,
.Rj2Mlf:not(:disabled):focus:active {
  border-color: rgb(218, 220, 224);
  border-color: var(--gm-hairlinebutton-outline-color, rgb(218, 220, 224));
}
.Rj2Mlf:disabled {
  color: rgba(60, 64, 67, 0.38);
  color: var(--gm-hairlinebutton-disabled-ink-color, rgba(60, 64, 67, 0.38));
}
.Rj2Mlf:disabled {
  border-color: rgba(60, 64, 67, 0.12);
  border-color: var(
    --gm-hairlinebutton-disabled-outline-color,
    rgba(60, 64, 67, 0.12)
  );
}
.Rj2Mlf:hover:not(:disabled),
.Rj2Mlf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:disabled),
.Rj2Mlf:not(.VfPpkd-ksKsZd-mWPk3d):focus:not(:disabled),
.Rj2Mlf:active:not(:disabled) {
  color: rgb(23, 78, 166);
  color: var(--gm-hairlinebutton-ink-color--stateful, rgb(23, 78, 166));
}
.Rj2Mlf .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.Rj2Mlf .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
.Rj2Mlf .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: rgb(26, 115, 232);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .Rj2Mlf .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
  .Rj2Mlf .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.Rj2Mlf .VfPpkd-Jh9lGc::before,
.Rj2Mlf .VfPpkd-Jh9lGc::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--gm-hairlinebutton-state-color, rgb(26, 115, 232));
}
.Rj2Mlf:hover .VfPpkd-Jh9lGc::before,
.Rj2Mlf.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.Rj2Mlf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-Jh9lGc::before,
.Rj2Mlf:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.Rj2Mlf:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.Rj2Mlf:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.Rj2Mlf.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.Rj2Mlf:disabled:hover .VfPpkd-Jh9lGc::before,
.Rj2Mlf:disabled.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0;
  opacity: var(--mdc-ripple-hover-opacity, 0);
}
.Rj2Mlf:disabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.Rj2Mlf:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-focus-opacity, 0);
}
.Rj2Mlf:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.Rj2Mlf:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-press-opacity, 0);
}
.Rj2Mlf:disabled.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0);
}
.b9hyVd {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0107142857em;
  font-weight: 500;
  text-transform: none;
  transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  border-width: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0
      var(--gm-protectedbutton-keyshadow-color, rgba(60, 64, 67, 0.3)),
    0 1px 3px 1px
      var(--gm-protectedbutton-ambientshadow-color, rgba(60, 64, 67, 0.15));
}
.b9hyVd .VfPpkd-Jh9lGc {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}
.b9hyVd:not(:disabled) {
  background-color: #fff;
  background-color: var(--gm-protectedbutton-container-color, #fff);
}
.b9hyVd:not(:disabled) {
  color: rgb(26, 115, 232);
  color: var(--gm-protectedbutton-ink-color, rgb(26, 115, 232));
}
.b9hyVd:disabled {
  background-color: rgba(60, 64, 67, 0.12);
  background-color: var(
    --gm-protectedbutton-disabled-container-color,
    rgba(60, 64, 67, 0.12)
  );
}
.b9hyVd:disabled {
  color: rgba(60, 64, 67, 0.38);
  color: var(--gm-protectedbutton-disabled-ink-color, rgba(60, 64, 67, 0.38));
}
.b9hyVd:hover:not(:disabled),
.b9hyVd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:disabled),
.b9hyVd:not(.VfPpkd-ksKsZd-mWPk3d):focus:not(:disabled),
.b9hyVd:active:not(:disabled) {
  color: rgb(23, 78, 166);
  color: var(--gm-protectedbutton-ink-color--stateful, rgb(23, 78, 166));
}
.b9hyVd .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.b9hyVd .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
.b9hyVd .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: rgb(26, 115, 232);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .b9hyVd .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
  .b9hyVd .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.b9hyVd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe,
.b9hyVd:not(.VfPpkd-ksKsZd-mWPk3d):focus {
  border-width: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0
      var(--gm-protectedbutton-keyshadow-color, rgba(60, 64, 67, 0.3)),
    0 1px 3px 1px
      var(--gm-protectedbutton-ambientshadow-color, rgba(60, 64, 67, 0.15));
}
.b9hyVd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-BFbNVe-bF1uUb,
.b9hyVd:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.b9hyVd:hover {
  border-width: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0
      var(--gm-protectedbutton-keyshadow-color, rgba(60, 64, 67, 0.3)),
    0 2px 6px 2px
      var(--gm-protectedbutton-ambientshadow-color, rgba(60, 64, 67, 0.15));
}
.b9hyVd:hover .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.b9hyVd:not(:disabled):active {
  border-width: 0;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
    0 4px 8px 3px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 3px 0
      var(--gm-protectedbutton-keyshadow-color, rgba(60, 64, 67, 0.3)),
    0 4px 8px 3px
      var(--gm-protectedbutton-ambientshadow-color, rgba(60, 64, 67, 0.15));
}
.b9hyVd:not(:disabled):active .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.b9hyVd .VfPpkd-Jh9lGc::before,
.b9hyVd .VfPpkd-Jh9lGc::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--gm-protectedbutton-state-color, rgb(26, 115, 232));
}
.b9hyVd:hover .VfPpkd-Jh9lGc::before,
.b9hyVd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.b9hyVd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-Jh9lGc::before,
.b9hyVd:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.b9hyVd:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.b9hyVd:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.b9hyVd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.b9hyVd:disabled {
  box-shadow: none;
}
.b9hyVd:disabled .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.b9hyVd:disabled:hover .VfPpkd-Jh9lGc::before,
.b9hyVd:disabled.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0;
  opacity: var(--mdc-ripple-hover-opacity, 0);
}
.b9hyVd:disabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.b9hyVd:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-focus-opacity, 0);
}
.b9hyVd:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.b9hyVd:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-press-opacity, 0);
}
.b9hyVd:disabled.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0);
}
.Kjnxrf {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0107142857em;
  font-weight: 500;
  text-transform: none;
  transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: none;
}
.Kjnxrf .VfPpkd-Jh9lGc {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}
.Kjnxrf:not(:disabled) {
  background-color: rgb(232, 240, 254);
}
.Kjnxrf:not(:disabled) {
  color: rgb(25, 103, 210);
}
.Kjnxrf:disabled {
  background-color: rgba(60, 64, 67, 0.12);
}
.Kjnxrf:disabled {
  color: rgba(60, 64, 67, 0.38);
}
.Kjnxrf:hover:not(:disabled),
.Kjnxrf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:disabled),
.Kjnxrf:not(.VfPpkd-ksKsZd-mWPk3d):focus:not(:disabled),
.Kjnxrf:active:not(:disabled) {
  color: rgb(23, 78, 166);
}
.Kjnxrf .VfPpkd-Jh9lGc::before,
.Kjnxrf .VfPpkd-Jh9lGc::after {
  background-color: rgb(25, 103, 210);
  background-color: var(--mdc-ripple-color, rgb(25, 103, 210));
}
.Kjnxrf:hover .VfPpkd-Jh9lGc::before,
.Kjnxrf.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.Kjnxrf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-Jh9lGc::before,
.Kjnxrf:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.Kjnxrf:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.Kjnxrf:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.Kjnxrf.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.Kjnxrf .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.Kjnxrf .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
.Kjnxrf .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: rgb(25, 103, 210);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .Kjnxrf .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
  .Kjnxrf .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.Kjnxrf:hover {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.Kjnxrf:hover .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.Kjnxrf:not(:disabled):active {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
}
.Kjnxrf:not(:disabled):active .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.Kjnxrf:disabled {
  box-shadow: none;
}
.Kjnxrf:disabled .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.Kjnxrf:disabled:hover .VfPpkd-Jh9lGc::before,
.Kjnxrf:disabled.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0;
  opacity: var(--mdc-ripple-hover-opacity, 0);
}
.Kjnxrf:disabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.Kjnxrf:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-focus-opacity, 0);
}
.Kjnxrf:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.Kjnxrf:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-press-opacity, 0);
}
.Kjnxrf:disabled.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0);
}
.ksBjEc {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0107142857em;
  font-weight: 500;
  text-transform: none;
}
.ksBjEc .VfPpkd-Jh9lGc {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}
.ksBjEc:not(:disabled) {
  background-color: transparent;
}
.ksBjEc:not(:disabled) {
  color: rgb(26, 115, 232);
  color: var(--gm-colortextbutton-ink-color, rgb(26, 115, 232));
}
.ksBjEc:disabled {
  color: rgba(60, 64, 67, 0.38);
  color: var(--gm-colortextbutton-disabled-ink-color, rgba(60, 64, 67, 0.38));
}
.ksBjEc .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
.ksBjEc .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: rgb(26, 115, 232);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .ksBjEc .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
  .ksBjEc .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.ksBjEc:hover:not(:disabled),
.ksBjEc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:disabled),
.ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d):focus:not(:disabled),
.ksBjEc:active:not(:disabled) {
  color: rgb(23, 78, 166);
  color: var(--gm-colortextbutton-ink-color--stateful, rgb(23, 78, 166));
}
.ksBjEc .VfPpkd-Jh9lGc::before,
.ksBjEc .VfPpkd-Jh9lGc::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--gm-colortextbutton-state-color, rgb(26, 115, 232));
}
.ksBjEc:hover .VfPpkd-Jh9lGc::before,
.ksBjEc.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.ksBjEc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-Jh9lGc::before,
.ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.ksBjEc.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.ksBjEc:disabled:hover .VfPpkd-Jh9lGc::before,
.ksBjEc:disabled.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0;
  opacity: var(--mdc-ripple-hover-opacity, 0);
}
.ksBjEc:disabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.ksBjEc:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-focus-opacity, 0);
}
.ksBjEc:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.ksBjEc:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-press-opacity, 0);
}
.ksBjEc:disabled.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0);
}
.LjDxcd {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0107142857em;
  font-weight: 500;
  text-transform: none;
}
.LjDxcd .VfPpkd-Jh9lGc {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}
.LjDxcd:not(:disabled) {
  color: rgb(95, 99, 104);
  color: var(--gm-neutraltextbutton-ink-color, rgb(95, 99, 104));
}
.LjDxcd:disabled {
  color: rgba(60, 64, 67, 0.38);
  color: var(--gm-neutraltextbutton-disabled-ink-color, rgba(60, 64, 67, 0.38));
}
.LjDxcd:hover:not(:disabled),
.LjDxcd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:disabled),
.LjDxcd:not(.VfPpkd-ksKsZd-mWPk3d):focus:not(:disabled),
.LjDxcd:active:not(:disabled) {
  color: rgb(32, 33, 36);
  color: var(--gm-neutraltextbutton-ink-color--stateful, rgb(32, 33, 36));
}
.LjDxcd .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
.LjDxcd .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: rgb(95, 99, 104);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .LjDxcd .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-uI4vCe-LkdAo,
  .LjDxcd .VfPpkd-UdE5de-uDEFge .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.LjDxcd .VfPpkd-Jh9lGc::before,
.LjDxcd .VfPpkd-Jh9lGc::after {
  background-color: rgb(95, 99, 104);
  background-color: var(--gm-neutraltextbutton-state-color, rgb(95, 99, 104));
}
.LjDxcd:hover .VfPpkd-Jh9lGc::before,
.LjDxcd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.LjDxcd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-Jh9lGc::before,
.LjDxcd:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.LjDxcd:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.LjDxcd:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.LjDxcd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.LjDxcd:disabled:hover .VfPpkd-Jh9lGc::before,
.LjDxcd:disabled.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0;
  opacity: var(--mdc-ripple-hover-opacity, 0);
}
.LjDxcd:disabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Jh9lGc::before,
.LjDxcd:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-focus-opacity, 0);
}
.LjDxcd:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}
.LjDxcd:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
  transition-duration: 75ms;
  opacity: 0;
  opacity: var(--mdc-ripple-press-opacity, 0);
}
.LjDxcd:disabled.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0);
}
.DuMIQc {
  padding: 0 24px 0 24px;
}
.P62QJc {
  padding: 0 23px 0 23px;
  border-width: 1px;
}
.P62QJc.VfPpkd-LgbsSe-OWXEXe-Bz112c-UbuQg {
  padding: 0 11px 0 23px;
}
.P62QJc.VfPpkd-LgbsSe-OWXEXe-Bz112c-M1Soyc {
  padding: 0 23px 0 11px;
}
.P62QJc .VfPpkd-Jh9lGc {
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-width: 1px;
}
.P62QJc .VfPpkd-RLmnJb {
  left: -1px;
  width: calc(100% + 2px);
}
.yHy1rc {
  z-index: 0;
}
.yHy1rc .VfPpkd-Bz112c-Jh9lGc::before,
.yHy1rc .VfPpkd-Bz112c-Jh9lGc::after {
  z-index: -1;
}
.yHy1rc:disabled {
  color: rgba(60, 64, 67, 0.38);
  color: var(--gm-iconbutton-disabled-ink-color, rgba(60, 64, 67, 0.38));
}
.fzRBVc {
  z-index: 0;
}
.fzRBVc .VfPpkd-Bz112c-Jh9lGc::before,
.fzRBVc .VfPpkd-Bz112c-Jh9lGc::after {
  z-index: -1;
}
.fzRBVc:disabled {
  color: rgba(60, 64, 67, 0.38);
  color: var(--gm-iconbutton-disabled-ink-color, rgba(60, 64, 67, 0.38));
}
.WpHeLc {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  outline: none;
}
[dir="rtl"] .HDnnrf .VfPpkd-kBDsod,
.HDnnrf .VfPpkd-kBDsod[dir="rtl"] {
  transform: scaleX(-1);
}
[dir="rtl"] .QDwDD,
.QDwDD[dir="rtl"] {
  transform: scaleX(-1);
}
.PDpWxe {
  will-change: unset;
}
.LQeN7 .VfPpkd-J1Ukfc-LhBDec {
  pointer-events: none;
  border: 2px solid rgb(24, 90, 188);
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .LQeN7 .VfPpkd-J1Ukfc-LhBDec {
    border-color: CanvasText;
  }
}
.LQeN7 .VfPpkd-J1Ukfc-LhBDec::after {
  content: "";
  border: 2px solid rgb(232, 240, 254);
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .LQeN7 .VfPpkd-J1Ukfc-LhBDec::after {
    border-color: CanvasText;
  }
}
.LQeN7.gmghec .VfPpkd-J1Ukfc-LhBDec {
  display: inline-block;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .LQeN7.gmghec .VfPpkd-J1Ukfc-LhBDec {
    display: none;
  }
}
.mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec {
  pointer-events: none;
  border: 2px solid rgb(24, 90, 188);
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
@media screen and (forced-colors: active) {
  .mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec {
    border-color: CanvasText;
  }
}
.mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
  content: "";
  border: 2px solid rgb(232, 240, 254);
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
    border-color: CanvasText;
  }
}
.mN1ivc.gmghec .VfPpkd-Bz112c-J1Ukfc-LhBDec {
  display: inline-block;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mN1ivc.gmghec .VfPpkd-Bz112c-J1Ukfc-LhBDec {
    display: none;
  }
}
.MyRpB .VfPpkd-kBDsod,
.MyRpB .VfPpkd-vQzf8d {
  opacity: 0;
}
[data-tooltip-enabled="true"]:disabled,
.VfPpkd-Bz112c-LgbsSe[data-tooltip-enabled="true"]:disabled
  .VfPpkd-Bz112c-Jh9lGc {
  pointer-events: auto;
}
.VfPpkd-xl07Ob-XxIAqe {
  display: none;
  position: absolute;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  transform-origin-left: top left;
  transform-origin-right: top right;
}
.VfPpkd-xl07Ob-XxIAqe:focus {
  outline: none;
}
.VfPpkd-xl07Ob-XxIAqe-OWXEXe-oT9UPb-FNFY6c {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}
.VfPpkd-xl07Ob-XxIAqe-OWXEXe-FNFY6c {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}
.VfPpkd-xl07Ob-XxIAqe-OWXEXe-oT9UPb-xTMeO {
  display: inline-block;
  opacity: 0;
}
[dir="rtl"] .VfPpkd-xl07Ob-XxIAqe,
.VfPpkd-xl07Ob-XxIAqe[dir="rtl"] {
  transform-origin-left: top right;
  transform-origin-right: top left;
}
.VfPpkd-xl07Ob-XxIAqe-OWXEXe-oYxtQd {
  position: relative;
  overflow: visible;
}
.VfPpkd-xl07Ob-XxIAqe-OWXEXe-qbOKL {
  position: fixed;
}
.VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb {
  width: 100%;
}
.VfPpkd-xl07Ob-XxIAqe {
  max-width: calc(100vw - 32px);
  max-width: var(--mdc-menu-max-width, calc(100vw - 32px));
  max-height: calc(100vh - 32px);
  max-height: var(--mdc-menu-max-height, calc(100vh - 32px));
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1),
    height 0.25s cubic-bezier(0, 0, 0.2, 1);
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  border-radius: var(--mdc-shape-medium, 4px);
}
.VfPpkd-xl07Ob-XxIAqe-OWXEXe-oT9UPb-xTMeO {
  transition: opacity 75ms linear;
}
.VfPpkd-StrnGf-rymPhb {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle1-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.VfPpkd-StrnGf-rymPhb:focus {
  outline: none;
}
.VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
  color: rgba(0, 0, 0, 0.54);
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.VfPpkd-StrnGf-rymPhb-f7MjDc {
  background-color: transparent;
}
.VfPpkd-StrnGf-rymPhb-f7MjDc {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.VfPpkd-StrnGf-rymPhb-IhFlZd {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c {
  opacity: 0.38;
}
.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b {
  color: #6200ee;
  color: var(--mdc-theme-primary, #6200ee);
}
.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-StrnGf-rymPhb-f7MjDc {
  color: #6200ee;
  color: var(--mdc-theme-primary, #6200ee);
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}
.VfPpkd-StrnGf-rymPhb-Tkg0ld {
  display: block;
}
.VfPpkd-StrnGf-rymPhb-ibnC6b {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
}
.VfPpkd-StrnGf-rymPhb-ibnC6b:focus {
  outline: none;
}
.VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd):focus::before,
.VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd):focus::before,
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe::before {
    border-color: CanvasText;
  }
}
.VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd::before {
    border-color: CanvasText;
  }
}
[dir="rtl"] .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 16px;
  padding-right: 16px;
  height: 56px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 16px;
  padding-right: 16px;
  height: 56px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 16px;
  padding-right: 16px;
  height: 56px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 16px;
  padding-right: 16px;
  height: 72px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: 16px;
  height: 72px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
[dir="rtl"] .VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 16px;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-f7MjDc {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  margin-left: 0;
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir="rtl"] .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 32px;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 32px;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 16px;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 16px;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 16px;
  width: 56px;
  height: 56px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 16px;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 16px;
  width: 100px;
  height: 56px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 16px;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-f7MjDc {
  display: inline-flex;
}
.VfPpkd-StrnGf-rymPhb-IhFlZd {
  margin-left: auto;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-IhFlZd:not(.HzV7m-fuEl3d) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-caption-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.75rem;
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-caption-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] .VfPpkd-StrnGf-rymPhb-IhFlZd,
[dir="rtl"] .VfPpkd-StrnGf-rymPhb-ibnC6b .VfPpkd-StrnGf-rymPhb-IhFlZd {
  margin-left: 0;
  margin-right: auto;
}
.VfPpkd-StrnGf-rymPhb-b9t22c {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.VfPpkd-StrnGf-rymPhb-b9t22c[for] {
  pointer-events: none;
}
.VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::before,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::before,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::before,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::before,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::after,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::after,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::after,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::after,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-body2-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.875rem;
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
  font-size: inherit;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc .VfPpkd-StrnGf-rymPhb-ibnC6b {
  height: 40px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc .VfPpkd-StrnGf-rymPhb-b9t22c {
  align-self: flex-start;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc .VfPpkd-StrnGf-rymPhb-ibnC6b {
  height: 64px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b {
  height: 72px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-f7MjDc {
  align-self: flex-start;
  margin-top: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aSi1db-RWgCYc.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc
  .VfPpkd-StrnGf-rymPhb-ibnC6b {
  height: 60px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc
  .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb.VfPpkd-StrnGf-rymPhb-OWXEXe-EzIYc
  .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 16px;
  margin-right: 0;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b {
  cursor: pointer;
}
a.VfPpkd-StrnGf-rymPhb-ibnC6b {
  color: inherit;
  text-decoration: none;
}
.VfPpkd-StrnGf-rymPhb-clz4Ic {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.VfPpkd-StrnGf-rymPhb-clz4Ic {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-nNtqDd {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir="rtl"] .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-nNtqDd,
.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-nNtqDd[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir="rtl"] .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe,
.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe[dir="rtl"] {
  margin-left: 0;
  margin-right: 72px;
}
.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-nNtqDd {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-nNtqDd,
.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-nNtqDd[dir="rtl"] {
  margin-left: 0;
  margin-right: 72px;
}
.VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc,
.VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  width: calc(100% - 16px);
}
.VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg,
.VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2,
.VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2,
.VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc[dir="rtl"] {
  margin-left: 0;
  margin-right: 72px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  width: calc(100% - 16px);
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg[dir="rtl"] {
  margin-left: 0;
  margin-right: 72px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-Bz112c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc[dir="rtl"] {
  margin-left: 0;
  margin-right: 72px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  width: calc(100% - 16px);
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg[dir="rtl"] {
  margin-left: 0;
  margin-right: 72px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-YLEF4c-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc[dir="rtl"] {
  margin-left: 0;
  margin-right: 72px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  width: calc(100% - 16px);
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg[dir="rtl"] {
  margin-left: 0;
  margin-right: 72px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-JUCs7e-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc {
  margin-left: 88px;
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc[dir="rtl"] {
  margin-left: 0;
  margin-right: 88px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  width: calc(100% - 16px);
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  margin-left: 88px;
  margin-right: 0;
  width: calc(100% - 104px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg[dir="rtl"] {
  margin-left: 0;
  margin-right: 88px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-HiaYvf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc {
  margin-left: 116px;
  margin-right: 0;
  width: calc(100% - 116px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc[dir="rtl"] {
  margin-left: 0;
  margin-right: 116px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  width: calc(100% - 16px);
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  margin-left: 116px;
  margin-right: 0;
  width: calc(100% - 132px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg[dir="rtl"] {
  margin-left: 0;
  margin-right: 116px;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2 {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2 {
  margin-left: 0;
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir="rtl"]
  .VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2,
.VfPpkd-StrnGf-rymPhb-OWXEXe-aTv5jf-rymPhb
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 0;
}
.VfPpkd-StrnGf-rymPhb-JNdkSc .VfPpkd-StrnGf-rymPhb {
  padding: 0;
}
.VfPpkd-StrnGf-rymPhb-oT7voc {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle1-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: 0.75rem 16px;
}
.VfPpkd-rymPhb-fpDzbe-fmcmS {
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.VfPpkd-rymPhb-L8ivfd-fmcmS {
  color: rgba(0, 0, 0, 0.54);
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.VfPpkd-rymPhb-bC5pod-fmcmS {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c .VfPpkd-rymPhb-JMEf7e {
  background-color: transparent;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c .VfPpkd-rymPhb-JMEf7e {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.VfPpkd-rymPhb-JMEf7e {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-Gtdoyb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-JMEf7e {
  opacity: 0.38;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-fpDzbe-fmcmS {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-L8ivfd-fmcmS {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-bC5pod-fmcmS {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c
  .VfPpkd-rymPhb-JMEf7e {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-fpDzbe-fmcmS,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-rymPhb-fpDzbe-fmcmS {
  color: #6200ee;
  color: var(--mdc-theme-primary, #6200ee);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb {
  color: #6200ee;
  color: var(--mdc-theme-primary, #6200ee);
}
.VfPpkd-StrnGf-rymPhb-oT7voc {
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.VfPpkd-rymPhb-clz4Ic::after {
  border-bottom-color: white;
}
.VfPpkd-rymPhb {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle1-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  line-height: 1.5rem;
}
.VfPpkd-rymPhb-fpDzbe-fmcmS {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle1-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
}
.VfPpkd-rymPhb-L8ivfd-fmcmS {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-body2-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.875rem;
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}
.VfPpkd-rymPhb-bC5pod-fmcmS {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-overline-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.75rem;
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  -webkit-text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c .VfPpkd-rymPhb-KkROqb {
  width: 40px;
  height: 40px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-rymPhb-KkROqb {
  width: 24px;
  height: 24px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e .VfPpkd-rymPhb-KkROqb {
  width: 40px;
  height: 40px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf .VfPpkd-rymPhb-KkROqb {
  width: 56px;
  height: 56px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf .VfPpkd-rymPhb-KkROqb {
  width: 100px;
  height: 56px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b .VfPpkd-rymPhb-KkROqb {
  width: 40px;
  height: 40px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc .VfPpkd-rymPhb-KkROqb {
  width: 36px;
  height: 20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c .VfPpkd-rymPhb-JMEf7e {
  width: 24px;
  height: 24px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-MPu53c .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-GCYh9b .VfPpkd-rymPhb-JMEf7e {
  width: 40px;
  height: 40px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-scr2fc .VfPpkd-rymPhb-JMEf7e {
  width: 36px;
  height: 20px;
}
.VfPpkd-rymPhb-oT7voc {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle1-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
}
.VfPpkd-rymPhb-clz4Ic {
  background-color: rgba(0, 0, 0, 0.12);
}
.VfPpkd-rymPhb-clz4Ic {
  height: 1px;
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .VfPpkd-rymPhb-clz4Ic::after {
    content: "";
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}
.VfPpkd-rymPhb {
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
}
.VfPpkd-rymPhb:focus {
  outline: none;
}
.VfPpkd-rymPhb-Tkg0ld {
  display: block;
}
.VfPpkd-rymPhb-ibnC6b {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  align-items: stretch;
  cursor: pointer;
}
.VfPpkd-rymPhb-ibnC6b:focus {
  outline: none;
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 48px;
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 64px;
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb {
  height: 88px;
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc
  .VfPpkd-rymPhb-KkROqb {
  align-self: center;
  margin-top: 0;
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-KkROqb {
  align-self: flex-start;
  margin-top: 16px;
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc
  .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-JMEf7e {
  align-self: center;
  margin-top: 0;
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-JMEf7e {
  align-self: flex-start;
  margin-top: 16px;
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me,
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-tPcied-hXIJHe {
  cursor: auto;
}
.VfPpkd-rymPhb-ibnC6b:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd):focus::before,
.VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-rymPhb-ibnC6b:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd):focus::before,
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe::before {
    border-color: CanvasText;
  }
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd::before {
    border-color: CanvasText;
  }
}
.VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:focus::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:focus::before {
    border-color: CanvasText;
  }
}
a.VfPpkd-rymPhb-ibnC6b {
  color: inherit;
  text-decoration: none;
}
.VfPpkd-rymPhb-KkROqb {
  fill: currentColor;
  flex-shrink: 0;
  pointer-events: none;
}
.VfPpkd-rymPhb-JMEf7e {
  flex-shrink: 0;
  pointer-events: none;
}
.VfPpkd-rymPhb-Gtdoyb {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  flex: 1;
  pointer-events: none;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-Gtdoyb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb .VfPpkd-rymPhb-Gtdoyb {
  align-self: stretch;
}
.VfPpkd-rymPhb-Gtdoyb[for] {
  pointer-events: none;
}
.VfPpkd-rymPhb-fpDzbe-fmcmS {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-fpDzbe-fmcmS,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-L8ivfd-fmcmS {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-L8ivfd-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb .VfPpkd-rymPhb-L8ivfd-fmcmS {
  white-space: normal;
  line-height: 20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-BYmFj .VfPpkd-rymPhb-L8ivfd-fmcmS {
  white-space: nowrap;
  line-height: auto;
}
.VfPpkd-rymPhb-bC5pod-fmcmS {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c .VfPpkd-rymPhb-KkROqb {
  margin-left: 16px;
  margin-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c
  .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 16px;
  margin-right: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 56px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 72px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-YLEF4c .VfPpkd-rymPhb-KkROqb {
  border-radius: 50%;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-rymPhb-KkROqb {
  margin-left: 16px;
  margin-right: 32px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 32px;
  margin-right: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 56px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 72px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e .VfPpkd-rymPhb-KkROqb {
  margin-left: 16px;
  margin-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e
  .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 16px;
  margin-right: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 56px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 72px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf .VfPpkd-rymPhb-KkROqb {
  margin-left: 16px;
  margin-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf
  .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 16px;
  margin-right: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 72px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-KkROqb {
  align-self: flex-start;
  margin-top: 8px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf .VfPpkd-rymPhb-KkROqb {
  margin-left: 0;
  margin-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf
  .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 16px;
  margin-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 72px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c .VfPpkd-rymPhb-KkROqb {
  margin-left: 8px;
  margin-right: 24px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c
  .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 24px;
  margin-right: 8px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-KkROqb {
  align-self: flex-start;
  margin-top: 8px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 56px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 72px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b .VfPpkd-rymPhb-KkROqb {
  margin-left: 8px;
  margin-right: 24px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b
  .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 24px;
  margin-right: 8px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-KkROqb {
  align-self: flex-start;
  margin-top: 8px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 56px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 72px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc .VfPpkd-rymPhb-KkROqb {
  margin-left: 16px;
  margin-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc
  .VfPpkd-rymPhb-KkROqb,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc
  .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 16px;
  margin-right: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-KkROqb {
  align-self: flex-start;
  margin-top: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-bC5pod-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 56px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 72px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c .VfPpkd-rymPhb-JMEf7e {
  margin-left: 16px;
  margin-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c
  .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c
  .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 16px;
  margin-right: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-JMEf7e {
  align-self: flex-start;
  margin-top: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf .VfPpkd-rymPhb-JMEf7e {
  margin-left: 28px;
  margin-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 16px;
  margin-right: 28px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf .VfPpkd-rymPhb-JMEf7e {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-caption-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.75rem;
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-caption-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-MPu53c.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-MPu53c.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-MPu53c.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-MPu53c .VfPpkd-rymPhb-JMEf7e {
  margin-left: 24px;
  margin-right: 8px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-MPu53c
  .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-MPu53c
  .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 8px;
  margin-right: 24px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-MPu53c.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-JMEf7e {
  align-self: flex-start;
  margin-top: 8px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-GCYh9b.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-GCYh9b.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-GCYh9b.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-GCYh9b .VfPpkd-rymPhb-JMEf7e {
  margin-left: 24px;
  margin-right: 8px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-GCYh9b
  .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-GCYh9b
  .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 8px;
  margin-right: 24px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-GCYh9b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-JMEf7e {
  align-self: flex-start;
  margin-top: 8px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-scr2fc.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-scr2fc.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-scr2fc.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-scr2fc .VfPpkd-rymPhb-JMEf7e {
  margin-left: 16px;
  margin-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-scr2fc
  .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-scr2fc
  .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 16px;
  margin-right: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-scr2fc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-JMEf7e {
  align-self: flex-start;
  margin-top: 16px;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-BYmFj.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-BYmFj.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-BYmFj.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-BYmFj.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-vfifzc-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-rymPhb-ibnC6b {
  padding-left: 16px;
  padding-right: 16px;
}
[dir="rtl"] .VfPpkd-rymPhb-ibnC6b,
.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 16px;
}
.VfPpkd-rymPhb-JNdkSc .VfPpkd-StrnGf-rymPhb {
  padding: 0;
}
.VfPpkd-rymPhb-oT7voc {
  margin: 0.75rem 16px;
}
.VfPpkd-rymPhb-clz4Ic {
  padding: 0;
  background-clip: content-box;
}
.VfPpkd-rymPhb-clz4Ic.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-fmcmS.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe {
  padding-left: 16px;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-fmcmS.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-fmcmS.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"] {
  padding-left: auto;
  padding-right: 16px;
}
.VfPpkd-rymPhb-clz4Ic.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-fmcmS.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe {
  padding-left: auto;
  padding-right: 16px;
}
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-fmcmS.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.VfPpkd-rymPhb-clz4Ic.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-fmcmS.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-JUCs7e.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YLEF4c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-MPu53c.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-scr2fc.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"],
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-GCYh9b.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"] {
  padding-left: 16px;
  padding-right: auto;
}
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"]
  .VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"] .VfPpkd-rymPhb-clz4Ic,
.VfPpkd-rymPhb-clz4Ic[dir="rtl"] {
  padding: 0;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-rymPhb-pZXsl::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-rymPhb-pZXsl::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd
  .VfPpkd-rymPhb-pZXsl::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc
  .VfPpkd-rymPhb-pZXsl::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf
  .VfPpkd-rymPhb-pZXsl::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::after {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-rymPhb-pZXsl::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, #000);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, #000);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b:hover
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.12;
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  background-color: #6200ee;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.16;
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.12;
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: #6200ee;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b:hover
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.16;
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.08;
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  background-color: #6200ee;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.12;
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.2;
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.2;
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.08;
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: #6200ee;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.12;
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.2;
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.2;
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-StrnGf-rymPhb-pZXsl,
:not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-rymPhb-pZXsl::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-rymPhb-pZXsl::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd
  .VfPpkd-rymPhb-pZXsl::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc
  .VfPpkd-rymPhb-pZXsl::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf
  .VfPpkd-rymPhb-pZXsl::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::after {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d
  .VfPpkd-rymPhb-pZXsl::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, #000);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b:hover
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.12;
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: #6200ee;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b:hover
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.16;
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.08;
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: #6200ee;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.12;
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.2;
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.2;
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
  .VfPpkd-rymPhb-pZXsl {
  position: relative;
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.VfPpkd-xl07Ob {
  min-width: 112px;
  min-width: var(--mdc-menu-min-width, 112px);
}
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb-IhFlZd,
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb-f7MjDc {
  color: rgba(0, 0, 0, 0.87);
}
.VfPpkd-xl07Ob
  .VfPpkd-xl07Ob-ibnC6b-OWXEXe-eKm5Fc-FNFY6c
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.04;
}
.VfPpkd-xl07Ob
  .VfPpkd-xl07Ob-ibnC6b-OWXEXe-eKm5Fc-FNFY6c
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.04;
}
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb {
  color: rgba(0, 0, 0, 0.87);
}
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb,
.VfPpkd-xl07Ob .VfPpkd-rymPhb {
  position: relative;
}
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb .VfPpkd-BFbNVe-bF1uUb,
.VfPpkd-xl07Ob .VfPpkd-rymPhb .VfPpkd-BFbNVe-bF1uUb {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb::before,
.VfPpkd-xl07Ob .VfPpkd-rymPhb::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb::before,
  .VfPpkd-xl07Ob .VfPpkd-rymPhb::before {
    border-color: CanvasText;
  }
}
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb-clz4Ic {
  margin: 8px 0;
}
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb-ibnC6b {
  user-select: none;
}
.VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me {
  cursor: auto;
}
.VfPpkd-xl07Ob a.VfPpkd-StrnGf-rymPhb-ibnC6b .VfPpkd-StrnGf-rymPhb-b9t22c,
.VfPpkd-xl07Ob a.VfPpkd-StrnGf-rymPhb-ibnC6b .VfPpkd-StrnGf-rymPhb-f7MjDc {
  pointer-events: none;
}
.VfPpkd-qPzbhe-JNdkSc {
  padding: 0;
  fill: currentColor;
}
.VfPpkd-qPzbhe-JNdkSc .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 56px;
  padding-right: 16px;
}
[dir="rtl"] .VfPpkd-qPzbhe-JNdkSc .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-qPzbhe-JNdkSc .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 56px;
}
.VfPpkd-qPzbhe-JNdkSc .VfPpkd-qPzbhe-JNdkSc-Bz112c {
  left: 16px;
  right: auto;
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition-property: visibility;
  transition-delay: 75ms;
}
[dir="rtl"] .VfPpkd-qPzbhe-JNdkSc .VfPpkd-qPzbhe-JNdkSc-Bz112c,
.VfPpkd-qPzbhe-JNdkSc .VfPpkd-qPzbhe-JNdkSc-Bz112c[dir="rtl"] {
  left: auto;
  right: 16px;
}
.VfPpkd-xl07Ob-ibnC6b-OWXEXe-gk6SMd .VfPpkd-qPzbhe-JNdkSc-Bz112c {
  display: inline;
  visibility: visible;
}
.P2Hi5d,
.mkMxfe,
.OBi8lb,
.P9QRxe,
.vqjb4e,
.y8Rdrf,
.DMZ54e {
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.00625em;
  font-weight: 400;
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-IhFlZd,
.mkMxfe .VfPpkd-StrnGf-rymPhb-IhFlZd,
.OBi8lb .VfPpkd-StrnGf-rymPhb-IhFlZd,
.P9QRxe .VfPpkd-StrnGf-rymPhb-IhFlZd,
.vqjb4e .VfPpkd-StrnGf-rymPhb-IhFlZd,
.y8Rdrf .VfPpkd-StrnGf-rymPhb-IhFlZd,
.DMZ54e .VfPpkd-StrnGf-rymPhb-IhFlZd {
  color: rgb(95, 99, 104);
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
.OBi8lb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
.P9QRxe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
.vqjb4e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
.y8Rdrf .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
.DMZ54e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
  color: rgb(60, 64, 67);
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.OBi8lb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.P9QRxe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.vqjb4e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.y8Rdrf .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-StrnGf-rymPhb-b9t22c,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-b9t22c {
  opacity: 0.38;
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b,
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b,
.OBi8lb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.OBi8lb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b,
.P9QRxe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.P9QRxe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b,
.vqjb4e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.vqjb4e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b,
.y8Rdrf .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.y8Rdrf .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b,
.DMZ54e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.DMZ54e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-StrnGf-rymPhb-f7MjDc,
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-StrnGf-rymPhb-f7MjDc,
.OBi8lb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.OBi8lb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-StrnGf-rymPhb-f7MjDc,
.P9QRxe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.P9QRxe .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-StrnGf-rymPhb-f7MjDc,
.vqjb4e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.vqjb4e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-StrnGf-rymPhb-f7MjDc,
.y8Rdrf .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.y8Rdrf .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-StrnGf-rymPhb-f7MjDc,
.DMZ54e .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-StrnGf-rymPhb-f7MjDc {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0;
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.OBi8lb .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.P9QRxe .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.vqjb4e .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.y8Rdrf .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.DMZ54e .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd {
  background-color: rgb(232, 240, 254);
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--mdc-ripple-color, rgb(26, 115, 232));
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d,
.OBi8lb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d,
.P9QRxe
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d,
.vqjb4e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d,
.y8Rdrf
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d,
.DMZ54e
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .P2Hi5d
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .P2Hi5d
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .P2Hi5d
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
  .mkMxfe
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .mkMxfe
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .mkMxfe
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
  .OBi8lb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .OBi8lb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .OBi8lb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
  .P9QRxe
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .P9QRxe
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .P9QRxe
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
  .vqjb4e
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .vqjb4e
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .vqjb4e
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
  .y8Rdrf
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .y8Rdrf
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .y8Rdrf
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS,
  .DMZ54e
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .DMZ54e
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .DMZ54e
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
    color: GrayText;
  }
  .P2Hi5d
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .mkMxfe
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .OBi8lb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .P9QRxe
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .vqjb4e
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .y8Rdrf
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .DMZ54e
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c {
    opacity: 1;
  }
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 24px;
  padding-right: 16px;
}
[dir="rtl"] .P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b,
.P2Hi5d .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 24px;
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc {
  margin-left: 24px;
  margin-right: 0;
  width: calc(100% - 24px);
}
[dir="rtl"] .P2Hi5d .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc,
.P2Hi5d .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc[dir="rtl"] {
  margin-left: 0;
  margin-right: 24px;
}
.P2Hi5d .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  width: calc(100% - 16px);
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  margin-left: 24px;
  margin-right: 0;
  width: calc(100% - 40px);
}
[dir="rtl"]
  .P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg,
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg[dir="rtl"] {
  margin-left: 0;
  margin-right: 24px;
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2 {
  margin-left: 24px;
  margin-right: 0;
  width: calc(100% - 24px);
}
[dir="rtl"]
  .P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2,
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 24px;
}
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2 {
  margin-left: 24px;
  margin-right: 0;
  width: calc(100% - 40px);
}
[dir="rtl"]
  .P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2,
.P2Hi5d
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 24px;
}
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 16px;
}
[dir="rtl"] .mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b .VfPpkd-StrnGf-rymPhb-f7MjDc,
.mkMxfe .VfPpkd-StrnGf-rymPhb-ibnC6b .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 16px;
  margin-right: 0;
}
.mkMxfe .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc {
  margin-left: 56px;
  margin-right: 0;
  width: calc(100% - 56px);
}
[dir="rtl"] .mkMxfe .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc,
.mkMxfe .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc[dir="rtl"] {
  margin-left: 0;
  margin-right: 56px;
}
.mkMxfe .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  width: calc(100% - 16px);
}
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg {
  margin-left: 56px;
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir="rtl"]
  .mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg[dir="rtl"] {
  margin-left: 0;
  margin-right: 56px;
}
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir="rtl"]
  .mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2 {
  margin-left: 16px;
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir="rtl"]
  .mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2,
.mkMxfe
  .VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-M1Soyc.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-UbuQg.VfPpkd-StrnGf-rymPhb-clz4Ic-OWXEXe-YbohUe-QFlW2[dir="rtl"] {
  margin-left: 0;
  margin-right: 16px;
}
.r6B9Fd {
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.00625em;
  font-weight: 400;
}
.r6B9Fd .VfPpkd-rymPhb-fpDzbe-fmcmS {
  color: rgb(60, 64, 67);
}
.r6B9Fd .VfPpkd-rymPhb-L8ivfd-fmcmS,
.r6B9Fd .VfPpkd-rymPhb-bC5pod-fmcmS,
.r6B9Fd .VfPpkd-rymPhb-JMEf7e {
  color: rgb(95, 99, 104);
}
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-rymPhb-KkROqb,
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c .VfPpkd-rymPhb-JMEf7e,
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-fpDzbe-fmcmS,
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-L8ivfd-fmcmS,
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-bC5pod-fmcmS,
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb,
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c
  .VfPpkd-rymPhb-JMEf7e,
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  color: rgb(60, 64, 67);
}
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-KkROqb,
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-Gtdoyb,
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-JMEf7e {
  opacity: 0.38;
}
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-fpDzbe-fmcmS,
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-rymPhb-fpDzbe-fmcmS,
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb,
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb {
  color: rgb(60, 64, 67);
}
.r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0;
}
.r6B9Fd .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd {
  background-color: rgb(232, 240, 254);
}
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before,
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--mdc-ripple-color, rgb(26, 115, 232));
}
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-rymPhb-pZXsl::before,
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.r6B9Fd
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
@media screen and (forced-colors: active) {
  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-fpDzbe-fmcmS,
  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-L8ivfd-fmcmS,
  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-bC5pod-fmcmS,
  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
    .VfPpkd-rymPhb-KkROqb,
  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c
    .VfPpkd-rymPhb-JMEf7e,
  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
    .VfPpkd-rymPhb-JMEf7e {
    color: GrayText;
  }
  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-KkROqb,
  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-Gtdoyb,
  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-JMEf7e {
    opacity: 1;
  }
}
.uTZ9Lb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-KkROqb,
.FvXOfd.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-KkROqb,
.QrsYgb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-KkROqb,
.gfwIBd.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-KkROqb {
  align-self: center;
  margin-top: 0;
}
.HiC7Nc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 56px;
}
.HiC7Nc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-HiaYvf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc,
.HiC7Nc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-aTv5jf.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 72px;
}
.UbEQCe.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"] .UbEQCe.VfPpkd-rymPhb-ibnC6b,
.UbEQCe.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.UbEQCe .VfPpkd-rymPhb-KkROqb {
  margin-left: 16px;
  margin-right: 16px;
}
[dir="rtl"] .UbEQCe .VfPpkd-rymPhb-KkROqb,
.UbEQCe .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 16px;
  margin-right: 16px;
}
.rKASPc.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"] .rKASPc.VfPpkd-rymPhb-ibnC6b,
.rKASPc.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.rKASPc .VfPpkd-rymPhb-KkROqb {
  margin-left: 8px;
  margin-right: 8px;
}
[dir="rtl"] .rKASPc .VfPpkd-rymPhb-KkROqb,
.rKASPc .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 8px;
  margin-right: 8px;
}
.rKASPc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-KkROqb {
  align-self: flex-start;
  margin-top: 8px;
}
.U5k4Fd.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"] .U5k4Fd.VfPpkd-rymPhb-ibnC6b,
.U5k4Fd.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.U5k4Fd .VfPpkd-rymPhb-KkROqb {
  margin-left: 8px;
  margin-right: 8px;
}
[dir="rtl"] .U5k4Fd .VfPpkd-rymPhb-KkROqb,
.U5k4Fd .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 8px;
  margin-right: 8px;
}
.U5k4Fd.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-KkROqb {
  align-self: flex-start;
  margin-top: 8px;
}
.ifEyr.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"] .ifEyr.VfPpkd-rymPhb-ibnC6b,
.ifEyr.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.ifEyr .VfPpkd-rymPhb-KkROqb {
  margin-left: 8px;
  margin-right: 8px;
}
[dir="rtl"] .ifEyr .VfPpkd-rymPhb-KkROqb,
.ifEyr .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 8px;
  margin-right: 8px;
}
.ifEyr.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb .VfPpkd-rymPhb-KkROqb {
  align-self: flex-start;
  margin-top: 8px;
}
.SNowt.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"] .SNowt.VfPpkd-rymPhb-ibnC6b,
.SNowt.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.SNowt .VfPpkd-rymPhb-JMEf7e {
  margin-left: 8px;
  margin-right: 16px;
}
[dir="rtl"] .SNowt .VfPpkd-rymPhb-JMEf7e,
.SNowt .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 16px;
  margin-right: 8px;
}
.tfmWAf.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"] .tfmWAf.VfPpkd-rymPhb-ibnC6b,
.tfmWAf.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.tfmWAf .VfPpkd-rymPhb-JMEf7e {
  margin-left: 8px;
  margin-right: 16px;
}
[dir="rtl"] .tfmWAf .VfPpkd-rymPhb-JMEf7e,
.tfmWAf .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 16px;
  margin-right: 8px;
}
.axtYbd.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"] .axtYbd.VfPpkd-rymPhb-ibnC6b,
.axtYbd.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.axtYbd .VfPpkd-rymPhb-JMEf7e {
  margin-left: 16px;
  margin-right: 24px;
}
[dir="rtl"] .axtYbd .VfPpkd-rymPhb-JMEf7e,
.axtYbd .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 24px;
  margin-right: 16px;
}
.aopLEb.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"] .aopLEb.VfPpkd-rymPhb-ibnC6b,
.aopLEb.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.aopLEb .VfPpkd-rymPhb-JMEf7e {
  margin-left: 16px;
  margin-right: 24px;
}
[dir="rtl"] .aopLEb .VfPpkd-rymPhb-JMEf7e,
.aopLEb .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 24px;
  margin-right: 16px;
}
.zlqiud.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"] .zlqiud.VfPpkd-rymPhb-ibnC6b,
.zlqiud.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.zlqiud .VfPpkd-rymPhb-JMEf7e {
  margin-left: 16px;
  margin-right: 24px;
}
[dir="rtl"] .zlqiud .VfPpkd-rymPhb-JMEf7e,
.zlqiud .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 24px;
  margin-right: 16px;
}
.isC8Y.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe {
  padding-left: 24px;
  padding-right: auto;
}
[dir="rtl"] .isC8Y.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe,
.isC8Y.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-M1Soyc-YbohUe[dir="rtl"] {
  padding-left: auto;
  padding-right: 24px;
}
.MCs1Pd {
  padding-left: 24px;
  padding-right: 24px;
}
[dir="rtl"] .MCs1Pd,
.MCs1Pd[dir="rtl"] {
  padding-left: 24px;
  padding-right: 24px;
}
.e6pQl.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe {
  padding-left: auto;
  padding-right: 24px;
}
[dir="rtl"] .e6pQl.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe,
.e6pQl.VfPpkd-rymPhb-clz4Ic-OWXEXe-SfQLQb-UbuQg-YbohUe[dir="rtl"] {
  padding-left: 24px;
  padding-right: auto;
}
[dir="rtl"] .e6pQl,
.e6pQl[dir="rtl"] {
  padding: 0;
}
.UQ5E0 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
.q6oraf {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
.q6oraf .VfPpkd-StrnGf-rymPhb {
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.00625em;
  font-weight: 400;
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.q6oraf .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-IhFlZd {
  color: rgb(95, 99, 104);
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-b9t22c,
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
  color: rgb(60, 64, 67);
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-b9t22c {
  opacity: 0.38;
}
.q6oraf .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.q6oraf .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-StrnGf-rymPhb-f7MjDc {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0;
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd {
  background-color: rgb(232, 240, 254);
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--mdc-ripple-color, rgb(26, 115, 232));
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.q6oraf
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .q6oraf
    .VfPpkd-StrnGf-rymPhb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .q6oraf
    .VfPpkd-StrnGf-rymPhb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .q6oraf
    .VfPpkd-StrnGf-rymPhb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
    color: GrayText;
  }
  .q6oraf
    .VfPpkd-StrnGf-rymPhb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c {
    opacity: 1;
  }
}
.FliLIb {
  box-sizing: border-box;
  display: inline-block;
}
.FliLIb .u3bW4e {
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.FliLIb.eLNT1d {
  display: none;
}
.FliLIb .q6oraf .VfPpkd-rymPhb,
.FliLIb .ksBjEc {
  font-size: inherit;
}
.FliLIb .TrZEUc .WpHeLc {
  position: absolute;
}
.FliLIb .qIypjc:not(:disabled) {
  color: #fff;
}
.xYnMae .VfPpkd-Jh9lGc {
  box-sizing: content-box;
}
.uRo0Xe .snByac {
  font-weight: 500;
  line-height: 1.4286;
}
.FliLIb .uRo0Xe {
  min-width: 0;
}
.uRo0Xe .snByac {
  margin: 8px 8px;
  text-transform: none;
}
.VfPpkd-JGcpL-uI4vCe-LkdAo,
.VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: #6200ee;
  stroke: var(--mdc-theme-primary, #6200ee);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .VfPpkd-JGcpL-uI4vCe-LkdAo,
  .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.VfPpkd-JGcpL-uI4vCe-u014N {
  stroke: transparent;
}
@keyframes mdc-circular-progress-container-rotate {
  to {
    transform: rotate(1turn);
  }
}
@keyframes mdc-circular-progress-spinner-layer-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(3turn);
  }
}
@keyframes mdc-circular-progress-color-1-fade-in-out {
  from {
    opacity: 0.99;
  }
  25% {
    opacity: 0.99;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 0.99;
  }
  to {
    opacity: 0.99;
  }
}
@keyframes mdc-circular-progress-color-2-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0.99;
  }
  50% {
    opacity: 0.99;
  }
  51% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-color-3-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  75% {
    opacity: 0.99;
  }
  76% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-color-4-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 0.99;
  }
  90% {
    opacity: 0.99;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-left-spin {
  from {
    transform: rotate(265deg);
  }
  50% {
    transform: rotate(130deg);
  }
  to {
    transform: rotate(265deg);
  }
}
@keyframes mdc-circular-progress-right-spin {
  from {
    transform: rotate(-265deg);
  }
  50% {
    transform: rotate(-130deg);
  }
  to {
    transform: rotate(-265deg);
  }
}
.VfPpkd-JGcpL-P1ekSe {
  display: inline-flex;
  position: relative;
  direction: ltr;
  line-height: 0;
  transition: opacity 0.25s 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.VfPpkd-JGcpL-uI4vCe-haAclf,
.VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G,
.VfPpkd-JGcpL-IdXvz-haAclf,
.VfPpkd-JGcpL-QYI5B-pbTTYe {
  position: absolute;
  width: 100%;
  height: 100%;
}
.VfPpkd-JGcpL-uI4vCe-haAclf {
  transform: rotate(-90deg);
}
.VfPpkd-JGcpL-IdXvz-haAclf {
  font-size: 0;
  letter-spacing: 0;
  white-space: nowrap;
  opacity: 0;
}
.VfPpkd-JGcpL-uI4vCe-LkdAo-Bd00G,
.VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  fill: transparent;
}
.VfPpkd-JGcpL-uI4vCe-LkdAo {
  transition: stroke-dashoffset 0.5s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-JGcpL-OcUoKf-TpMipd {
  position: absolute;
  top: 0;
  left: 47.5%;
  box-sizing: border-box;
  width: 5%;
  height: 100%;
  overflow: hidden;
}
.VfPpkd-JGcpL-OcUoKf-TpMipd .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  left: -900%;
  width: 2000%;
  transform: rotate(180deg);
}
.VfPpkd-JGcpL-lLvYUc-e9ayKc {
  display: inline-flex;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.VfPpkd-JGcpL-lLvYUc-e9ayKc .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  width: 200%;
}
.VfPpkd-JGcpL-lLvYUc-qwU8Me .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  left: -100%;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc .VfPpkd-JGcpL-uI4vCe-haAclf {
  opacity: 0;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc .VfPpkd-JGcpL-IdXvz-haAclf {
  opacity: 1;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc .VfPpkd-JGcpL-IdXvz-haAclf {
  animation: mdc-circular-progress-container-rotate 1.5682352941176s linear
    infinite;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc .VfPpkd-JGcpL-QYI5B-pbTTYe {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms
    cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc .VfPpkd-JGcpL-Ydhldb-R6PoUb {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms
      cubic-bezier(0.4, 0, 0.2, 1) infinite both,
    mdc-circular-progress-color-1-fade-in-out 5332ms
      cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc .VfPpkd-JGcpL-Ydhldb-ibL1re {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms
      cubic-bezier(0.4, 0, 0.2, 1) infinite both,
    mdc-circular-progress-color-2-fade-in-out 5332ms
      cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc .VfPpkd-JGcpL-Ydhldb-c5RTEf {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms
      cubic-bezier(0.4, 0, 0.2, 1) infinite both,
    mdc-circular-progress-color-3-fade-in-out 5332ms
      cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc .VfPpkd-JGcpL-Ydhldb-II5mzb {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms
      cubic-bezier(0.4, 0, 0.2, 1) infinite both,
    mdc-circular-progress-color-4-fade-in-out 5332ms
      cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc
  .VfPpkd-JGcpL-lLvYUc-LK5yu
  .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  animation: mdc-circular-progress-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-A9y3zc
  .VfPpkd-JGcpL-lLvYUc-qwU8Me
  .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  animation: mdc-circular-progress-right-spin 1333ms
    cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.VfPpkd-JGcpL-P1ekSe-OWXEXe-xTMeO {
  opacity: 0;
}
.DU29of {
  position: relative;
}
.DU29of .VfPpkd-JGcpL-uI4vCe-LkdAo,
.DU29of .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: #4285f4;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .DU29of .VfPpkd-JGcpL-uI4vCe-LkdAo,
  .DU29of .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.DU29of .VfPpkd-JGcpL-Ydhldb-R6PoUb .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: #4285f4;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .DU29of .VfPpkd-JGcpL-Ydhldb-R6PoUb .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.DU29of .VfPpkd-JGcpL-Ydhldb-ibL1re .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: #ea4335;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .DU29of .VfPpkd-JGcpL-Ydhldb-ibL1re .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.DU29of .VfPpkd-JGcpL-Ydhldb-c5RTEf .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: #fbbc04;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .DU29of .VfPpkd-JGcpL-Ydhldb-c5RTEf .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.DU29of .VfPpkd-JGcpL-Ydhldb-II5mzb .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
  stroke: #34a853;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .DU29of .VfPpkd-JGcpL-Ydhldb-II5mzb .VfPpkd-JGcpL-IdXvz-LkdAo-Bd00G {
    stroke: CanvasText;
  }
}
.DU29of .VfPpkd-JGcpL-Mr8B3-V67aGc {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}
.VfPpkd-BFbNVe-bF1uUb {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  transition: opacity 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
  background-color: var(--mdc-elevation-overlay-color, #fff);
}
.NZp2ef {
  background-color: #e8eaed;
}
.VfPpkd-z59Tgd {
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}
.VfPpkd-Djsh7e-XxIAqe-ma6Yeb,
.VfPpkd-Djsh7e-XxIAqe-cGMI2b {
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}
.VfPpkd-z59Tgd {
  color: white;
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.VfPpkd-z59Tgd {
  background-color: rgba(0, 0, 0, 0.6);
}
.VfPpkd-MlC99b {
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
}
.VfPpkd-IqDDtd {
  color: rgba(0, 0, 0, 0.6);
}
.VfPpkd-IqDDtd-hSRGPd {
  color: #6200ee;
  color: var(--mdc-theme-primary, #6200ee);
}
.VfPpkd-suEOdc.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd,
.VfPpkd-suEOdc.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-Djsh7e-XxIAqe-ma6Yeb,
.VfPpkd-suEOdc.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-Djsh7e-XxIAqe-cGMI2b {
  background-color: #fff;
}
.VfPpkd-z59Tgd {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-caption-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.75rem;
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-caption-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  line-height: 20px;
}
.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd .VfPpkd-BFbNVe-bF1uUb {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.VfPpkd-z59Tgd .VfPpkd-MlC99b {
  display: block;
  margin-top: 0;
  line-height: 20px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle2-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.875rem;
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  letter-spacing: var(
    --mdc-typography-subtitle2-letter-spacing,
    0.0071428571em
  );
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle2-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}
.VfPpkd-z59Tgd .VfPpkd-MlC99b::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-z59Tgd .VfPpkd-IqDDtd {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-body2-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.875rem;
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}
.VfPpkd-z59Tgd {
  word-break: break-all;
  word-break: var(--mdc-tooltip-word-break, normal);
  overflow-wrap: anywhere;
}
.VfPpkd-suEOdc-OWXEXe-eo9XGd-RCfa3e .VfPpkd-z59Tgd-OiiCO {
  transition: opacity 0.15s 0ms cubic-bezier(0, 0, 0.2, 1),
    transform 0.15s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-suEOdc-OWXEXe-ZYIfFd-RCfa3e .VfPpkd-z59Tgd-OiiCO {
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}
.VfPpkd-suEOdc {
  position: fixed;
  display: none;
  z-index: 9;
}
.VfPpkd-suEOdc-sM5MNb-OWXEXe-nzrxxc {
  position: relative;
}
.VfPpkd-suEOdc-OWXEXe-TSZdd,
.VfPpkd-suEOdc-OWXEXe-eo9XGd,
.VfPpkd-suEOdc-OWXEXe-ZYIfFd {
  display: inline-flex;
}
.VfPpkd-suEOdc-OWXEXe-TSZdd.VfPpkd-suEOdc-OWXEXe-nzrxxc,
.VfPpkd-suEOdc-OWXEXe-eo9XGd.VfPpkd-suEOdc-OWXEXe-nzrxxc,
.VfPpkd-suEOdc-OWXEXe-ZYIfFd.VfPpkd-suEOdc-OWXEXe-nzrxxc {
  display: inline-block;
  left: -320px;
  position: absolute;
}
.VfPpkd-z59Tgd {
  line-height: 16px;
  padding: 4px 8px;
  min-width: 40px;
  max-width: 200px;
  min-height: 24px;
  max-height: 40vh;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
}
.VfPpkd-z59Tgd::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .VfPpkd-z59Tgd::before {
    border-color: CanvasText;
  }
}
.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 24px;
  min-width: 40px;
  max-width: 320px;
  position: relative;
}
.VfPpkd-suEOdc-OWXEXe-LlMNQd .VfPpkd-z59Tgd {
  text-align: left;
}
[dir="rtl"] .VfPpkd-suEOdc-OWXEXe-LlMNQd .VfPpkd-z59Tgd,
.VfPpkd-suEOdc-OWXEXe-LlMNQd .VfPpkd-z59Tgd[dir="rtl"] {
  text-align: right;
}
.VfPpkd-z59Tgd .VfPpkd-MlC99b {
  margin: 0 8px;
}
.VfPpkd-z59Tgd .VfPpkd-IqDDtd {
  max-width: 184px;
  margin: 8px;
  text-align: left;
}
[dir="rtl"] .VfPpkd-z59Tgd .VfPpkd-IqDDtd,
.VfPpkd-z59Tgd .VfPpkd-IqDDtd[dir="rtl"] {
  text-align: right;
}
.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd .VfPpkd-IqDDtd {
  max-width: 304px;
  align-self: stretch;
}
.VfPpkd-z59Tgd .VfPpkd-IqDDtd-hSRGPd {
  text-decoration: none;
}
.VfPpkd-suEOdc-OWXEXe-nzrxxc-LQLjdd,
.VfPpkd-IqDDtd,
.VfPpkd-MlC99b {
  z-index: 1;
}
.VfPpkd-z59Tgd-OiiCO {
  opacity: 0;
  transform: scale(0.8);
  will-change: transform, opacity;
}
.VfPpkd-suEOdc-OWXEXe-TSZdd .VfPpkd-z59Tgd-OiiCO {
  transform: scale(1);
  opacity: 1;
}
.VfPpkd-suEOdc-OWXEXe-ZYIfFd .VfPpkd-z59Tgd-OiiCO {
  transform: scale(1);
}
.VfPpkd-Djsh7e-XxIAqe-ma6Yeb,
.VfPpkd-Djsh7e-XxIAqe-cGMI2b {
  position: absolute;
  height: 24px;
  width: 24px;
  transform: rotate(35deg) skewY(20deg) scaleX(0.9396926208);
}
.VfPpkd-Djsh7e-XxIAqe-ma6Yeb .VfPpkd-BFbNVe-bF1uUb,
.VfPpkd-Djsh7e-XxIAqe-cGMI2b .VfPpkd-BFbNVe-bF1uUb {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.VfPpkd-Djsh7e-XxIAqe-cGMI2b {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  outline: 1px solid transparent;
  z-index: -1;
}
@media screen and (forced-colors: active) {
  .VfPpkd-Djsh7e-XxIAqe-cGMI2b {
    outline-color: CanvasText;
  }
}
.EY8ABd {
  z-index: 2101;
}
.EY8ABd .VfPpkd-z59Tgd {
  background-color: #3c4043;
  color: #e8eaed;
}
.EY8ABd .VfPpkd-MlC99b,
.EY8ABd .VfPpkd-IqDDtd {
  color: #3c4043;
}
.EY8ABd .VfPpkd-IqDDtd-hSRGPd {
  color: #1a73e8;
}
.EY8ABd.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd,
.EY8ABd.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-Djsh7e-XxIAqe-ma6Yeb,
.EY8ABd.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-Djsh7e-XxIAqe-cGMI2b {
  background-color: #fff;
}
.EY8ABd.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-MlC99b {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  line-height: 1.25rem;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429em;
  font-weight: 500;
}
.EY8ABd.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd {
  border-radius: 8px;
}
.ziykHb {
  z-index: 2101;
}
.ziykHb .VfPpkd-z59Tgd {
  background-color: #3c4043;
  color: #e8eaed;
}
.ziykHb .VfPpkd-MlC99b,
.ziykHb .VfPpkd-IqDDtd {
  color: #3c4043;
}
.ziykHb .VfPpkd-IqDDtd-hSRGPd {
  color: #1a73e8;
}
.ziykHb.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd,
.ziykHb.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-Djsh7e-XxIAqe-ma6Yeb,
.ziykHb.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-Djsh7e-XxIAqe-cGMI2b {
  background-color: #fff;
}
.ziykHb.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-MlC99b {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  line-height: 1.25rem;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429em;
  font-weight: 500;
}
.ziykHb.VfPpkd-suEOdc-OWXEXe-nzrxxc .VfPpkd-z59Tgd {
  border-radius: 8px;
}
.EY8ABd-OWXEXe-TAWMXe {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  -webkit-user-select: none;
  -webkit-user-select: none;
}
html[dir="rtl"] .giSqbe {
  transform: scaleX(-1);
}
.YZrg6 {
  align-items: center;
  background: #fff;
  border: 1px solid #dadce0;
  color: #3c4043;
  cursor: pointer;
  display: -webkit-inline-box;
  display: inline-flex;
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  max-width: 100%;
  position: relative;
}
.YZrg6:after {
  bottom: -1px;
  border: 1px solid transparent;
  content: "";
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
}
.YZrg6:focus,
.YZrg6.u3bW4e {
  background: rgba(60, 64, 67, 0.122);
  outline: none;
}
.YZrg6:focus-visible::after {
  bottom: -5px;
  border: 2px solid #185abc;
  border-radius: 20px;
  box-shadow: 0 0 0 2px #e8f0fe;
  content: "";
  left: -5px;
  position: absolute;
  right: -5px;
  top: -5px;
}
.YZrg6:hover:not(:focus-visible)::after {
  background: rgba(60, 64, 67, 0.039);
}
.YZrg6:focus:not(:focus-visible)::after,
.YZrg6:hover:not(:focus-visible)::after,
.YZrg6.u3bW4e {
  border-color: #dadce0;
}
.YZrg6.qs41qe {
  color: #3c4043;
}
.YZrg6.qs41qe:not(:focus-visible)::after {
  background: rgba(60, 64, 67, 0.122);
  border-color: #3c4043;
}
.SOOv2c {
  color: #1a73e8;
  font-size: 12px;
}
.HnRr5d {
  border-radius: 16px;
  padding: 0 15px 0 15px;
}
.HnRr5d.SOOv2c {
  border-radius: 12px;
  padding: 0 10px 0 10px;
}
.HnRr5d.iiFyne {
  padding-right: 7px;
}
.HnRr5d.cd29Sd {
  padding-left: 5px;
}
.HnRr5d.SOOv2c.iiFyne {
  padding-right: 7px;
}
.HnRr5d.SOOv2c.cd29Sd {
  padding-left: 2px;
}
.HnRr5d:after {
  border-radius: 16px;
}
.HnRr5d.SOOv2c:after {
  border-radius: 12px;
}
.gPHLDe {
  border-radius: 10px;
  height: 20px;
  margin-right: 8px;
}
.gPHLDe .stUf5b,
.gPHLDe .G5XIyb {
  border-radius: 50%;
  color: #3c4043;
  display: block;
  height: 20px;
  width: 20px;
}
.KTeGk {
  direction: ltr;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.HnRr5d .KTeGk {
  line-height: 30px;
}
.HnRr5d.SOOv2c .KTeGk {
  line-height: 22px;
}
.krLnGe {
  color: #3c4043;
  flex-shrink: 0;
  height: 18px;
  margin-left: 4px;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 18px;
}
.YZrg6.sMVRZe .krLnGe {
  transform: rotate(180deg);
}
.SOOv2c .krLnGe {
  height: 16px;
  width: 16px;
}
.MSBt4d {
  display: block;
  height: 100%;
  width: 100%;
}
.B6L7ke {
  height: 25vh;
  margin: auto -24px;
  min-height: 110px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}
.SCAiR {
  height: 25vh;
  margin: auto -24px;
  min-height: 110px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  overflow: hidden;
}
.JtUbMb,
.Nny6ue {
  display: block;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
}
@media all and (min-width: 450px) {
  .B6L7ke {
    margin: auto -40px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (min-width: 601px) {
  .B6L7ke {
    height: 150px;
  }
}
.B6L7ke.Irjbwb {
  height: auto;
}
.B6L7ke.IiQozc {
  text-align: center;
}
.xh7Wmd {
  height: 25vh;
  max-width: 100%;
  min-height: 110px;
  position: relative;
  transform: translate(-43%, -3%);
  z-index: 3;
}
@media all and (min-width: 601px) {
  .xh7Wmd {
    height: 150px;
  }
}
.B6L7ke.FnDdB {
  height: auto;
}
.B6L7ke.FnDdB .xh7Wmd {
  height: auto;
  max-width: 312px;
  width: 100%;
}
.B6L7ke.FnDdB.zpCp3 .xh7Wmd {
  max-width: unset;
}
.B6L7ke.IiQozc .xh7Wmd {
  transform: none;
}
.B6L7ke.aJJFde .xh7Wmd {
  left: -100%;
  margin: auto;
  position: absolute;
  right: -100%;
  transform: translate(0, -3%);
}
.B6L7ke.Irjbwb .xh7Wmd {
  height: auto;
  width: 100%;
}
.p17Urb {
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(233, 233, 233, 0) 0%,
    rgba(233, 233, 233, 0) 62.22%,
    rgba(233, 233, 233, 1) 40.22%,
    rgba(233, 233, 233, 0) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(233, 233, 233, 0) 0%,
    rgba(233, 233, 233, 0) 62.22%,
    rgba(233, 233, 233, 1) 40.22%,
    rgba(233, 233, 233, 0) 100%
  );
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.p17Urb:after,
.p17Urb:before {
  content: "";
  display: block;
  height: 100%;
  min-width: 110px;
  position: absolute;
  right: -10%;
  transform: rotate(-104deg);
  width: 25vh;
  z-index: 2;
}
@media all and (min-width: 601px) {
  .p17Urb:after,
  .p17Urb:before {
    width: 150px;
  }
}
.p17Urb:before {
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(243, 243, 243, 0) 0%,
    rgba(243, 243, 243, 0.9) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(243, 243, 243, 0) 0%,
    rgba(243, 243, 243, 0.9) 100%
  );
  bottom: -10%;
}
.p17Urb:after {
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  bottom: -80%;
}
.DrceJe {
  height: auto;
}
.yb5i2e {
  transform: translate(-9%, -3%);
}
.hNLjwb {
  transform: translate(9%, -3%);
}
.ulNYne {
  left: -40%;
  margin: auto;
  max-height: 230px;
  position: absolute;
  right: 0;
  top: -3%;
  transform: none;
}
.F8EZte {
  transform: translate(24px, 0);
}
.eMXECe {
  transform: translate(0, 0);
}
.B6L7ke.i1L7v {
  height: 15vh;
  max-height: 137px;
  min-height: 112px;
  padding-bottom: 12px;
}
.B6L7ke.i1L7v .xh7Wmd {
  max-height: 100%;
  min-height: 100%;
}
.B6L7ke.j1zy9 {
  height: auto;
}
.B6L7ke.j1zy9 .xh7Wmd {
  height: auto;
  max-width: 432px;
}
.PeAiAb {
  max-width: 300px;
}
.aTzEhb {
  margin: 16px 0;
  outline: none;
}
.aTzEhb + .aTzEhb {
  margin-top: 24px;
}
.aTzEhb:first-child {
  margin-top: 0;
}
.aTzEhb:last-child {
  margin-bottom: 0;
}
.AORPd {
  border-radius: 8px;
  padding: 16px;
}
.AORPd > :first-child {
  margin-top: 0;
}
.AORPd > :last-child {
  margin-bottom: 0;
}
.AORPd .kV95Wc {
  color: #202124;
}
.AORPd .CxRgyd {
  color: #202124;
}
.AORPd.YFdWic .CxRgyd {
  color: #5f6368;
  margin-top: 4px;
  padding: 0;
}
.AORPd.YFdWic .IdEPtc,
.AORPd.YFdWic .CxRgyd {
  margin-left: 64px;
  width: calc(100% - 64px);
}
.AORPd.YFdWic:not(.S7S4N) .IdEPtc {
  margin-left: 0;
  width: 0;
}
.AORPd:not(.S7S4N) > .CxRgyd {
  margin-top: 0;
}
.AORPd.sj692e {
  background: #e8f0fe;
}
.AORPd.Xq8bDe {
  background: #fce8e6;
}
.AORPd.rNe0id {
  background: #fef7e0;
}
.AORPd.YFdWic {
  border: 1px solid #dadce0;
  min-height: 48px;
  position: relative;
}
.AORPd:not(.S7S4N) {
  display: flex;
}
.aTzEhb.eLNT1d {
  display: none;
}
.aTzEhb.RDPZE {
  opacity: 0.5;
  pointer-events: none;
}
.aTzEhb.RDPZE .aTzEhb.RDPZE {
  opacity: 1;
}
.wfep7d {
  border: 1px solid #dadce0;
  border-radius: 8px;
  padding: 16px;
}
.wfep7d .UST9Bf {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.wfep7d .UST9Bf .xYnMae {
  margin-bottom: 0;
  margin-top: 0;
}
.vEFDtd {
  border-bottom: 1px solid #dadce0;
  display: flex;
  flex-direction: column;
}
.vEFDtd .V9RXW .rBUW7e,
.vEFDtd .vEFDtd:last-child {
  border-bottom: 0;
}
.vEFDtd .vEFDtd:last-child .L9iFZc {
  padding-bottom: 0;
}
.vEFDtd.D6kf4b {
  border-bottom: 0;
}
.IdEPtc:empty,
.yMb59d:empty {
  display: none;
}
.IdEPtc > :first-child {
  margin-top: 0;
  padding-top: 0;
}
.IdEPtc > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.UWVyoc {
  margin: 0 0 8px;
}
.vEFDtd[data-expand-type="1"] .L9iFZc,
.aTzEhb[data-expand-type="1"] .A6OHve {
  cursor: pointer;
}
.vEFDtd .L9iFZc {
  padding-bottom: 16px;
}
.kV95Wc {
  align-items: center;
  color: #202124;
  display: flex;
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.vEFDtd.u3bW4e .kV95Wc {
  position: relative;
}
.vEFDtd[data-expand-type="1"].u3bW4e .kV95Wc:after {
  background: rgba(26, 115, 232, 0.149);
  border-radius: 8px;
  bottom: -4px;
  content: "";
  left: -8px;
  position: absolute;
  right: -8px;
  top: -4px;
  z-index: -1;
}
.A6OHve {
  background: none;
  border: none;
  color: inherit;
  box-flex: 1;
  flex-grow: 1;
  font: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: inherit;
}
.A6OHve::-moz-focus-inner {
  border: 0;
}
.A6OHve [jsslot] {
  position: relative;
}
.jhXB3b {
  margin-left: 16px;
}
.jhXB3b .Z6O26d {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 24px;
}
.vEFDtd .jhXB3b,
.vEFDtd .A6OHve,
.vEFDtd .yiP64c {
  pointer-events: none;
}
.vEFDtd.jVwmLb .Z6O26d {
  transform: rotate(-180deg);
}
.yiP64c {
  color: #5f6368;
  flex-shrink: 0;
  height: 20px;
  margin-right: 16px;
  width: 20px;
}
.yiP64c .d7Plee {
  height: 100%;
  width: 100%;
}
.AORPd .yiP64c {
  margin-top: 0;
}
.AORPd.sj692e .yiP64c {
  color: #1967d2;
}
.AORPd.Xq8bDe .yiP64c {
  color: #c5221f;
}
.AORPd.rNe0id .yiP64c {
  color: #ea8600;
}
.AORPd.YFdWic .yiP64c {
  height: 48px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 48px;
}
.yMb59d {
  color: #5f6368;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4286;
  margin-top: 8px;
}
.CxRgyd {
  margin: auto -24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
  margin-top: 10px;
}
@media all and (min-width: 450px) {
  .CxRgyd {
    margin: auto -40px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 16px;
    margin-top: 10px;
  }
}
.wfep7d .CxRgyd {
  margin-bottom: 0;
  margin-top: 16px;
}
.IdEPtc:empty + .CxRgyd {
  margin-top: 0;
}
.CxRgyd:only-child {
  margin-bottom: 0;
  margin-top: 0;
}
.vEFDtd .CxRgyd {
  margin-top: 0;
  overflow-y: hidden;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.vEFDtd.jVwmLb .CxRgyd {
  margin-bottom: 0;
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}
.CxRgyd > [jsslot] > :first-child:not(section) {
  margin-top: 0;
  padding-top: 0;
}
.CxRgyd > [jsslot] > :last-child:not(section) {
  margin-bottom: 0;
  padding-bottom: 0;
}
.w7wqLd {
  align-self: center;
  margin-bottom: 0;
}
.x3iGMd {
  border-bottom: 1px solid #dadce0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 0;
  margin-bottom: 24px;
  margin-top: 12px;
}
.aQPcpb {
  background: #fff;
  display: flex;
  height: 24px;
  margin-top: -12px;
}
.D6kf4b:not(.jVwmLb) .x3iGMd {
  display: none;
}
.D6kf4b .VBGMK:focus-visible {
  outline: none;
  position: relative;
}
.D6kf4b .VBGMK:focus-visible::after {
  border: 2px solid #185abc;
  border-radius: 6px;
  bottom: 0;
  box-shadow: 0 0 0 2px #e8f0fe;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.XjS9D {
  display: -webkit-inline-box;
  display: inline-flex;
}
.XjS9D.eLNT1d {
  display: none;
}
.XjS9D .TrZEUc .WpHeLc {
  position: absolute;
}
.XjS9D .q6oraf .DMZ54e,
.XjS9D .BqKGqe {
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.42857143;
}
.BqKGqe,
.BqKGqe .VfPpkd-Jh9lGc {
  border-radius: 20px;
}
.XjS9D .VfPpkd-LgbsSe {
  height: 40px;
}
@media all and (orientation: landscape) {
  .XjS9D .VfPpkd-LgbsSe {
    height: 40px;
  }
}
.AnSR9d:not(:disabled).VfPpkd-LgbsSe {
  background: #c2e7ff;
  color: #001d35;
}
.AnSR9d:not(:disabled).VfPpkd-LgbsSe:focus {
  color: #001d35;
}
.eR0mzb.VfPpkd-LgbsSe {
  min-width: 0;
  padding-left: 16px;
  padding-right: 16px;
}
.H76ePc {
  margin: auto;
  max-width: 380px;
  overflow: hidden;
  position: relative;
}
.H76ePc .LbOduc {
  position: relative;
  text-align: center;
}
.JQ5tlb {
  border-radius: 50%;
  color: #444746;
  overflow: hidden;
}
.pGzURd {
  line-height: 1.42857143;
}
.lPxAeb {
  width: 100%;
}
.lPxAeb .JQ5tlb {
  flex: none;
  height: 28px;
  margin-right: 12px;
  width: 28px;
}
.lPxAeb .LbOduc,
.VUfHYd .LbOduc {
  display: flex;
  align-items: center;
}
.lPxAeb .LbOduc {
  justify-content: center;
}
.H76ePc .JQ5tlb {
  height: 64px;
  margin: 0 auto 8px;
  width: 64px;
}
.MnFlu {
  border-radius: 50%;
  display: block;
}
.lPxAeb .MnFlu,
.lPxAeb .Qk3oof,
.lPxAeb .uHLU0 {
  max-height: 100%;
  max-width: 100%;
}
.H76ePc .MnFlu,
.H76ePc .Qk3oof,
.H76ePc .uHLU0 {
  height: 64px;
  width: 64px;
}
.VUfHYd {
  height: 24px;
}
.VUfHYd .JQ5tlb {
  margin-right: 8px;
  height: 24px;
  min-width: 24px;
}
.VUfHYd .MnFlu,
.VUfHYd .Qk3oof,
.VUfHYd .uHLU0 {
  color: #444746;
  height: 24px;
  width: 24px;
}
.VUfHYd .DOLDDf {
  overflow: hidden;
}
.VUfHYd .yAlK0b {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lPxAeb .DOLDDf {
  box-flex: 1;
  flex-grow: 1;
}
.lPxAeb .pGzURd {
  color: #1f1f1f;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.5;
}
.H76ePc .pGzURd {
  color: #1f1f1f;
  font-size: 0.875rem;
}
.yAlK0b,
.VhdzSd,
.H2oig {
  direction: ltr;
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.42857143;
  word-break: break-all;
}
.lPxAeb .yAlK0b,
.lPxAeb .VhdzSd,
.lPxAeb .H2oig {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.42857143;
}
.H2oig {
  color: #444746;
}
.VUfHYd .yAlK0b {
  color: #1f1f1f;
}
.lPxAeb .VhdzSd,
.lPxAeb .yAlK0b {
  color: #444746;
}
.H76ePc .yAlK0b {
  color: #444746;
}
.lrLKwc {
  color: #444746;
  font-size: 0.875rem;
}
.lPxAeb .lrLKwc {
  align-self: flex-start;
  flex: none;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
  line-height: 1.33333333;
}
.Ahygpe {
  align-items: center;
  background: #fff;
  border: 1px solid #747775;
  color: #1f1f1f;
  cursor: pointer;
  display: -webkit-inline-box;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  max-width: 100%;
  position: relative;
}
.Ahygpe:after {
  bottom: -1px;
  border: 1px solid transparent;
  content: "";
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
}
.Ahygpe:focus,
.Ahygpe.u3bW4e {
  outline: none;
}
.Ahygpe:focus:after,
.Ahygpe.u3bW4e:after {
  background: rgba(60, 64, 67, 0.122);
}
.Ahygpe:hover:after {
  background: rgba(60, 64, 67, 0.039);
}
.Ahygpe:focus:after,
.Ahygpe:hover:after,
.Ahygpe.u3bW4e {
  border-color: #747775;
}
.Ahygpe.qs41qe {
  color: #1f1f1f;
}
.Ahygpe.qs41qe:after {
  background: rgba(60, 64, 67, 0.122);
  border-color: #3c4043;
}
.Zjyti {
  color: #0b57d0;
  font-size: 0.75rem;
}
.m8wwGd {
  border-radius: 16px;
  padding: 0 15px 0 15px;
}
.m8wwGd.Zjyti {
  border-radius: 12px;
  padding: 0 10px 0 10px;
}
.m8wwGd.EPPJc {
  padding-right: 8px;
}
.m8wwGd.cd29Sd {
  padding-left: 3px;
}
.m8wwGd.Zjyti.EPPJc {
  padding-right: 8px;
}
.m8wwGd.Zjyti.cd29Sd {
  padding-left: 2px;
}
.m8wwGd:after {
  border-radius: 16px;
}
.m8wwGd.Zjyti:after {
  border-radius: 12px;
}
.HOE91e {
  border-radius: 12px;
  height: 24px;
  margin-right: 8px;
}
.HOE91e .MnFlu,
.HOE91e .Qk3oof,
.HOE91e .uHLU0 {
  border-radius: 50%;
  color: #444746;
  display: block;
  height: 24px;
  width: 24px;
}
.IxcUte {
  direction: ltr;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m8wwGd .IxcUte {
  line-height: 30px;
}
.m8wwGd.Zjyti .IxcUte {
  line-height: 22px;
}
.JCl8ie {
  color: #1f1f1f;
  flex-shrink: 0;
  height: 20px;
  margin-left: 5px;
  width: 20px;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.Ahygpe.sMVRZe .JCl8ie {
  transform: rotate(180deg);
}
.Zjyti .JCl8ie {
  height: 16px;
  width: 16px;
}
.u4TTuf {
  display: block;
  height: 100%;
  width: 100%;
}
.Dzz9Db,
.GpMPBe {
  height: 25vh;
  position: relative;
}
@media all and (min-width: 600px) {
  .Dzz9Db,
  .GpMPBe {
    height: 150px;
  }
}
.Dzz9Db.Irjbwb {
  height: auto;
}
.GpMPBe {
  margin: 0;
  overflow: hidden;
}
.UFQPDd,
.JNOvdd {
  display: block;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
}
.f4ZpM {
  display: block;
  height: 100%;
  max-width: 100%;
  min-height: 110px;
  position: relative;
  transform: translate(-43%, -3%);
  width: auto;
  z-index: 3;
}
.wsArZ[data-ss-mode="1"] .Dzz9Db,
.wsArZ[data-ss-mode="1"] .f4ZpM {
  height: auto;
  width: 100%;
}
.wsArZ[data-ss-mode="1"] .f4ZpM {
  max-width: 400px;
}
@media all and (orientation: landscape) {
  .NQ5OL .Dzz9Db {
    height: auto;
    width: 100%;
  }
  .NQ5OL .f4ZpM {
    height: auto;
    width: 100%;
    max-width: 400px;
  }
}
.Dzz9Db.utFBGf {
  height: auto;
}
.Dzz9Db.utFBGf .f4ZpM {
  height: auto;
  max-width: 312px;
  width: 100%;
}
.Dzz9Db.utFBGf.zpCp3 .f4ZpM {
  max-width: unset;
}
.Dzz9Db.IiQozc .f4ZpM {
  margin: 0 auto;
  transform: none;
}
.Dzz9Db.aJJFde .f4ZpM {
  left: -100%;
  margin: auto;
  position: absolute;
  right: -100%;
  transform: translate(0, -3%);
}
.Dzz9Db.Irjbwb .f4ZpM {
  height: auto;
  width: 100%;
}
.nPt1pc {
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(233, 233, 233, 0) 0%,
    rgba(233, 233, 233, 0) 62.22%,
    rgba(233, 233, 233, 1) 40.22%,
    rgba(233, 233, 233, 0) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(233, 233, 233, 0) 0%,
    rgba(233, 233, 233, 0) 62.22%,
    rgba(233, 233, 233, 1) 40.22%,
    rgba(233, 233, 233, 0) 100%
  );
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.nPt1pc:after,
.nPt1pc:before {
  content: "";
  display: block;
  height: 100%;
  min-width: 110px;
  position: absolute;
  right: -10%;
  transform: rotate(-104deg);
  width: 25vh;
  z-index: 2;
}
@media all and (min-width: 600px) {
  .nPt1pc:after,
  .nPt1pc:before {
    width: 150px;
  }
}
.nPt1pc:before {
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(243, 243, 243, 0) 0%,
    rgba(243, 243, 243, 0.9) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(243, 243, 243, 0) 0%,
    rgba(243, 243, 243, 0.9) 100%
  );
  bottom: -10%;
}
.nPt1pc:after {
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  bottom: -80%;
}
.wsArZ[data-ss-mode="1"] .nPt1pc ~ .f4ZpM {
  width: auto;
}
@media all and (orientation: landscape) {
  .NQ5OL .nPt1pc ~ .f4ZpM {
    width: auto;
  }
}
.ZS7CGc {
  height: auto;
}
.qiRZ5e {
  transform: translate(-9%, -3%);
}
.vIv7Gf {
  left: -40%;
  margin: auto;
  max-height: 230px;
  right: 0;
  top: -3%;
  transform: none;
}
.nvYXVd {
  transform: translate(9%, -3%);
}
.uOhnzd {
  transform: translate(20px, 0);
}
.MsYMaf {
  transform: translate(0, 0);
}
.Dzz9Db.YIi9qf {
  height: 15vh;
  max-height: 137px;
  min-height: 112px;
  padding-bottom: 12px;
}
.Dzz9Db.YIi9qf .f4ZpM {
  min-height: 100%;
}
.QG3Xbe {
  max-width: 300px;
}
.F6gtje {
  transform: none;
}
.rFrNMe {
  -webkit-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  outline: none;
  padding-bottom: 8px;
  width: 200px;
}
.aCsJod {
  height: 40px;
  position: relative;
  vertical-align: top;
}
.aXBtI {
  display: flex;
  position: relative;
  top: 14px;
}
.Xb9hP {
  display: flex;
  box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0%;
  position: relative;
}
.A37UZe {
  box-sizing: border-box;
  height: 24px;
  line-height: 24px;
  position: relative;
}
.qgcB3c:not(:empty) {
  padding-right: 12px;
}
.sxyYjd:not(:empty) {
  padding-left: 12px;
}
.whsOnd {
  box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: transparent;
  border: none;
  display: block;
  font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  height: 24px;
  line-height: 24px;
  margin: 0;
  min-width: 0%;
  outline: none;
  padding: 0;
  z-index: 0;
}
.rFrNMe.dm7YTc .whsOnd {
  color: #fff;
}
.whsOnd:invalid,
.whsOnd:-moz-submit-invalid,
.whsOnd:-moz-ui-invalid {
  box-shadow: none;
}
.I0VJ4d > .whsOnd::-ms-clear,
.I0VJ4d > .whsOnd::-ms-reveal {
  display: none;
}
.i9lrp {
  background-color: rgba(0, 0, 0, 0.12);
  bottom: -2px;
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}
.i9lrp:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: -2px;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  pointer-events: none;
}
.rFrNMe.dm7YTc .i9lrp {
  background-color: rgba(255, 255, 255, 0.7);
}
.OabDMe {
  transform: scaleX(0);
  background-color: #4285f4;
  bottom: -2px;
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}
.rFrNMe.dm7YTc .OabDMe {
  background-color: #a1c2fa;
}
.rFrNMe.k0tWj .i9lrp,
.rFrNMe.k0tWj .OabDMe {
  background-color: #d50000;
  height: 2px;
}
.rFrNMe.k0tWj.dm7YTc .i9lrp,
.rFrNMe.k0tWj.dm7YTc .OabDMe {
  background-color: #e06055;
}
.whsOnd[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.rFrNMe.dm7YTc .whsOnd[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.whsOnd[disabled] ~ .i9lrp {
  background: none;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
}
.OabDMe.Y2Zypf {
  animation: quantumWizPaperInputRemoveUnderline 0.3s
    cubic-bezier(0.4, 0, 0.2, 1);
}
.rFrNMe.u3bW4e .OabDMe {
  animation: quantumWizPaperInputAddUnderline 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scaleX(1);
}
.rFrNMe.sdJrJc > .aCsJod {
  padding-top: 24px;
}
.AxOyFc {
  transform-origin: bottom left;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: color, bottom, transform;
  -webkit-transition-property: color, bottom, transform, -webkit-transform;
  transition-property: color, bottom, transform, -webkit-transform;
  color: rgba(0, 0, 0, 0.38);
  font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 16px;
  pointer-events: none;
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
}
.whsOnd:not([disabled]):focus ~ .AxOyFc,
.whsOnd[badinput="true"] ~ .AxOyFc,
.rFrNMe.CDELXb .AxOyFc,
.rFrNMe.dLgj8b .AxOyFc {
  transform: scale(0.75) translateY(-39px);
}
.whsOnd:not([disabled]):focus ~ .AxOyFc {
  color: #3367d6;
}
.rFrNMe.dm7YTc .whsOnd:not([disabled]):focus ~ .AxOyFc {
  color: #a1c2fa;
}
.rFrNMe.k0tWj .whsOnd:not([disabled]):focus ~ .AxOyFc {
  color: #d50000;
}
.ndJi5d {
  color: rgba(0, 0, 0, 0.38);
  font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 2px;
  left: 0;
  white-space: nowrap;
}
.rFrNMe.CDELXb .ndJi5d {
  display: none;
}
.K0Y8Se {
  -webkit-tap-highlight-color: transparent;
  font: 400 12px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  height: 16px;
  margin-left: auto;
  padding-left: 16px;
  padding-top: 8px;
  pointer-events: none;
  opacity: 0.3;
  white-space: nowrap;
}
.rFrNMe.dm7YTc .AxOyFc,
.rFrNMe.dm7YTc .K0Y8Se,
.rFrNMe.dm7YTc .ndJi5d {
  color: rgba(255, 255, 255, 0.7);
}
.rFrNMe.Tyc9J {
  padding-bottom: 4px;
}
.dEOOab,
.ovnfwe:not(:empty) {
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font: 400 12px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  min-height: 16px;
  padding-top: 8px;
}
.LXRPh {
  display: flex;
}
.ovnfwe {
  pointer-events: none;
}
.dEOOab {
  color: #d50000;
}
.rFrNMe.dm7YTc .dEOOab,
.rFrNMe.dm7YTc.k0tWj .whsOnd:not([disabled]):focus ~ .AxOyFc {
  color: #e06055;
}
.ovnfwe {
  opacity: 0.3;
}
.rFrNMe.dm7YTc .ovnfwe {
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}
.rFrNMe.k0tWj .ovnfwe,
.rFrNMe:not(.k0tWj) .ovnfwe:not(:empty) + .dEOOab {
  display: none;
}
@keyframes quantumWizPaperInputRemoveUnderline {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }
  to {
    transform: scaleX(1);
    opacity: 0;
  }
}
@keyframes quantumWizPaperInputAddUnderline {
  0% {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
@media all and (min-width: 600px) {
  .njnYzb.DbQnIe .YqLCIe {
    display: flex;
    justify-content: space-between;
  }
}
.njnYzb:first-child .H2p7Gf:first-child .i79UJc {
  padding-top: 8px;
}
@media all and (min-width: 600px) {
  .njnYzb.DbQnIe:first-child .H2p7Gf .i79UJc {
    padding-top: 8px;
  }
  .njnYzb.DbQnIe .H2p7Gf {
    box-flex: 1;
    flex-grow: 1;
    margin-right: 8px;
    width: 0;
  }
  .njnYzb.DbQnIe .H2p7Gf:last-child {
    margin-right: 0;
  }
}
.i79UJc.i79UJc {
  box-sizing: content-box;
}
.i79UJc {
  padding-bottom: 0;
  padding-top: 24px;
  width: 100%;
}
.i79UJc .oJeWuf.oJeWuf {
  height: 56px;
  padding-top: 0;
}
.i79UJc.OcVpRe .oJeWuf.oJeWuf {
  height: 36px;
}
.i79UJc .Wic03c {
  align-items: center;
  position: static;
}
.i79UJc .snByac {
  background-color: transparent;
  bottom: 18px;
  box-sizing: border-box;
  color: #444746;
  font-size: 16px;
  font-weight: 400;
  left: 8px;
  max-width: calc(100% - (2 * 8px));
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  width: auto;
  z-index: 1;
}
.i79UJc.OcVpRe .snByac {
  bottom: 10px;
  color: #444746;
  font-size: 14px;
  left: 4px;
  line-height: 16px;
  padding: 0 6px;
}
.i79UJc.u3bW4e .snByac.snByac,
.i79UJc.CDELXb .snByac.snByac {
  background-color: #fff;
  transform: scale(0.75) translatey(-41px);
}
.i79UJc.OcVpRe.u3bW4e .snByac,
.i79UJc.OcVpRe.CDELXb .snByac {
  transform: scale(0.75) translatey(-165%);
}
.i79UJc .zHQkBf:not([disabled]):focus ~ .snByac {
  color: #0b57d0;
}
.i79UJc.IYewr.u3bW4e .zHQkBf:not([disabled]) ~ .snByac,
.i79UJc.IYewr.CDELXb .zHQkBf:not([disabled]) ~ .snByac {
  color: #b3261e;
}
.i79UJc .zHQkBf {
  border-radius: 4px;
  color: #1f1f1f;
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 16px;
  height: 28px;
  margin: 2px;
  padding: 12px 14px;
  z-index: 1;
}
.i79UJc.OcVpRe .zHQkBf {
  font-size: 14px;
  height: 20px;
  padding: 6px 8px;
}
.i79UJc.YKooDc .zHQkBf,
.i79UJc.YKooDc .MQL3Ob {
  direction: ltr;
  text-align: left;
}
.i79UJc .iHd5yb {
  padding-left: 14px;
}
.i79UJc.OcVpRe .iHd5yb {
  padding-left: 8px;
}
.i79UJc .MQL3Ob {
  padding-right: 14px;
  z-index: 1;
}
.i79UJc.OcVpRe .MQL3Ob {
  padding-right: 8px;
}
.i79UJc .mIZh1c,
.i79UJc .cXrdqd {
  border-radius: 4px;
  box-sizing: border-box;
}
.i79UJc .mIZh1c,
.i79UJc .cXrdqd,
.i79UJc.IYewr .mIZh1c,
.i79UJc.IYewr .cXrdqd {
  background-color: transparent;
  bottom: 0;
  height: auto;
  top: 0;
}
.i79UJc .mIZh1c {
  border: 1px solid #747775;
  padding: 1px;
}
.i79UJc .cXrdqd {
  border: 1px solid #0b57d0;
  opacity: 0;
  transform: none;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
.i79UJc.u3bW4e .cXrdqd {
  border-width: 2px;
  animation: none;
  opacity: 1;
}
.i79UJc.IYewr .cXrdqd {
  animation: none;
  opacity: 1;
  border-color: #b3261e;
}
.i79UJc .ndJi5d,
.i79UJc .ovnfwe {
  pointer-events: auto;
}
.i79UJc .RxsGPe,
.i79UJc .Is7Fhb {
  display: none;
}
.Ly8vae {
  display: flex;
  font-size: 12px;
}
.Ly8vae:empty,
.NdBX9e:empty {
  display: none;
}
.njnYzb.Jj6Lae .Ly8vae {
  color: #b3261e;
}
.jEOsLc {
  display: none;
  margin-right: 8px;
}
.JPqhye {
  height: 16px;
  width: 16px;
}
.njnYzb.Jj6Lae .jEOsLc {
  display: block;
}
.njnYzb .Ly8vae {
  color: #444746;
  margin-top: 4px;
}
.njnYzb .YQOsOe {
  margin-left: 16px;
}
.njnYzb.OcVpRe .YQOsOe {
  margin-left: 10px;
}
.Ekjuhf {
  align-items: flex-start;
  display: flex;
  font-size: 12px;
  line-height: normal;
  margin-top: 2px;
}
.Ekjuhf.Jj6Lae {
  color: #b3261e;
}
.AfGCob {
  margin-right: 8px;
  margin-top: -2px;
}
.xTjuxe {
  display: block;
  height: 16px;
  width: 16px;
}
.edhGSc {
  -webkit-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  outline: none;
  padding-bottom: 8px;
}
.RpC4Ne {
  min-height: 1.5em;
  position: relative;
  vertical-align: top;
}
.Pc9Gce {
  display: flex;
  position: relative;
  padding-top: 14px;
}
.KHxj8b {
  box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: transparent;
  border: none;
  display: block;
  font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  margin: 0;
  outline: none;
  padding: 0;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 0;
  overflow-y: visible;
  overflow-x: hidden;
}
.KHxj8b.VhWN2c {
  text-align: center;
}
.edhGSc.dm7YTc .KHxj8b {
  color: rgba(255, 255, 255, 0.87);
}
.edhGSc.u3bW4e.dm7YTc .KHxj8b {
  color: #fff;
}
.z0oSpf {
  background-color: rgba(0, 0, 0, 0.12);
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}
.edhGSc.dm7YTc > .RpC4Ne > .z0oSpf {
  background-color: rgba(255, 255, 255, 0.12);
}
.Bfurwb {
  transform: scaleX(0);
  background-color: #4285f4;
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}
.edhGSc.k0tWj > .RpC4Ne > .z0oSpf,
.edhGSc.k0tWj > .RpC4Ne > .Bfurwb {
  background-color: #d50000;
  height: 2px;
}
.edhGSc.k0tWj.dm7YTc > .RpC4Ne > .z0oSpf,
.edhGSc.k0tWj.dm7YTc > .RpC4Ne > .Bfurwb {
  background-color: #ff6e6e;
}
.edhGSc.RDPZE .KHxj8b {
  color: rgba(0, 0, 0, 0.38);
}
.edhGSc.RDPZE > .RpC4Ne > .z0oSpf {
  background: none;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
}
.Bfurwb.Y2Zypf {
  animation: quantumWizPaperInputRemoveUnderline 0.3s
    cubic-bezier(0.4, 0, 0.2, 1);
}
.edhGSc.u3bW4e > .RpC4Ne > .Bfurwb {
  animation: quantumWizPaperInputAddUnderline 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scaleX(1);
}
.edhGSc.FPYHkb > .RpC4Ne {
  padding-top: 24px;
}
.fqp6hd {
  transform-origin: top left;
  transform: translate(0, -22px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: color, top, transform;
  -webkit-transition-property: color, top, transform, -webkit-transform;
  transition-property: color, top, transform, -webkit-transform;
  color: rgba(0, 0, 0, 0.38);
  font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 16px;
  pointer-events: none;
  position: absolute;
  top: 100%;
  width: 100%;
}
.edhGSc.u3bW4e > .RpC4Ne > .fqp6hd,
.edhGSc.CDELXb > .RpC4Ne > .fqp6hd,
.edhGSc.LydCob .fqp6hd {
  transform: scale(0.75);
  top: 16px;
}
.edhGSc.dm7YTc > .RpC4Ne > .fqp6hd {
  color: rgba(255, 255, 255, 0.38);
}
.edhGSc.u3bW4e > .RpC4Ne > .fqp6hd,
.edhGSc.u3bW4e.dm7YTc > .RpC4Ne > .fqp6hd {
  color: #4285f4;
}
.F1pOBe {
  color: rgba(0, 0, 0, 0.38);
  font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  bottom: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.edhGSc.dm7YTc .F1pOBe {
  color: rgba(255, 255, 255, 0.38);
}
.edhGSc.CDELXb > .RpC4Ne > .F1pOBe {
  display: none;
}
.S1BUyf {
  -webkit-tap-highlight-color: transparent;
  font: 400 12px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  height: 16px;
  margin-left: auto;
  padding-left: 16px;
  padding-top: 8px;
  pointer-events: none;
  text-align: right;
  color: rgba(0, 0, 0, 0.38);
  white-space: nowrap;
}
.edhGSc.dm7YTc > .S1BUyf {
  color: rgba(255, 255, 255, 0.38);
}
.edhGSc.wrxyb {
  padding-bottom: 4px;
}
.v6odTb,
.YElZX:not(:empty) {
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font: 400 12px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  min-height: 16px;
  padding-top: 8px;
}
.edhGSc.wrxyb .jE8NUc {
  display: flex;
}
.YElZX {
  pointer-events: none;
}
.v6odTb {
  color: #d50000;
}
.edhGSc.dm7YTc .v6odTb {
  color: #ff6e6e;
}
.YElZX {
  opacity: 0.3;
}
.edhGSc.k0tWj .YElZX,
.edhGSc:not(.k0tWj) .YElZX:not(:empty) + .v6odTb {
  display: none;
}
@keyframes quantumWizPaperInputRemoveUnderline {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }
  to {
    transform: scaleX(1);
    opacity: 0;
  }
}
@keyframes quantumWizPaperInputAddUnderline {
  0% {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
.X3mtXb {
  box-sizing: content-box;
}
.FAiUob,
.X3mtXb {
  display: block;
  padding: 16px 0 0;
  width: 100%;
}
.AFTWye.OcVpRe .X3mtXb,
.AFTWye.OcVpRe .FAiUob {
  padding: 24px 0 0;
  padding-bottom: 0;
}
:first-child > .X3mtXb,
:first-child > .FAiUob,
:first-child.OcVpRe > .X3mtXb,
:first-child.OcVpRe > .FAiUob {
  padding: 8px 0 0;
}
.AFTWye .X3mtXb .oJeWuf {
  height: 56px;
  padding-top: 0;
}
.AFTWye.OcVpRe .X3mtXb .oJeWuf {
  height: 36px;
}
.X3mtXb .Wic03c {
  align-items: center;
  position: static;
  top: 0;
}
.X3mtXb .snByac {
  background: #fff;
  bottom: 17px;
  box-sizing: border-box;
  color: #444746;
  font-size: 16px;
  font-weight: 400;
  left: 8px;
  max-width: calc(100% - (2 * 8px));
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  width: auto;
  z-index: 1;
}
.X3mtXb.IYewr.u3bW4e .zHQkBf:not([disabled]) ~ .snByac {
  color: #b3261e;
}
.AFTWye.OcVpRe .X3mtXb .snByac {
  bottom: 9px;
  color: #444746;
  font-size: 14px;
  left: 4px;
  line-height: 16px;
  padding: 0 6px;
}
.AFTWye.OcVpRe .u3bW4e .snByac,
.AFTWye.OcVpRe .CDELXb .snByac {
  transform: scale(0.75) translateY(-155%);
}
.X3mtXb .ndJi5d {
  top: inherit;
  pointer-events: auto;
}
.X3mtXb .ovnfwe {
  pointer-events: auto;
}
.X3mtXb .Is7Fhb,
.X3mtXb .RxsGPe {
  font-size: 12px;
  opacity: 1;
  padding-top: 4px;
}
.AFTWye .Is7Fhb {
  color: #444746;
  margin-left: 16px;
}
.AFTWye.OcVpRe .Is7Fhb {
  margin-left: 10px;
}
.X3mtXb .RxsGPe {
  color: #b3261e;
}
.X3mtXb .Is7Fhb:empty,
.X3mtXb .RxsGPe:empty {
  display: none;
}
.X3mtXb .zHQkBf {
  border-radius: 4px;
  color: #1f1f1f;
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 16px;
  height: 28px;
  margin: 1px 1px 0 1px;
  padding: 13px 15px;
  width: 100%;
  z-index: 1;
}
.X3mtXb.u3bW4e .zHQkBf,
.X3mtXb.IYewr .zHQkBf {
  margin: 2px 2px 0 2px;
  padding: 12px 14px;
}
.AFTWye.OcVpRe .X3mtXb .zHQkBf {
  font-size: 14px;
  height: 20px;
  padding: 7px 9px;
}
.AFTWye.OcVpRe .u3bW4e .zHQkBf,
.AFTWye.OcVpRe .IYewr .zHQkBf {
  height: 20px;
  padding: 6px 8px;
}
.UOsO2 .Wic03c,
.UOsO2 .zHQkBf,
.UOsO2 .iHd5yb,
.UOsO2 .MQL3Ob {
  direction: ltr;
  text-align: left;
}
.UOsO2 .Wic03c .snByac {
  direction: ltr;
}
.X3mtXb .iHd5yb {
  padding-left: 15px;
}
.X3mtXb.u3bW4e .iHd5yb {
  padding-left: 14px;
}
.X3mtXb .MQL3Ob {
  padding-right: 15px;
  z-index: 1;
}
.X3mtXb.u3bW4e .MQL3Ob {
  padding-right: 15px;
}
.AFTWye.OcVpRe .X3mtXb .iHd5yb {
  padding-left: 9px;
}
.AFTWye.OcVpRe .X3mtXb.u3bW4e .iHd5yb {
  padding-left: 8px;
}
.AFTWye.OcVpRe .X3mtXb .MQL3Ob,
.AFTWye.OcVpRe .X3mtXb.u3bW4e .MQL3Ob {
  padding-right: 9px;
}
.X3mtXb .AxOyFc {
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
}
.X3mtXb .whsOnd:not([disabled]):focus ~ .AxOyFc {
  color: #0b57d0;
}
.X3mtXb .mIZh1c {
  border: 1px solid #747775;
  border-radius: 4px;
  bottom: 0;
  box-sizing: border-box;
}
.X3mtXb .cXrdqd {
  border-radius: 4px;
  bottom: 0;
  opacity: 0;
  transform: none;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: calc(100% - 2 * 2px);
}
.X3mtXb .mIZh1c,
.X3mtXb .cXrdqd,
.X3mtXb.IYewr .mIZh1c,
.X3mtXb.IYewr .cXrdqd {
  background-color: transparent;
}
.X3mtXb .mIZh1c,
.X3mtXb.k0tWj .mIZh1c {
  height: 100%;
}
.X3mtXb.IYewr .cXrdqd {
  height: calc(100% - 2 * 1px);
  width: calc(100% - 2 * 1px);
}
.X3mtXb .cXrdqd,
.X3mtXb.IYewr.u3bW4e .cXrdqd {
  height: calc(100% - 2 * 2px);
  width: calc(100% - 2 * 2px);
}
.X3mtXb.u3bW4e .cXrdqd,
.X3mtXb.IYewr .cXrdqd {
  animation: none;
  opacity: 1;
}
.X3mtXb.u3bW4e .cXrdqd {
  border: 2px solid #0b57d0;
}
.X3mtXb.IYewr.u3bW4e .cXrdqd {
  border-width: 2px;
}
.X3mtXb.IYewr .cXrdqd {
  border: 1px solid #b3261e;
}
.X3mtXb.IYewr.CDELXb .snByac {
  color: #b3261e;
}
.X3mtXb .zHQkBf[disabled] {
  color: rgba(31, 31, 31, 0.502);
}
.FAiUob .mIZh1c {
  background-color: #747775;
}
.FAiUob .cXrdqd {
  background-color: #0b57d0;
}
.FAiUob .snByac {
  color: #444746;
}
.FAiUob.u3bW4e .snByac.snByac {
  color: #0b57d0;
}
.Em2Ord {
  margin: 16px 0;
  outline: none;
}
.Em2Ord + .Em2Ord {
  margin-top: 24px;
}
.Em2Ord:first-child {
  margin-top: 0;
}
.Em2Ord:last-child {
  margin-bottom: 0;
}
.PsAlOe {
  border-radius: 8px;
  padding: 16px;
}
.PsAlOe > :first-child {
  margin-top: 0;
}
.PsAlOe > :last-child {
  margin-bottom: 0;
}
.PsAlOe .x9zgF {
  color: #1f1f1f;
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.5;
}
.PsAlOe .yTaH4c {
  color: #1f1f1f;
}
.PsAlOe.YFdWic .vYeFie {
  margin-left: 64px;
  width: calc(100% - 64px);
}
.PsAlOe.YFdWic .yTaH4c {
  margin-left: 64px;
  width: calc(100% - 64px);
  color: #444746;
  margin-top: 4px;
}
.PsAlOe.YFdWic:not(.S7S4N) .vYeFie {
  margin-left: 0;
  width: 0;
}
.PsAlOe:not(.S7S4N) > .yTaH4c {
  margin-top: 0;
}
.PsAlOe.sj692e {
  background: #e8f0fe;
}
.PsAlOe.Xq8bDe {
  background: #fce8e6;
}
.PsAlOe.rNe0id {
  background: #fef7e0;
}
.PsAlOe.YFdWic {
  background: linear-gradient(
      0deg,
      rgba(124, 172, 248, 0.05),
      rgba(124, 172, 248, 0.05)
    ),
    linear-gradient(0deg, rgba(117, 117, 117, 0.02), rgba(117, 117, 117, 0.02)),
    #fff;
  color: #1f1f1f;
  min-height: 80px;
  position: relative;
}
.PsAlOe:not(.S7S4N) {
  display: flex;
}
.Em2Ord.eLNT1d {
  display: none;
}
.Em2Ord.RDPZE {
  opacity: 0.5;
  pointer-events: none;
}
.Em2Ord.RDPZE .Em2Ord.RDPZE {
  opacity: 1;
}
.se0rCf {
  background: linear-gradient(
      0deg,
      rgba(124, 172, 248, 0.05),
      rgba(124, 172, 248, 0.05)
    ),
    linear-gradient(0deg, rgba(117, 117, 117, 0.02), rgba(117, 117, 117, 0.02)),
    #fff;
  border-radius: 28px;
  padding: 16px;
}
.se0rCf .X3mtXb .snByac,
.se0rCf.Em2Ord .i79UJc .snByac {
  background: linear-gradient(
      0deg,
      rgba(124, 172, 248, 0.05),
      rgba(124, 172, 248, 0.05)
    ),
    linear-gradient(0deg, rgba(117, 117, 117, 0.02), rgba(117, 117, 117, 0.02)),
    #fff;
}
.se0rCf .SgHwWb {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.se0rCf .SgHwWb .BqKGqe {
  margin-bottom: 0;
  margin-top: 0;
}
.nn7x3d {
  border-bottom: 1px solid #e1e3e1;
  display: flex;
  flex-direction: column;
}
.nn7x3d .V9RXW .rBUW7e,
.nn7x3d .nn7x3d:last-child {
  border-bottom: 0;
}
.dwkQ3b:not(.jVwmLb) {
  border-bottom: 0;
}
.nn7x3d .nn7x3d:last-child .ozEFYb {
  padding-bottom: 0;
}
.nn7x3d.dwkQ3b {
  border-bottom: 0;
}
.vYeFie:empty,
.osxBFb:empty {
  display: none;
}
.vYeFie > :first-child {
  margin-top: 0;
  padding-top: 0;
}
.vYeFie > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.LwQQGe {
  margin: 0 0 8px;
}
.nn7x3d[data-expand-type="1"] .ozEFYb,
.Em2Ord[data-expand-type="1"] .HKEKLe {
  cursor: pointer;
}
.nn7x3d .ozEFYb {
  padding-bottom: 16px;
}
.x9zgF {
  align-items: center;
  color: #1f1f1f;
  display: flex;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.Em2Ord.S7S4N .Em2Ord:not(.nn7x3d) .x9zgF {
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.5;
}
.nn7x3d.u3bW4e .x9zgF {
  position: relative;
}
.nn7x3d[data-expand-type="1"].u3bW4e .x9zgF:after {
  background: rgba(11, 87, 208, 0.149);
  border-radius: 8px;
  bottom: -4px;
  content: "";
  left: -8px;
  position: absolute;
  right: -8px;
  top: -4px;
  z-index: -1;
}
.HKEKLe {
  background: none;
  border: none;
  color: inherit;
  box-flex: 1;
  flex-grow: 1;
  font: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: inherit;
}
.HKEKLe::-moz-focus-inner {
  border: 0;
}
.HKEKLe [jsslot] {
  position: relative;
}
.MI3XC {
  margin-left: 16px;
}
.MI3XC .aHWa4d {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 24px;
}
.nn7x3d .MI3XC,
.nn7x3d .HKEKLe,
.nn7x3d .CuWxc {
  pointer-events: none;
}
.nn7x3d.jVwmLb .aHWa4d {
  transform: rotate(-180deg);
}
.CuWxc {
  color: #444746;
  flex-shrink: 0;
  height: 20px;
  margin-right: 16px;
  width: 20px;
}
.CuWxc .C3qbwe {
  height: 100%;
  width: 100%;
}
.PsAlOe .CuWxc {
  margin-top: 0;
}
.PsAlOe.sj692e .CuWxc {
  color: #1967d2;
}
.PsAlOe.Xq8bDe .CuWxc {
  color: #b3261e;
}
.PsAlOe.rNe0id .CuWxc {
  color: #ea8600;
}
.PsAlOe.YFdWic .CuWxc {
  height: 48px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 48px;
}
.osxBFb {
  color: #444746;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: 8px;
}
.vYeFie:empty + .yTaH4c {
  margin-top: 0;
}
.yTaH4c {
  margin-bottom: 16px;
  margin-top: 10px;
}
.se0rCf .yTaH4c {
  margin-bottom: 0;
  margin-top: 16px;
}
.yTaH4c:only-child {
  margin-bottom: 0;
  margin-top: 0;
}
.nn7x3d .yTaH4c {
  margin-top: 0;
  overflow: hidden;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.nn7x3d.jVwmLb .yTaH4c {
  margin-bottom: 0;
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}
.yTaH4c > [jsslot] > :first-child {
  margin-top: 0;
  padding-top: 0;
}
.yTaH4c > [jsslot] > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.kvM7xe {
  align-self: center;
  align-self: center;
  margin-bottom: 0;
}
.g0ndYb {
  border-bottom: 1px solid #e1e3e1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 0;
  margin-top: 12px;
}
.VXMllb {
  background: #fff;
  display: flex;
  height: 24px;
  margin-top: -12px;
}
.dwkQ3b:not(.jVwmLb) .g0ndYb {
  display: none;
}
.VfPpkd-MPu53c {
  padding: 11px;
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: 0;
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.VfPpkd-MPu53c .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c .VfPpkd-OYHm6b::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, #000);
}
.VfPpkd-MPu53c:hover .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-OYHm6b::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-OYHm6b::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.VfPpkd-MPu53c:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-OYHm6b::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-MPu53c:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-OYHm6b::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd .VfPpkd-OYHm6b::after {
  background-color: #018786;
  background-color: var(
    --mdc-ripple-color,
    var(--mdc-theme-secondary, #018786)
  );
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd:hover .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-OYHm6b::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-OYHm6b::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-OYHm6b::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-OYHm6b::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-MPu53c-OWXEXe-gk6SMd
  .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-MPu53c-OWXEXe-gk6SMd
  .VfPpkd-OYHm6b::after {
  background-color: #018786;
  background-color: var(
    --mdc-ripple-color,
    var(--mdc-theme-secondary, #018786)
  );
}
.VfPpkd-MPu53c .VfPpkd-YQoJzd {
  top: 11px;
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: 11px;
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe {
  top: 0;
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: 0;
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: 0;
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: 40px;
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: 40px;
  height: var(--mdc-checkbox-touch-target-size, 40px);
}
.VfPpkd-MPu53c
  .VfPpkd-muHVFf-bMcfAe:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd {
  border-color: rgba(0, 0, 0, 0.54);
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe:enabled:checked ~ .VfPpkd-YQoJzd,
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe:enabled:indeterminate ~ .VfPpkd-YQoJzd,
.VfPpkd-MPu53c
  .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]:enabled
  ~ .VfPpkd-YQoJzd {
  border-color: #018786;
  border-color: var(
    --mdc-checkbox-checked-color,
    var(--mdc-theme-secondary, #018786)
  );
  background-color: #018786;
  background-color: var(
    --mdc-checkbox-checked-color,
    var(--mdc-theme-secondary, #018786)
  );
}
@keyframes mdc-checkbox-fade-in-background-8A000000FF01878600000000FF018786 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #018786;
    border-color: var(
      --mdc-checkbox-checked-color,
      var(--mdc-theme-secondary, #018786)
    );
    background-color: #018786;
    background-color: var(
      --mdc-checkbox-checked-color,
      var(--mdc-theme-secondary, #018786)
    );
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FF01878600000000FF018786 {
  0%,
  80% {
    border-color: #018786;
    border-color: var(
      --mdc-checkbox-checked-color,
      var(--mdc-theme-secondary, #018786)
    );
    background-color: #018786;
    background-color: var(
      --mdc-checkbox-checked-color,
      var(--mdc-theme-secondary, #018786)
    );
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-barxie
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-A9y3zc
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-in-background-8A000000FF01878600000000FF018786;
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-out-background-8A000000FF01878600000000FF018786;
}
.VfPpkd-MPu53c
  .VfPpkd-muHVFf-bMcfAe[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd {
  border-color: rgba(0, 0, 0, 0.38);
  border-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
  background-color: transparent;
}
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe[disabled]:checked ~ .VfPpkd-YQoJzd,
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe[disabled]:indeterminate ~ .VfPpkd-YQoJzd,
.VfPpkd-MPu53c
  .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"][disabled]
  ~ .VfPpkd-YQoJzd {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.38);
  background-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
}
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe:enabled ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb {
  color: #fff;
  color: var(--mdc-checkbox-ink-color, #fff);
}
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe:enabled ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d {
  border-color: #fff;
  border-color: var(--mdc-checkbox-ink-color, #fff);
}
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe:disabled ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb {
  color: #fff;
  color: var(--mdc-checkbox-ink-color, #fff);
}
.VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe:disabled ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d {
  border-color: #fff;
  border-color: var(--mdc-checkbox-ink-color, #fff);
}
@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(1turn);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%,
  100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.VfPpkd-MPu53c {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}
.VfPpkd-MPu53c[hidden] {
  display: none;
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-sMek6-LhBDec,
.VfPpkd-MPu53c:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-sMek6-LhBDec {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
@media screen and (forced-colors: active) {
  .VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-sMek6-LhBDec,
  .VfPpkd-MPu53c:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-sMek6-LhBDec {
    border-color: CanvasText;
  }
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-sMek6-LhBDec::after,
.VfPpkd-MPu53c:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-sMek6-LhBDec::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
    .VfPpkd-sMek6-LhBDec::after,
  .VfPpkd-MPu53c:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-sMek6-LhBDec::after {
    border-color: CanvasText;
  }
}
@media (-ms-high-contrast: none) {
  .VfPpkd-MPu53c .VfPpkd-sMek6-LhBDec {
    display: none;
  }
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .VfPpkd-SJnn3d {
    margin: 0 1px;
  }
}
.VfPpkd-MPu53c-OWXEXe-OWB6Me {
  cursor: default;
  pointer-events: none;
}
.VfPpkd-YQoJzd {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1),
    border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.VfPpkd-HUofsb {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.18s 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.VfPpkd-MPu53c-OWXEXe-mWPk3d .VfPpkd-HUofsb {
  opacity: 1;
}
.VfPpkd-HUofsb-Jt5cK {
  transition: stroke-dashoffset 0.18s 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}
.VfPpkd-SJnn3d {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1),
    transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-barxie .VfPpkd-YQoJzd,
.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-A9y3zc .VfPpkd-YQoJzd,
.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-iAfbIe .VfPpkd-YQoJzd,
.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-iAfbIe .VfPpkd-YQoJzd {
  animation-duration: 0.18s;
  animation-timing-function: linear;
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-barxie .VfPpkd-HUofsb-Jt5cK {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 0.18s linear 0s;
  transition: none;
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-A9y3zc .VfPpkd-SJnn3d {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-iAfbIe .VfPpkd-HUofsb-Jt5cK {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-A9y3zc .VfPpkd-HUofsb {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-A9y3zc .VfPpkd-SJnn3d {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-barxie .VfPpkd-HUofsb {
  animation: mdc-checkbox-indeterminate-checked-checkmark 0.5s linear 0s;
  transition: none;
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-barxie .VfPpkd-SJnn3d {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 0.5s linear 0s;
  transition: none;
}
.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-iAfbIe .VfPpkd-SJnn3d {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 0.3s linear 0s;
  transition: none;
}
.VfPpkd-muHVFf-bMcfAe:checked ~ .VfPpkd-YQoJzd,
.VfPpkd-muHVFf-bMcfAe:indeterminate ~ .VfPpkd-YQoJzd,
.VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"] ~ .VfPpkd-YQoJzd {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1),
    background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-muHVFf-bMcfAe:checked ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb-Jt5cK,
.VfPpkd-muHVFf-bMcfAe:indeterminate ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb-Jt5cK,
.VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]
  ~ .VfPpkd-YQoJzd
  .VfPpkd-HUofsb-Jt5cK {
  stroke-dashoffset: 0;
}
.VfPpkd-muHVFf-bMcfAe {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}
.VfPpkd-muHVFf-bMcfAe:disabled {
  cursor: default;
  pointer-events: none;
}
.VfPpkd-MPu53c-OWXEXe-dgl2Hf {
  margin: 4px;
  margin: calc(
    (
        var(--mdc-checkbox-state-layer-size, 48px) -
          var(--mdc-checkbox-state-layer-size, 40px)
      ) / 2
  );
}
.VfPpkd-MPu53c-OWXEXe-dgl2Hf .VfPpkd-muHVFf-bMcfAe {
  top: -4px;
  top: calc(
    (
        var(--mdc-checkbox-state-layer-size, 40px) -
          var(--mdc-checkbox-state-layer-size, 48px)
      ) / 2
  );
  right: -4px;
  right: calc(
    (
        var(--mdc-checkbox-state-layer-size, 40px) -
          var(--mdc-checkbox-state-layer-size, 48px)
      ) / 2
  );
  left: -4px;
  left: calc(
    (
        var(--mdc-checkbox-state-layer-size, 40px) -
          var(--mdc-checkbox-state-layer-size, 48px)
      ) / 2
  );
  width: 48px;
  width: var(--mdc-checkbox-state-layer-size, 48px);
  height: 48px;
  height: var(--mdc-checkbox-state-layer-size, 48px);
}
.VfPpkd-muHVFf-bMcfAe:checked ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb {
  transition: opacity 0.18s 0ms cubic-bezier(0, 0, 0.2, 1),
    transform 0.18s 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}
.VfPpkd-muHVFf-bMcfAe:checked ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d {
  transform: scaleX(1) rotate(-45deg);
}
.VfPpkd-muHVFf-bMcfAe:indeterminate ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb,
.VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]
  ~ .VfPpkd-YQoJzd
  .VfPpkd-HUofsb {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1),
    transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.VfPpkd-muHVFf-bMcfAe:indeterminate ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d,
.VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]
  ~ .VfPpkd-YQoJzd
  .VfPpkd-SJnn3d {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-mWPk3d .VfPpkd-YQoJzd,
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-mWPk3d .VfPpkd-HUofsb,
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-mWPk3d .VfPpkd-HUofsb-Jt5cK,
.VfPpkd-MPu53c.VfPpkd-MPu53c-OWXEXe-mWPk3d .VfPpkd-SJnn3d {
  transition: none;
}
.VfPpkd-MPu53c {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.VfPpkd-MPu53c .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c .VfPpkd-OYHm6b::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.VfPpkd-MPu53c .VfPpkd-OYHm6b::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}
.VfPpkd-MPu53c .VfPpkd-OYHm6b::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d .VfPpkd-OYHm6b::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d .VfPpkd-OYHm6b::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd .VfPpkd-OYHm6b::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc .VfPpkd-OYHm6b::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf .VfPpkd-OYHm6b::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-MPu53c .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c .VfPpkd-OYHm6b::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d .VfPpkd-OYHm6b::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-MPu53c.VfPpkd-ksKsZd-mWPk3d .VfPpkd-OYHm6b::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-MPu53c {
  z-index: 0;
}
.VfPpkd-MPu53c .VfPpkd-OYHm6b::before,
.VfPpkd-MPu53c .VfPpkd-OYHm6b::after {
  z-index: -1;
  z-index: var(--mdc-ripple-z-index, -1);
}
.VfPpkd-OYHm6b {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .VfPpkd-MPu53c
    .VfPpkd-muHVFf-bMcfAe[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .VfPpkd-YQoJzd {
    border-color: GrayText;
    border-color: var(--mdc-checkbox-disabled-unselected-icon-color, GrayText);
    background-color: transparent;
  }
  .VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe[disabled]:checked ~ .VfPpkd-YQoJzd,
  .VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe[disabled]:indeterminate ~ .VfPpkd-YQoJzd,
  .VfPpkd-MPu53c
    .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"][disabled]
    ~ .VfPpkd-YQoJzd {
    border-color: GrayText;
    background-color: GrayText;
    background-color: var(
      --mdc-checkbox-disabled-selected-icon-color,
      GrayText
    );
  }
  .VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe:enabled ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb {
    color: ButtonText;
    color: var(--mdc-checkbox-selected-checkmark-color, ButtonText);
  }
  .VfPpkd-MPu53c .VfPpkd-muHVFf-bMcfAe:enabled ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d {
    border-color: ButtonText;
    border-color: var(--mdc-checkbox-selected-checkmark-color, ButtonText);
  }
  .VfPpkd-MPu53c
    .VfPpkd-muHVFf-bMcfAe:disabled
    ~ .VfPpkd-YQoJzd
    .VfPpkd-HUofsb {
    color: ButtonFace;
    color: var(--mdc-checkbox-disabled-selected-checkmark-color, ButtonFace);
  }
  .VfPpkd-MPu53c
    .VfPpkd-muHVFf-bMcfAe:disabled
    ~ .VfPpkd-YQoJzd
    .VfPpkd-SJnn3d {
    border-color: ButtonFace;
    border-color: var(
      --mdc-checkbox-disabled-selected-checkmark-color,
      ButtonFace
    );
  }
}
.Ne8lhe
  .VfPpkd-muHVFf-bMcfAe[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd {
  border-color: rgba(60, 64, 67, 0.38);
  border-color: var(
    --mdc-checkbox-disabled-unselected-icon-color,
    rgba(60, 64, 67, 0.38)
  );
  background-color: transparent;
}
.Ne8lhe .VfPpkd-muHVFf-bMcfAe[disabled]:checked ~ .VfPpkd-YQoJzd,
.Ne8lhe .VfPpkd-muHVFf-bMcfAe[disabled]:indeterminate ~ .VfPpkd-YQoJzd,
.Ne8lhe
  .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"][disabled]
  ~ .VfPpkd-YQoJzd {
  border-color: transparent;
  background-color: rgba(60, 64, 67, 0.38);
  background-color: var(
    --mdc-checkbox-disabled-selected-icon-color,
    rgba(60, 64, 67, 0.38)
  );
}
.Ne8lhe .VfPpkd-muHVFf-bMcfAe:enabled ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb {
  color: #fff;
  color: var(--mdc-checkbox-selected-checkmark-color, #fff);
}
.Ne8lhe .VfPpkd-muHVFf-bMcfAe:enabled ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d {
  border-color: #fff;
  border-color: var(--mdc-checkbox-selected-checkmark-color, #fff);
}
.Ne8lhe .VfPpkd-muHVFf-bMcfAe:disabled ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb {
  color: #fff;
  color: var(--mdc-checkbox-disabled-selected-checkmark-color, #fff);
}
.Ne8lhe .VfPpkd-muHVFf-bMcfAe:disabled ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d {
  border-color: #fff;
  border-color: var(--mdc-checkbox-disabled-selected-checkmark-color, #fff);
}
.Ne8lhe
  .VfPpkd-muHVFf-bMcfAe:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd {
  border-color: #5f6368;
  border-color: var(--mdc-checkbox-unselected-icon-color, #5f6368);
  background-color: transparent;
}
.Ne8lhe .VfPpkd-muHVFf-bMcfAe:enabled:checked ~ .VfPpkd-YQoJzd,
.Ne8lhe .VfPpkd-muHVFf-bMcfAe:enabled:indeterminate ~ .VfPpkd-YQoJzd,
.Ne8lhe
  .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]:enabled
  ~ .VfPpkd-YQoJzd {
  border-color: #1a73e8;
  border-color: var(--mdc-checkbox-selected-icon-color, #1a73e8);
  background-color: #1a73e8;
  background-color: var(--mdc-checkbox-selected-icon-color, #1a73e8);
}
@keyframes mdc-checkbox-fade-in-background-FF5F6368FF1A73E800000000FF1A73E8 {
  0% {
    border-color: #5f6368;
    border-color: var(--mdc-checkbox-unselected-icon-color, #5f6368);
    background-color: transparent;
  }
  50% {
    border-color: #1a73e8;
    border-color: var(--mdc-checkbox-selected-icon-color, #1a73e8);
    background-color: #1a73e8;
    background-color: var(--mdc-checkbox-selected-icon-color, #1a73e8);
  }
}
@keyframes mdc-checkbox-fade-out-background-FF5F6368FF1A73E800000000FF1A73E8 {
  0%,
  80% {
    border-color: #1a73e8;
    border-color: var(--mdc-checkbox-selected-icon-color, #1a73e8);
    background-color: #1a73e8;
    background-color: var(--mdc-checkbox-selected-icon-color, #1a73e8);
  }
  100% {
    border-color: #5f6368;
    border-color: var(--mdc-checkbox-unselected-icon-color, #5f6368);
    background-color: transparent;
  }
}
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-barxie
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-A9y3zc
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-in-background-FF5F6368FF1A73E800000000FF1A73E8;
}
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-out-background-FF5F6368FF1A73E800000000FF1A73E8;
}
.Ne8lhe:hover
  .VfPpkd-muHVFf-bMcfAe:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd {
  border-color: #202124;
  border-color: var(--mdc-checkbox-unselected-hover-icon-color, #202124);
  background-color: transparent;
}
.Ne8lhe:hover .VfPpkd-muHVFf-bMcfAe:enabled:checked ~ .VfPpkd-YQoJzd,
.Ne8lhe:hover .VfPpkd-muHVFf-bMcfAe:enabled:indeterminate ~ .VfPpkd-YQoJzd,
.Ne8lhe:hover
  .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]:enabled
  ~ .VfPpkd-YQoJzd {
  border-color: #174ea6;
  border-color: var(--mdc-checkbox-selected-hover-icon-color, #174ea6);
  background-color: #174ea6;
  background-color: var(--mdc-checkbox-selected-hover-icon-color, #174ea6);
}
.Ne8lhe:hover.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-barxie
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:hover.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-A9y3zc
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-in-background-FF202124FF174EA600000000FF174EA6;
}
.Ne8lhe:hover.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:hover.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-out-background-FF202124FF174EA600000000FF174EA6;
}
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-muHVFf-bMcfAe:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-muHVFf-bMcfAe:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd {
  border-color: #202124;
  border-color: var(--mdc-checkbox-unselected-focus-icon-color, #202124);
  background-color: transparent;
}
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-muHVFf-bMcfAe:enabled:checked
  ~ .VfPpkd-YQoJzd,
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-muHVFf-bMcfAe:enabled:indeterminate
  ~ .VfPpkd-YQoJzd,
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-muHVFf-bMcfAe:enabled:checked
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-muHVFf-bMcfAe:enabled:indeterminate
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]:enabled
  ~ .VfPpkd-YQoJzd {
  border-color: #174ea6;
  border-color: var(--mdc-checkbox-selected-focus-icon-color, #174ea6);
  background-color: #174ea6;
  background-color: var(--mdc-checkbox-selected-focus-icon-color, #174ea6);
}
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-barxie
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-A9y3zc
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-barxie
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-A9y3zc
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-in-background-FF202124FF174EA600000000FF174EA6;
}
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-out-background-FF202124FF174EA600000000FF174EA6;
}
.Ne8lhe:not(:disabled):active
  .VfPpkd-muHVFf-bMcfAe:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd {
  border-color: #202124;
  border-color: var(--mdc-checkbox-unselected-pressed-icon-color, #202124);
  background-color: transparent;
}
.Ne8lhe:not(:disabled):active
  .VfPpkd-muHVFf-bMcfAe:enabled:checked
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(:disabled):active
  .VfPpkd-muHVFf-bMcfAe:enabled:indeterminate
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(:disabled):active
  .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"]:enabled
  ~ .VfPpkd-YQoJzd {
  border-color: #174ea6;
  border-color: var(--mdc-checkbox-selected-pressed-icon-color, #174ea6);
  background-color: #174ea6;
  background-color: var(--mdc-checkbox-selected-pressed-icon-color, #174ea6);
}
@keyframes mdc-checkbox-fade-in-background-FF202124FF174EA600000000FF174EA6 {
  0% {
    border-color: #202124;
    border-color: var(--mdc-checkbox-unselected-pressed-icon-color, #202124);
    background-color: transparent;
  }
  50% {
    border-color: #174ea6;
    border-color: var(--mdc-checkbox-selected-pressed-icon-color, #174ea6);
    background-color: #174ea6;
    background-color: var(--mdc-checkbox-selected-pressed-icon-color, #174ea6);
  }
}
@keyframes mdc-checkbox-fade-out-background-FF202124FF174EA600000000FF174EA6 {
  0%,
  80% {
    border-color: #174ea6;
    border-color: var(--mdc-checkbox-selected-pressed-icon-color, #174ea6);
    background-color: #174ea6;
    background-color: var(--mdc-checkbox-selected-pressed-icon-color, #174ea6);
  }
  100% {
    border-color: #202124;
    border-color: var(--mdc-checkbox-unselected-pressed-icon-color, #202124);
    background-color: transparent;
  }
}
.Ne8lhe:not(:disabled):active.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-barxie
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(:disabled):active.VfPpkd-MPu53c-OWXEXe-vwu2ne-iAfbIe-A9y3zc
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-in-background-FF202124FF174EA600000000FF174EA6;
}
.Ne8lhe:not(:disabled):active.VfPpkd-MPu53c-OWXEXe-vwu2ne-barxie-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd,
.Ne8lhe:not(:disabled):active.VfPpkd-MPu53c-OWXEXe-vwu2ne-A9y3zc-iAfbIe
  .VfPpkd-muHVFf-bMcfAe:enabled
  ~ .VfPpkd-YQoJzd {
  animation-name: mdc-checkbox-fade-out-background-FF202124FF174EA600000000FF174EA6;
}
.Ne8lhe .VfPpkd-OYHm6b::before,
.Ne8lhe .VfPpkd-OYHm6b::after {
  background-color: #3c4043;
  background-color: var(
    --mdc-checkbox-unselected-hover-state-layer-color,
    #3c4043
  );
}
.Ne8lhe:hover .VfPpkd-OYHm6b::before,
.Ne8lhe.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-OYHm6b::before {
  opacity: 0.04;
  opacity: var(--mdc-checkbox-unselected-hover-state-layer-opacity, 0.04);
}
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-OYHm6b::before,
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-OYHm6b::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-checkbox-unselected-focus-state-layer-opacity, 0.12);
}
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-OYHm6b::after {
  transition: opacity 0.15s linear;
}
.Ne8lhe:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-OYHm6b::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-checkbox-unselected-pressed-state-layer-opacity, 0.1);
}
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(
    --mdc-checkbox-unselected-pressed-state-layer-opacity,
    0.1
  );
}
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd .VfPpkd-OYHm6b::before,
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd .VfPpkd-OYHm6b::after {
  background-color: #1a73e8;
  background-color: var(
    --mdc-checkbox-selected-hover-state-layer-color,
    #1a73e8
  );
}
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd:hover .VfPpkd-OYHm6b::before,
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-OYHm6b::before {
  opacity: 0.04;
  opacity: var(--mdc-checkbox-selected-hover-state-layer-opacity, 0.04);
}
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-OYHm6b::before,
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-OYHm6b::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-checkbox-selected-focus-state-layer-opacity, 0.12);
}
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-OYHm6b::after {
  transition: opacity 0.15s linear;
}
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-OYHm6b::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-checkbox-selected-pressed-state-layer-opacity, 0.1);
}
.Ne8lhe.VfPpkd-MPu53c-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(
    --mdc-checkbox-selected-pressed-state-layer-opacity,
    0.1
  );
}
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-MPu53c-OWXEXe-gk6SMd
  .VfPpkd-OYHm6b::before,
.Ne8lhe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-MPu53c-OWXEXe-gk6SMd
  .VfPpkd-OYHm6b::after {
  background-color: #1a73e8;
  background-color: var(
    --mdc-checkbox-selected-hover-state-layer-color,
    #1a73e8
  );
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .Ne8lhe
    .VfPpkd-muHVFf-bMcfAe[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .VfPpkd-YQoJzd {
    border-color: GrayText;
    border-color: var(--mdc-checkbox-disabled-unselected-icon-color, GrayText);
    background-color: transparent;
  }
  .Ne8lhe .VfPpkd-muHVFf-bMcfAe[disabled]:checked ~ .VfPpkd-YQoJzd,
  .Ne8lhe .VfPpkd-muHVFf-bMcfAe[disabled]:indeterminate ~ .VfPpkd-YQoJzd,
  .Ne8lhe
    .VfPpkd-muHVFf-bMcfAe[data-indeterminate="true"][disabled]
    ~ .VfPpkd-YQoJzd {
    border-color: GrayText;
    background-color: GrayText;
    background-color: var(
      --mdc-checkbox-disabled-selected-icon-color,
      GrayText
    );
  }
  .Ne8lhe .VfPpkd-muHVFf-bMcfAe:enabled ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb {
    color: ButtonText;
    color: var(--mdc-checkbox-selected-checkmark-color, ButtonText);
  }
  .Ne8lhe .VfPpkd-muHVFf-bMcfAe:enabled ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d {
    border-color: ButtonText;
    border-color: var(--mdc-checkbox-selected-checkmark-color, ButtonText);
  }
  .Ne8lhe .VfPpkd-muHVFf-bMcfAe:disabled ~ .VfPpkd-YQoJzd .VfPpkd-HUofsb {
    color: ButtonFace;
    color: var(--mdc-checkbox-disabled-selected-checkmark-color, ButtonFace);
  }
  .Ne8lhe .VfPpkd-muHVFf-bMcfAe:disabled ~ .VfPpkd-YQoJzd .VfPpkd-SJnn3d {
    border-color: ButtonFace;
    border-color: var(
      --mdc-checkbox-disabled-selected-checkmark-color,
      ButtonFace
    );
  }
}
.az2ine {
  will-change: unset;
}
.O1htCb-H9tDt {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}
.O1htCb-H9tDt[hidden] {
  display: none;
}
@charset "UTF-8";
.VfPpkd-O1htCb {
  display: inline-flex;
  position: relative;
}
.VfPpkd-O1htCb .VfPpkd-NLUYnc-V67aGc {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.VfPpkd-O1htCb .VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 16px;
  padding-right: 16px;
}
[dir="rtl"] .VfPpkd-O1htCb .VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-O1htCb .VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 16px;
  padding-right: 16px;
}
.VfPpkd-O1htCb .VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb-f7MjDc {
  margin-left: 0;
  margin-right: 12px;
}
[dir="rtl"] .VfPpkd-O1htCb .VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-O1htCb .VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb-f7MjDc[dir="rtl"] {
  margin-left: 12px;
  margin-right: 0;
}
.VfPpkd-O1htCb[hidden] {
  display: none;
}
.VfPpkd-t08AT-Bz112c {
  display: inline-flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  pointer-events: none;
}
.VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-auswjd,
.VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-mt1Mkb {
  position: absolute;
  top: 0;
  left: 0;
}
.VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-Bd00G {
  width: 41.6666666667%;
  height: 20.8333333333%;
}
.VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-mt1Mkb {
  opacity: 1;
  transition: opacity 75ms linear 75ms;
}
.VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-auswjd {
  opacity: 0;
  transition: opacity 75ms linear;
}
.VfPpkd-O1htCb-OWXEXe-pXU01b .VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-mt1Mkb {
  opacity: 0;
  transition: opacity 49.5ms linear;
}
.VfPpkd-O1htCb-OWXEXe-pXU01b .VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-auswjd {
  opacity: 1;
  transition: opacity 0.1005s linear 49.5ms;
}
.VfPpkd-TkwUic {
  min-width: 0;
  flex: 1 1 auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}
.VfPpkd-uusGie-fmcmS-haAclf {
  display: flex;
  -webkit-appearance: none;
  appearance: none;
  pointer-events: none;
  box-sizing: border-box;
  width: auto;
  min-width: 0;
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  color: inherit;
}
.VfPpkd-uusGie-fmcmS {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle1-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 100%;
  text-align: left;
}
[dir="rtl"] .VfPpkd-uusGie-fmcmS,
.VfPpkd-uusGie-fmcmS[dir="rtl"] {
  text-align: right;
}
.VfPpkd-O1htCb-OWXEXe-OWB6Me {
  cursor: default;
  pointer-events: none;
}
.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb-ibnC6b {
  padding-left: 12px;
  padding-right: 12px;
}
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb-ibnC6b,
.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 12px;
  padding-right: 12px;
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .VfPpkd-YPmvEd::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }
}
@media screen and (-ms-high-contrast: active) and (forced-colors: active),
  screen and (forced-colors: active) and (forced-colors: active) {
  .VfPpkd-YPmvEd::before {
    border-color: CanvasText;
  }
}
.VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb .VfPpkd-cTi5dd,
.VfPpkd-YPmvEd .VfPpkd-rymPhb .VfPpkd-cTi5dd {
  margin-left: 0;
  margin-right: 0;
}
[dir="rtl"] .VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb .VfPpkd-cTi5dd,
[dir="rtl"] .VfPpkd-YPmvEd .VfPpkd-rymPhb .VfPpkd-cTi5dd,
.VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb .VfPpkd-cTi5dd[dir="rtl"],
.VfPpkd-YPmvEd .VfPpkd-rymPhb .VfPpkd-cTi5dd[dir="rtl"] {
  margin-left: 0;
  margin-right: 0;
}
.VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.VfPpkd-YPmvEd .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b,
.VfPpkd-YPmvEd .VfPpkd-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.VfPpkd-YPmvEd .VfPpkd-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-YPmvEd
  .VfPpkd-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.VfPpkd-YPmvEd
  .VfPpkd-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-StrnGf-rymPhb-f7MjDc {
  color: #000;
  color: var(--mdc-theme-on-surface, #000);
}
.VfPpkd-YPmvEd .VfPpkd-rymPhb-KkROqb {
  display: inline-flex;
  align-items: center;
}
.VfPpkd-OkbHre {
  padding-left: 16px;
  padding-right: 16px;
}
[dir="rtl"] .VfPpkd-OkbHre,
.VfPpkd-OkbHre[dir="rtl"] {
  padding-left: 16px;
  padding-right: 16px;
}
.VfPpkd-aJasdd-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
  height: 48px;
}
.VfPpkd-hjZysc-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb {
  height: 64px;
}
.VfPpkd-hjZysc-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-KkROqb {
  margin-top: 20px;
}
.VfPpkd-hjZysc-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
}
.VfPpkd-hjZysc-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-hjZysc-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb
  .VfPpkd-rymPhb-fpDzbe-fmcmS::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.VfPpkd-hjZysc-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  display: block;
  margin-top: 0;
  line-height: normal;
}
.VfPpkd-hjZysc-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-aSi1db-MCEKJb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc {
  padding-left: 0;
  padding-right: 12px;
}
.VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc.VfPpkd-rymPhb-ibnC6b {
  padding-left: 0;
  padding-right: auto;
}
[dir="rtl"] .VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: auto;
  padding-right: 0;
}
.VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc .VfPpkd-rymPhb-KkROqb {
  margin-left: 12px;
  margin-right: 0;
}
[dir="rtl"] .VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc .VfPpkd-rymPhb-KkROqb,
.VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc .VfPpkd-rymPhb-KkROqb[dir="rtl"] {
  margin-left: 0;
  margin-right: 12px;
}
.VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc .VfPpkd-rymPhb-KkROqb {
  width: 36px;
  height: 24px;
}
[dir="rtl"] .VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc,
.VfPpkd-OkbHre-SfQLQb-M1Soyc-bN97Pc[dir="rtl"] {
  padding-left: 12px;
  padding-right: 0;
}
.VfPpkd-OkbHre-SfQLQb-r4m2rf.VfPpkd-rymPhb-ibnC6b {
  padding-left: auto;
  padding-right: 0;
}
[dir="rtl"] .VfPpkd-OkbHre-SfQLQb-r4m2rf.VfPpkd-rymPhb-ibnC6b,
.VfPpkd-OkbHre-SfQLQb-r4m2rf.VfPpkd-rymPhb-ibnC6b[dir="rtl"] {
  padding-left: 0;
  padding-right: auto;
}
.VfPpkd-OkbHre-SfQLQb-r4m2rf .VfPpkd-rymPhb-JMEf7e {
  margin-left: 12px;
  margin-right: 12px;
}
[dir="rtl"] .VfPpkd-OkbHre-SfQLQb-r4m2rf .VfPpkd-rymPhb-JMEf7e,
.VfPpkd-OkbHre-SfQLQb-r4m2rf .VfPpkd-rymPhb-JMEf7e[dir="rtl"] {
  margin-left: 12px;
  margin-right: 12px;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-xl07Ob-XxIAqe-OWXEXe-uxVfW-FNFY6c-uFfGwd {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-XpnDCe.VfPpkd-RWgCYc-ksKsZd::after {
  transform: scale(1, 2);
  opacity: 1;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-TkwUic {
  height: 56px;
  display: flex;
  align-items: baseline;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-TkwUic::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc
  .VfPpkd-TkwUic
  .VfPpkd-uusGie-fmcmS::before {
  content: "&ZeroWidthSpace;";
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc
  .VfPpkd-TkwUic
  .VfPpkd-uusGie-fmcmS-haAclf {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc
  .VfPpkd-TkwUic::before {
  display: none;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-TkwUic {
  border-top-left-radius: 4px;
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-TkwUic {
  background-color: whitesmoke;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-TkwUic {
  background-color: #fafafa;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.VfPpkd-O1htCb-OWXEXe-MFS4be:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):hover
  .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.VfPpkd-O1htCb-OWXEXe-MFS4be:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-RWgCYc-ksKsZd::after {
  border-bottom-color: #6200ee;
  border-bottom-color: var(--mdc-theme-primary, #6200ee);
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-OWB6Me
  .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-NLUYnc-V67aGc {
  max-width: calc(100% - 64px);
}
.VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  max-width: calc(133.3333333333% - 85.3333333333px);
}
.VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-NLUYnc-V67aGc {
  left: 16px;
  right: auto;
}
[dir="rtl"] .VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-NLUYnc-V67aGc,
.VfPpkd-O1htCb-OWXEXe-MFS4be .VfPpkd-NLUYnc-V67aGc[dir="rtl"] {
  left: auto;
  right: 16px;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc {
  left: 48px;
  right: auto;
}
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc,
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc[dir="rtl"] {
  left: auto;
  right: 48px;
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc {
  max-width: calc(100% - 96px);
}
.VfPpkd-O1htCb-OWXEXe-MFS4be.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  max-width: calc(133.3333333333% - 128px);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: #b00020;
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):hover
  .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: #b00020;
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-RWgCYc-ksKsZd::after {
  border-bottom-color: #b00020;
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc {
  border: none;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic {
  display: flex;
  align-items: baseline;
  overflow: visible;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-uusGie-fmcmS-haAclf {
  display: flex;
  border: none;
  z-index: 1;
  background-color: transparent;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-cTi5dd {
  z-index: 2;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-xl07Ob-XxIAqe {
  margin-bottom: 8px;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc
  .VfPpkd-xl07Ob-XxIAqe,
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-xl07Ob-XxIAqe-OWXEXe-uxVfW-FNFY6c-uFfGwd {
  margin-bottom: 0;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic {
  height: 56px;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic .VfPpkd-NLUYnc-V67aGc-OWXEXe-bF1zU {
  animation: mdc-floating-label-shake-float-above-select-outlined-56px 0.25s 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-56px {
  0% {
    transform: translateX(0) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(4%) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-4%) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(0) translateY(-34.75px) scale(0.75);
  }
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb {
  border-top-left-radius: 4px;
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb[dir="rtl"] {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-left-radius: 0;
}
@supports (top: max(0%)) {
  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Ra9xwd {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-MpmGFe {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-left-radius: 0;
}
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe,
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe[dir="rtl"] {
  border-top-left-radius: 4px;
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
@supports (top: max(0%)) {
  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic {
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir="rtl"] .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic,
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic[dir="rtl"] {
  padding-left: 0;
}
@supports (top: max(0%)) {
  [dir="rtl"] .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic,
  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic[dir="rtl"] {
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .VfPpkd-O1htCb-OWXEXe-INsAgc + .VfPpkd-O1htCb-W0vJo-fmcmS {
    margin-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir="rtl"] .VfPpkd-O1htCb-OWXEXe-INsAgc + .VfPpkd-O1htCb-W0vJo-fmcmS,
.VfPpkd-O1htCb-OWXEXe-INsAgc + .VfPpkd-O1htCb-W0vJo-fmcmS[dir="rtl"] {
  margin-left: 0;
}
@supports (top: max(0%)) {
  [dir="rtl"] .VfPpkd-O1htCb-OWXEXe-INsAgc + .VfPpkd-O1htCb-W0vJo-fmcmS,
  .VfPpkd-O1htCb-OWXEXe-INsAgc + .VfPpkd-O1htCb-W0vJo-fmcmS[dir="rtl"] {
    margin-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-TkwUic {
  background-color: transparent;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-TkwUic {
  background-color: transparent;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgba(0, 0, 0, 0.87);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: #6200ee;
  border-color: var(--mdc-theme-primary, #6200ee);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgba(0, 0, 0, 0.06);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-width: 2px;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-TkwUic
  :not(.VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd)
  .VfPpkd-NSFCdd-Ra9xwd {
  max-width: calc(100% - 60px);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic .VfPpkd-NLUYnc-V67aGc-OWXEXe-bF1zU {
  animation: mdc-floating-label-shake-float-above-select-outlined 0.25s 1;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-TkwUic
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  transform: translateY(-37.25px) scale(1);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-TkwUic
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  font-size: 0.75rem;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-TkwUic.VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe,
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-TkwUic
  .VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  transform: translateY(-34.75px) scale(0.75);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-TkwUic.VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe,
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-TkwUic
  .VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  font-size: 1rem;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc
  .VfPpkd-TkwUic
  .VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd
  .VfPpkd-NSFCdd-Ra9xwd {
  padding-top: 1px;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic .VfPpkd-uusGie-fmcmS::before {
  content: "&ZeroWidthSpace;";
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic .VfPpkd-uusGie-fmcmS-haAclf {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic::before {
  display: none;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NLUYnc-V67aGc {
  line-height: 1.15rem;
  left: 4px;
  right: auto;
}
[dir="rtl"] .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NLUYnc-V67aGc,
.VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NLUYnc-V67aGc[dir="rtl"] {
  left: auto;
  right: 4px;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd
  .VfPpkd-NSFCdd-Ra9xwd {
  padding-top: 2px;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: #b00020;
  border-color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: #b00020;
  border-color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: #b00020;
  border-color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc {
  left: 36px;
  right: auto;
}
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc[dir="rtl"] {
  left: auto;
  right: 36px;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe[dir="rtl"] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  font-size: 0.75rem;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe,
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe[dir="rtl"],
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe[dir="rtl"] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c.VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  font-size: 1rem;
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-bF1zU {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px
    0.25s 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px {
  0% {
    transform: translateX(-32px) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(-32px) translateY(-34.75px) scale(0.75);
  }
}
[dir="rtl"]
  .VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-bF1zU,
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c[dir="rtl"]
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-bF1zU {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px
    0.25s 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px-rtl {
  0% {
    transform: translateX(32px) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% + 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% + 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(32px) translateY(-34.75px) scale(0.75);
  }
}
.VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-TkwUic
  :not(.VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd)
  .VfPpkd-NSFCdd-Ra9xwd {
  max-width: calc(100% - 96px);
}
.VfPpkd-TkwUic {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.VfPpkd-TkwUic .VfPpkd-woaZLe::before,
.VfPpkd-TkwUic .VfPpkd-woaZLe::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.VfPpkd-TkwUic .VfPpkd-woaZLe::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}
.VfPpkd-TkwUic .VfPpkd-woaZLe::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}
.VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d .VfPpkd-woaZLe::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d .VfPpkd-woaZLe::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd .VfPpkd-woaZLe::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
.VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc .VfPpkd-woaZLe::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
.VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf .VfPpkd-woaZLe::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-TkwUic .VfPpkd-woaZLe::before,
.VfPpkd-TkwUic .VfPpkd-woaZLe::after {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
.VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d .VfPpkd-woaZLe::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-TkwUic .VfPpkd-woaZLe::before,
.VfPpkd-TkwUic .VfPpkd-woaZLe::after {
  background-color: rgba(0, 0, 0, 0.87);
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.VfPpkd-TkwUic:hover .VfPpkd-woaZLe::before,
.VfPpkd-TkwUic.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-woaZLe::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-woaZLe::before,
.VfPpkd-TkwUic:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-woaZLe::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.VfPpkd-TkwUic .VfPpkd-woaZLe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before,
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-rymPhb-pZXsl::before,
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-YPmvEd
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-O1htCb-W0vJo-fmcmS {
  margin: 0;
  margin-left: 16px;
  margin-right: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-caption-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 0.75rem;
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-caption-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  line-height: normal;
}
[dir="rtl"] .VfPpkd-O1htCb-W0vJo-fmcmS,
.VfPpkd-O1htCb-W0vJo-fmcmS[dir="rtl"] {
  margin-left: 16px;
  margin-right: 16px;
}
.VfPpkd-O1htCb-W0vJo-fmcmS::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}
.VfPpkd-O1htCb-W0vJo-fmcmS-OWXEXe-Rfh2Tc-EglORb {
  opacity: 0;
  transition: opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc + .VfPpkd-O1htCb-W0vJo-fmcmS-OWXEXe-Rfh2Tc-EglORb,
.VfPpkd-O1htCb-W0vJo-fmcmS-OWXEXe-Rfh2Tc-EglORb-zvnfze {
  opacity: 1;
}
.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-cTi5dd {
  display: inline-block;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  align-self: center;
  background-color: transparent;
  fill: currentColor;
}
.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-cTi5dd {
  margin-left: 12px;
  margin-right: 12px;
}
[dir="rtl"] .VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-cTi5dd,
.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-cTi5dd[dir="rtl"] {
  margin-left: 12px;
  margin-right: 12px;
}
.VfPpkd-cTi5dd:not([tabindex]),
.VfPpkd-cTi5dd[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}
.VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-uusGie-fmcmS {
  color: rgba(0, 0, 0, 0.87);
}
.VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-uusGie-fmcmS {
  color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NLUYnc-V67aGc {
  color: rgba(0, 0, 0, 0.6);
}
.VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NLUYnc-V67aGc {
  color: rgba(98, 0, 238, 0.87);
}
.VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NLUYnc-V67aGc {
  color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-t08AT-Bz112c {
  fill: rgba(0, 0, 0, 0.54);
}
.VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-t08AT-Bz112c {
  fill: #6200ee;
  fill: var(--mdc-theme-primary, #6200ee);
}
.VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-t08AT-Bz112c {
  fill: rgba(0, 0, 0, 0.38);
}
.VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) + .VfPpkd-O1htCb-W0vJo-fmcmS {
  color: rgba(0, 0, 0, 0.6);
}
.VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me + .VfPpkd-O1htCb-W0vJo-fmcmS {
  color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-cTi5dd {
  color: rgba(0, 0, 0, 0.54);
}
.VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-cTi5dd {
  color: rgba(0, 0, 0, 0.38);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-uusGie-fmcmS {
    color: GrayText;
  }
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-t08AT-Bz112c {
    fill: red;
  }
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NLUYnc-V67aGc {
    color: GrayText;
  }
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-RWgCYc-ksKsZd::before {
    border-bottom-color: GrayText;
  }
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-Brv4Fb,
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-Ra9xwd,
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-MpmGFe {
    border-color: GrayText;
  }
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-cTi5dd,
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-OWB6Me + .VfPpkd-O1htCb-W0vJo-fmcmS {
    color: GrayText;
  }
}
.VfPpkd-O1htCb .VfPpkd-TkwUic {
  padding-left: 16px;
  padding-right: 0;
}
[dir="rtl"] .VfPpkd-O1htCb .VfPpkd-TkwUic,
.VfPpkd-O1htCb .VfPpkd-TkwUic[dir="rtl"] {
  padding-left: 0;
  padding-right: 16px;
}
.VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-TkwUic {
  padding-left: 0;
  padding-right: 0;
}
[dir="rtl"]
  .VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-TkwUic,
.VfPpkd-O1htCb.VfPpkd-O1htCb-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-TkwUic[dir="rtl"] {
  padding-left: 0;
  padding-right: 0;
}
.VfPpkd-O1htCb .VfPpkd-cTi5dd {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.VfPpkd-O1htCb .VfPpkd-t08AT-Bz112c {
  width: 24px;
  height: 24px;
}
.VfPpkd-t08AT-Bz112c {
  margin-left: 12px;
  margin-right: 12px;
}
[dir="rtl"] .VfPpkd-t08AT-Bz112c,
.VfPpkd-t08AT-Bz112c[dir="rtl"] {
  margin-left: 12px;
  margin-right: 12px;
}
.VfPpkd-TkwUic {
  width: 200px;
}
.VfPpkd-TkwUic .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  transform: translateY(-106%) scale(0.75);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NLUYnc-V67aGc {
  color: #b00020;
  color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NLUYnc-V67aGc {
  color: #b00020;
  color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-UJflGc
  + .VfPpkd-O1htCb-W0vJo-fmcmS-OWXEXe-Rfh2Tc-EglORb {
  color: #b00020;
  color: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-t08AT-Bz112c {
  fill: #b00020;
  fill: var(--mdc-theme-error, #b00020);
}
.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-t08AT-Bz112c {
  fill: #b00020;
  fill: var(--mdc-theme-error, #b00020);
}
.VfPpkd-uusGie-fmcmS-haAclf {
  height: 28px;
}
.s8kOBc {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.00625em;
  font-weight: 400;
}
.s8kOBc .VfPpkd-StrnGf-rymPhb {
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.00625em;
  font-weight: 400;
  color: rgb(60, 64, 67);
}
.s8kOBc .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-IhFlZd {
  color: rgb(95, 99, 104);
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-b9t22c,
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
  color: rgb(60, 64, 67);
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
  .VfPpkd-StrnGf-rymPhb-b9t22c {
  opacity: 0.38;
}
.s8kOBc .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.s8kOBc .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b,
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-f7MjDc,
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-pXU01b
  .VfPpkd-StrnGf-rymPhb-f7MjDc {
  color: rgb(60, 64, 67);
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0;
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd {
  background-color: rgb(232, 240, 254);
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--mdc-ripple-color, rgb(26, 115, 232));
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.s8kOBc
  .VfPpkd-StrnGf-rymPhb
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .s8kOBc
    .VfPpkd-StrnGf-rymPhb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c,
  .s8kOBc
    .VfPpkd-StrnGf-rymPhb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-fpDzbe-fmcmS,
  .s8kOBc
    .VfPpkd-StrnGf-rymPhb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
    color: GrayText;
  }
  .s8kOBc
    .VfPpkd-StrnGf-rymPhb
    .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    .VfPpkd-StrnGf-rymPhb-b9t22c {
    opacity: 1;
  }
}
.s8kOBc .VfPpkd-rymPhb-fpDzbe-fmcmS {
  color: rgb(60, 64, 67);
}
.s8kOBc .VfPpkd-rymPhb-L8ivfd-fmcmS,
.s8kOBc .VfPpkd-rymPhb-bC5pod-fmcmS,
.s8kOBc .VfPpkd-rymPhb-JMEf7e {
  color: rgb(95, 99, 104);
}
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c .VfPpkd-rymPhb-KkROqb,
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c .VfPpkd-rymPhb-JMEf7e,
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-fpDzbe-fmcmS,
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-L8ivfd-fmcmS,
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-bC5pod-fmcmS,
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb,
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c
  .VfPpkd-rymPhb-JMEf7e,
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
  .VfPpkd-rymPhb-JMEf7e {
  color: rgb(60, 64, 67);
}
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-KkROqb,
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-Gtdoyb,
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-JMEf7e {
  opacity: 0.38;
}
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-fpDzbe-fmcmS,
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b .VfPpkd-rymPhb-fpDzbe-fmcmS,
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb,
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-pXU01b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb {
  color: rgb(60, 64, 67);
}
.s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0;
}
.s8kOBc .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd {
  background-color: rgb(232, 240, 254);
}
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--mdc-ripple-color, rgb(26, 115, 232));
}
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.s8kOBc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
@media screen and (forced-colors: active) {
  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-fpDzbe-fmcmS,
  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-L8ivfd-fmcmS,
  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-bC5pod-fmcmS,
  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
    .VfPpkd-rymPhb-KkROqb,
  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c
    .VfPpkd-rymPhb-JMEf7e,
  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-r4m2rf
    .VfPpkd-rymPhb-JMEf7e {
    color: GrayText;
  }
  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-KkROqb,
  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-Gtdoyb,
  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me .VfPpkd-rymPhb-JMEf7e {
    opacity: 1;
  }
}
.s8kOBc .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-L8ivfd-fmcmS {
  color: rgb(95, 99, 104);
}
.s8kOBc .VfPpkd-StrnGf-rymPhb .VfPpkd-StrnGf-rymPhb-f7MjDc,
.s8kOBc
  .VfPpkd-rymPhb
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-M1Soyc-Bz112c
  .VfPpkd-rymPhb-KkROqb,
.s8kOBc
  .VfPpkd-rymPhb
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-UbuQg-Bz112c
  .VfPpkd-rymPhb-JMEf7e {
  color: rgb(60, 64, 67);
}
.s8kOBc .VfPpkd-rymPhb-fpDzbe-fmcmS {
  letter-spacing: 0.00625em;
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd {
  background-color: rgb(252, 232, 230);
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  background-color: rgb(217, 48, 37);
  background-color: var(--mdc-ripple-color, rgb(217, 48, 37));
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-StrnGf-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-StrnGf-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-StrnGf-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::after,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
  .VfPpkd-rymPhb-pZXsl::after {
  background-color: rgb(217, 48, 37);
  background-color: var(--mdc-ripple-color, rgb(217, 48, 37));
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-rymPhb-pZXsl::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-rymPhb-pZXsl::before,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-rymPhb-pZXsl::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-rymPhb-pZXsl::after {
  transition: opacity 0.15s linear;
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-rymPhb-pZXsl::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-StrnGf-rymPhb-ibnC6b.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d,
.s8kOBc.VfPpkd-YPmvEd-OWXEXe-UJflGc
  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-TkwUic {
  background-color: rgb(241, 243, 244);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-TkwUic {
  background-color: rgba(95, 99, 104, 0.04);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: rgb(95, 99, 104);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):hover .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: rgb(32, 33, 36);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-RWgCYc-ksKsZd::after {
  border-bottom-color: rgb(25, 103, 210);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: rgba(95, 99, 104, 0.38);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NLUYnc-V67aGc {
  color: rgb(95, 99, 104);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NLUYnc-V67aGc {
  color: rgb(25, 103, 210);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-NLUYnc-V67aGc {
  color: rgb(32, 33, 36);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NLUYnc-V67aGc {
  color: rgba(95, 99, 104, 0.38);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) + .VfPpkd-O1htCb-W0vJo-fmcmS {
  color: rgb(95, 99, 104);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-OWB6Me + .VfPpkd-O1htCb-W0vJo-fmcmS {
  color: rgba(95, 99, 104, 0.38);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-UJflGc
  + .VfPpkd-O1htCb-W0vJo-fmcmS-OWXEXe-Rfh2Tc-EglORb {
  color: rgb(197, 34, 31);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover.VfPpkd-O1htCb-OWXEXe-UJflGc
  + .VfPpkd-O1htCb-W0vJo-fmcmS-OWXEXe-Rfh2Tc-EglORb {
  color: rgb(165, 14, 14);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-uusGie-fmcmS {
  color: rgb(60, 64, 67);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-uusGie-fmcmS {
  color: rgba(60, 64, 67, 0.38);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-t08AT-Bz112c {
  fill: rgb(95, 99, 104);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(32, 33, 36);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(23, 78, 166);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-t08AT-Bz112c {
  fill: rgba(95, 99, 104, 0.38);
}
.hqBSCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-cTi5dd {
  color: rgb(95, 99, 104);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-cTi5dd {
  color: rgba(95, 99, 104, 0.38);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: rgb(197, 34, 31);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):hover
  .VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-color: rgb(165, 14, 14);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-RWgCYc-ksKsZd::after {
  border-bottom-color: rgb(197, 34, 31);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NLUYnc-V67aGc {
  color: rgb(197, 34, 31);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-NLUYnc-V67aGc {
  color: rgb(165, 14, 14);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  color: rgb(197, 34, 31);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  color: rgb(165, 14, 14);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(217, 48, 37);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(165, 14, 14);
}
.hqBSCb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(217, 48, 37);
}
.hqBSCb .VfPpkd-TkwUic .VfPpkd-woaZLe::before,
.hqBSCb .VfPpkd-TkwUic .VfPpkd-woaZLe::after {
  background-color: rgb(60, 64, 67);
  background-color: var(--mdc-ripple-color, rgb(60, 64, 67));
}
.hqBSCb .VfPpkd-TkwUic:hover .VfPpkd-woaZLe::before,
.hqBSCb
  .VfPpkd-TkwUic.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-woaZLe::before {
  opacity: 0.08;
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.hqBSCb
  .VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-woaZLe::before,
.hqBSCb .VfPpkd-TkwUic:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-woaZLe::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.hqBSCb .VfPpkd-TkwUic:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-woaZLe::after {
  transition: opacity 0.15s linear;
}
.hqBSCb .VfPpkd-TkwUic:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-woaZLe::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.hqBSCb .VfPpkd-TkwUic.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Brv4Fb,
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Ra9xwd,
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(128, 134, 139);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(32, 33, 36);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(26, 115, 232);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-Brv4Fb,
.ReCbLb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-Ra9xwd,
.ReCbLb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgba(60, 64, 67, 0.12);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NLUYnc-V67aGc {
  color: rgb(95, 99, 104);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NLUYnc-V67aGc {
  color: rgb(26, 115, 232);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-NLUYnc-V67aGc {
  color: rgb(32, 33, 36);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-NLUYnc-V67aGc {
  color: rgba(95, 99, 104, 0.38);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) + .VfPpkd-O1htCb-W0vJo-fmcmS {
  color: rgb(95, 99, 104);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-OWB6Me + .VfPpkd-O1htCb-W0vJo-fmcmS {
  color: rgba(95, 99, 104, 0.38);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-UJflGc
  + .VfPpkd-O1htCb-W0vJo-fmcmS-OWXEXe-Rfh2Tc-EglORb {
  color: rgb(217, 48, 37);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover.VfPpkd-O1htCb-OWXEXe-UJflGc
  + .VfPpkd-O1htCb-W0vJo-fmcmS-OWXEXe-Rfh2Tc-EglORb {
  color: rgb(165, 14, 14);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-uusGie-fmcmS {
  color: rgb(60, 64, 67);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-uusGie-fmcmS {
  color: rgba(60, 64, 67, 0.38);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-t08AT-Bz112c {
  fill: rgb(95, 99, 104);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(32, 33, 36);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(26, 115, 232);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-t08AT-Bz112c {
  fill: rgba(95, 99, 104, 0.38);
}
.ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-cTi5dd {
  color: rgb(95, 99, 104);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-OWB6Me .VfPpkd-cTi5dd {
  color: rgba(95, 99, 104, 0.38);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-Brv4Fb,
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-Ra9xwd,
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(217, 48, 37);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
  .VfPpkd-TkwUic:hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(165, 14, 14);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb,
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Ra9xwd,
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(217, 48, 37);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NLUYnc-V67aGc {
  color: rgb(217, 48, 37);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-NLUYnc-V67aGc {
  color: rgb(165, 14, 14);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  color: rgb(217, 48, 37);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  color: rgb(165, 14, 14);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(197, 34, 31);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe):hover
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(165, 14, 14);
}
.ReCbLb.VfPpkd-O1htCb-OWXEXe-UJflGc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-t08AT-Bz112c {
  fill: rgb(197, 34, 31);
}
.VfPpkd-I9GLp-yrriRe {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.VfPpkd-I9GLp-yrriRe[hidden] {
  display: none;
}
.VfPpkd-I9GLp-yrriRe > label {
  margin-left: 0;
  margin-right: auto;
  padding-left: 4px;
  padding-right: 0;
  order: 0;
}
[dir="rtl"] .VfPpkd-I9GLp-yrriRe > label,
.VfPpkd-I9GLp-yrriRe > label[dir="rtl"] {
  margin-left: auto;
  margin-right: 0;
}
[dir="rtl"] .VfPpkd-I9GLp-yrriRe > label,
.VfPpkd-I9GLp-yrriRe > label[dir="rtl"] {
  padding-left: 0;
  padding-right: 4px;
}
.VfPpkd-I9GLp-yrriRe-OWXEXe-WrakWd > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.VfPpkd-I9GLp-yrriRe-OWXEXe-fW01td-CpWD9d > label {
  margin-left: auto;
  margin-right: 0;
  padding-left: 0;
  padding-right: 4px;
  order: -1;
}
[dir="rtl"] .VfPpkd-I9GLp-yrriRe-OWXEXe-fW01td-CpWD9d > label,
.VfPpkd-I9GLp-yrriRe-OWXEXe-fW01td-CpWD9d > label[dir="rtl"] {
  margin-left: 0;
  margin-right: auto;
}
[dir="rtl"] .VfPpkd-I9GLp-yrriRe-OWXEXe-fW01td-CpWD9d > label,
.VfPpkd-I9GLp-yrriRe-OWXEXe-fW01td-CpWD9d > label[dir="rtl"] {
  padding-left: 4px;
  padding-right: 0;
}
.VfPpkd-I9GLp-yrriRe-OWXEXe-fozPsf-t6UvL {
  justify-content: space-between;
}
.VfPpkd-I9GLp-yrriRe-OWXEXe-fozPsf-t6UvL > label {
  margin: 0;
}
[dir="rtl"] .VfPpkd-I9GLp-yrriRe-OWXEXe-fozPsf-t6UvL > label,
.VfPpkd-I9GLp-yrriRe-OWXEXe-fozPsf-t6UvL > label[dir="rtl"] {
  margin: 0;
}
.VfPpkd-I9GLp-yrriRe {
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-form-field-label-text-font, Roboto, sans-serif);
  line-height: 1.25rem;
  line-height: var(--mdc-form-field-label-text-line-height, 1.25rem);
  font-size: 0.875rem;
  font-size: var(--mdc-form-field-label-text-size, 0.875rem);
  font-weight: 400;
  font-weight: var(--mdc-form-field-label-text-weight, 400);
  letter-spacing: 0.0178571429em;
  letter-spacing: var(--mdc-form-field-label-text-tracking, 0.0178571429em);
  color: rgba(0, 0, 0, 0.87);
  color: var(
    --mdc-form-field-label-text-color,
    var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87))
  );
}
.MlG5Jc {
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.25rem;
  font-size: 0.875rem;
  letter-spacing: 0.0142857143em;
  font-weight: 400;
}
.MlG5Jc gm-checkbox[disabled] ~ .VfPpkd-V67aGc,
.MlG5Jc gm-radio[disabled] ~ .VfPpkd-V67aGc,
.MlG5Jc .VfPpkd-MPu53c-OWXEXe-OWB6Me ~ .VfPpkd-V67aGc,
.MlG5Jc .VfPpkd-GCYh9b-OWXEXe-OWB6Me ~ .VfPpkd-V67aGc {
  color: rgb(95, 99, 104);
}
.VfPpkd-GCYh9b {
  padding: 10px;
}
.VfPpkd-GCYh9b
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgba(0, 0, 0, 0.54);
}
.VfPpkd-GCYh9b
  .VfPpkd-gBXA9-bMcfAe:enabled:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: #018786;
  border-color: var(--mdc-theme-secondary, #018786);
}
.VfPpkd-GCYh9b
  .VfPpkd-gBXA9-bMcfAe:enabled
  + .VfPpkd-RsCWK
  .VfPpkd-Z5TpLc-LkdAo {
  border-color: #018786;
  border-color: var(--mdc-theme-secondary, #018786);
}
.VfPpkd-GCYh9b
  [aria-disabled="true"]
  .VfPpkd-gBXA9-bMcfAe:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.VfPpkd-GCYh9b
  .VfPpkd-gBXA9-bMcfAe:disabled:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-GCYh9b
  [aria-disabled="true"]
  .VfPpkd-gBXA9-bMcfAe:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.VfPpkd-GCYh9b
  .VfPpkd-gBXA9-bMcfAe:disabled:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-GCYh9b
  [aria-disabled="true"]
  .VfPpkd-gBXA9-bMcfAe
  + .VfPpkd-RsCWK
  .VfPpkd-Z5TpLc-LkdAo,
.VfPpkd-GCYh9b
  .VfPpkd-gBXA9-bMcfAe:disabled
  + .VfPpkd-RsCWK
  .VfPpkd-Z5TpLc-LkdAo {
  border-color: rgba(0, 0, 0, 0.38);
}
.VfPpkd-GCYh9b .VfPpkd-RsCWK::before {
  background-color: #018786;
  background-color: var(--mdc-theme-secondary, #018786);
}
.VfPpkd-GCYh9b .VfPpkd-RsCWK::before {
  top: -10px;
  left: -10px;
  width: 40px;
  height: 40px;
}
.VfPpkd-GCYh9b .VfPpkd-gBXA9-bMcfAe {
  top: 0;
  right: 0;
  left: 0;
  width: 40px;
  height: 40px;
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .VfPpkd-GCYh9b.VfPpkd-GCYh9b-OWXEXe-OWB6Me
    [aria-disabled="true"]
    .VfPpkd-gBXA9-bMcfAe:not(:checked)
    + .VfPpkd-RsCWK
    .VfPpkd-wVo5xe-LkdAo,
  .VfPpkd-GCYh9b.VfPpkd-GCYh9b-OWXEXe-OWB6Me
    .VfPpkd-gBXA9-bMcfAe:disabled:not(:checked)
    + .VfPpkd-RsCWK
    .VfPpkd-wVo5xe-LkdAo {
    border-color: GrayText;
  }
  .VfPpkd-GCYh9b.VfPpkd-GCYh9b-OWXEXe-OWB6Me
    [aria-disabled="true"]
    .VfPpkd-gBXA9-bMcfAe:checked
    + .VfPpkd-RsCWK
    .VfPpkd-wVo5xe-LkdAo,
  .VfPpkd-GCYh9b.VfPpkd-GCYh9b-OWXEXe-OWB6Me
    .VfPpkd-gBXA9-bMcfAe:disabled:checked
    + .VfPpkd-RsCWK
    .VfPpkd-wVo5xe-LkdAo {
    border-color: GrayText;
  }
  .VfPpkd-GCYh9b.VfPpkd-GCYh9b-OWXEXe-OWB6Me
    [aria-disabled="true"]
    .VfPpkd-gBXA9-bMcfAe
    + .VfPpkd-RsCWK
    .VfPpkd-Z5TpLc-LkdAo,
  .VfPpkd-GCYh9b.VfPpkd-GCYh9b-OWXEXe-OWB6Me
    .VfPpkd-gBXA9-bMcfAe:disabled
    + .VfPpkd-RsCWK
    .VfPpkd-Z5TpLc-LkdAo {
    border-color: GrayText;
  }
}
.VfPpkd-GCYh9b {
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  cursor: pointer;
  will-change: opacity, transform, border-color, color;
}
.VfPpkd-GCYh9b[hidden] {
  display: none;
}
.VfPpkd-RsCWK {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}
.VfPpkd-RsCWK::before {
  position: absolute;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  transition: opacity 0.12s 0ms cubic-bezier(0.4, 0, 0.6, 1),
    transform 0.12s 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.VfPpkd-wVo5xe-LkdAo {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  transition: border-color 0.12s 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.VfPpkd-Z5TpLc-LkdAo {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-width: 10px;
  border-style: solid;
  border-radius: 50%;
  transition: transform 0.12s 0ms cubic-bezier(0.4, 0, 0.6, 1),
    border-color 0.12s 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.VfPpkd-gBXA9-bMcfAe {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
  z-index: 1;
}
.VfPpkd-GCYh9b-OWXEXe-dgl2Hf {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.VfPpkd-GCYh9b-OWXEXe-dgl2Hf .VfPpkd-gBXA9-bMcfAe {
  top: -4px;
  right: -4px;
  left: -4px;
  width: 48px;
  height: 48px;
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-r6xRoe-LhBDec,
.VfPpkd-GCYh9b:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-r6xRoe-LhBDec {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
@media screen and (forced-colors: active) {
  .VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
    .VfPpkd-r6xRoe-LhBDec,
  .VfPpkd-GCYh9b:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-r6xRoe-LhBDec {
    border-color: CanvasText;
  }
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-r6xRoe-LhBDec::after,
.VfPpkd-GCYh9b:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-r6xRoe-LhBDec::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
    .VfPpkd-r6xRoe-LhBDec::after,
  .VfPpkd-GCYh9b:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-r6xRoe-LhBDec::after {
    border-color: CanvasText;
  }
}
.VfPpkd-gBXA9-bMcfAe:checked + .VfPpkd-RsCWK,
.VfPpkd-gBXA9-bMcfAe:disabled + .VfPpkd-RsCWK {
  transition: opacity 0.12s 0ms cubic-bezier(0, 0, 0.2, 1),
    transform 0.12s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-gBXA9-bMcfAe:checked + .VfPpkd-RsCWK .VfPpkd-wVo5xe-LkdAo,
.VfPpkd-gBXA9-bMcfAe:disabled + .VfPpkd-RsCWK .VfPpkd-wVo5xe-LkdAo {
  transition: border-color 0.12s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-gBXA9-bMcfAe:checked + .VfPpkd-RsCWK .VfPpkd-Z5TpLc-LkdAo,
.VfPpkd-gBXA9-bMcfAe:disabled + .VfPpkd-RsCWK .VfPpkd-Z5TpLc-LkdAo {
  transition: transform 0.12s 0ms cubic-bezier(0, 0, 0.2, 1),
    border-color 0.12s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-GCYh9b-OWXEXe-OWB6Me {
  cursor: default;
  pointer-events: none;
}
.VfPpkd-gBXA9-bMcfAe:checked + .VfPpkd-RsCWK .VfPpkd-Z5TpLc-LkdAo {
  transform: scale(0.5);
  transition: transform 0.12s 0ms cubic-bezier(0, 0, 0.2, 1),
    border-color 0.12s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-gBXA9-bMcfAe:disabled + .VfPpkd-RsCWK,
[aria-disabled="true"] .VfPpkd-gBXA9-bMcfAe + .VfPpkd-RsCWK {
  cursor: default;
}
.VfPpkd-gBXA9-bMcfAe:focus + .VfPpkd-RsCWK::before {
  transform: scale(1);
  opacity: 0.12;
  transition: opacity 0.12s 0ms cubic-bezier(0, 0, 0.2, 1),
    transform 0.12s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-GCYh9b {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.VfPpkd-GCYh9b .VfPpkd-eHTEvd::before,
.VfPpkd-GCYh9b .VfPpkd-eHTEvd::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.VfPpkd-GCYh9b .VfPpkd-eHTEvd::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}
.VfPpkd-GCYh9b .VfPpkd-eHTEvd::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d .VfPpkd-eHTEvd::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d .VfPpkd-eHTEvd::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd .VfPpkd-eHTEvd::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc .VfPpkd-eHTEvd::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf .VfPpkd-eHTEvd::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-GCYh9b .VfPpkd-eHTEvd::before,
.VfPpkd-GCYh9b .VfPpkd-eHTEvd::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d .VfPpkd-eHTEvd::before,
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d .VfPpkd-eHTEvd::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d .VfPpkd-eHTEvd::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-GCYh9b .VfPpkd-eHTEvd::before,
.VfPpkd-GCYh9b .VfPpkd-eHTEvd::after {
  background-color: #018786;
  background-color: var(
    --mdc-ripple-color,
    var(--mdc-theme-secondary, #018786)
  );
}
.VfPpkd-GCYh9b:hover .VfPpkd-eHTEvd::before,
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-eHTEvd::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-eHTEvd::before,
.VfPpkd-GCYh9b:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-eHTEvd::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.VfPpkd-GCYh9b:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-eHTEvd::after {
  transition: opacity 0.15s linear;
}
.VfPpkd-GCYh9b:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-eHTEvd::after {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d .VfPpkd-RsCWK::before,
.VfPpkd-GCYh9b.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-RsCWK::before {
  content: none;
}
.VfPpkd-eHTEvd {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.kDzhGf {
  z-index: 0;
}
.kDzhGf .VfPpkd-eHTEvd::before,
.kDzhGf .VfPpkd-eHTEvd::after {
  z-index: -1;
}
.kDzhGf .VfPpkd-eHTEvd::before,
.kDzhGf .VfPpkd-eHTEvd::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--gm-radio-state-color, rgb(26, 115, 232));
}
.kDzhGf:hover .VfPpkd-eHTEvd::before,
.kDzhGf.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-eHTEvd::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.kDzhGf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-eHTEvd::before,
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-eHTEvd::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-eHTEvd::after {
  transition: opacity 0.15s linear;
}
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-eHTEvd::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.kDzhGf .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked) ~ .VfPpkd-eHTEvd::before,
.kDzhGf .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked) ~ .VfPpkd-eHTEvd::after {
  background-color: rgb(60, 64, 67);
  background-color: var(--gm-radio-state-color, rgb(60, 64, 67));
}
.kDzhGf:hover
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  ~ .VfPpkd-eHTEvd::before,
.kDzhGf.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  ~ .VfPpkd-eHTEvd::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.kDzhGf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  ~ .VfPpkd-eHTEvd::before,
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  ~ .VfPpkd-eHTEvd::before {
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  ~ .VfPpkd-eHTEvd::after {
  transition: opacity 0.15s linear;
}
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  ~ .VfPpkd-eHTEvd::after {
  transition-duration: 75ms;
  opacity: 0.1;
  opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.kDzhGf.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.1);
}
.kDzhGf
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgb(95, 99, 104);
  border-color: var(--gm-radio-stroke-color--unchecked, rgb(95, 99, 104));
}
.kDzhGf
  .VfPpkd-gBXA9-bMcfAe:enabled:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgb(26, 115, 232);
  border-color: var(--gm-radio-stroke-color--checked, rgb(26, 115, 232));
}
.kDzhGf .VfPpkd-gBXA9-bMcfAe:enabled + .VfPpkd-RsCWK .VfPpkd-Z5TpLc-LkdAo {
  border-color: rgb(26, 115, 232);
  border-color: var(--gm-radio-ink-color, rgb(26, 115, 232));
}
.kDzhGf
  [aria-disabled="true"]
  .VfPpkd-gBXA9-bMcfAe:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.kDzhGf
  .VfPpkd-gBXA9-bMcfAe:disabled:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgba(60, 64, 67, 0.38);
  border-color: var(
    --gm-radio-disabled-stroke-color--unchecked,
    rgba(60, 64, 67, 0.38)
  );
}
.kDzhGf
  [aria-disabled="true"]
  .VfPpkd-gBXA9-bMcfAe:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.kDzhGf
  .VfPpkd-gBXA9-bMcfAe:disabled:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgba(60, 64, 67, 0.38);
  border-color: var(
    --gm-radio-disabled-stroke-color--checked,
    rgba(60, 64, 67, 0.38)
  );
}
.kDzhGf
  [aria-disabled="true"]
  .VfPpkd-gBXA9-bMcfAe
  + .VfPpkd-RsCWK
  .VfPpkd-Z5TpLc-LkdAo,
.kDzhGf .VfPpkd-gBXA9-bMcfAe:disabled + .VfPpkd-RsCWK .VfPpkd-Z5TpLc-LkdAo {
  border-color: rgba(60, 64, 67, 0.38);
  border-color: var(--gm-radio-disabled-ink-color, rgba(60, 64, 67, 0.38));
}
.kDzhGf .VfPpkd-RsCWK::before {
  background-color: rgb(26, 115, 232);
  background-color: var(--gm-radio-state-color, rgb(26, 115, 232));
}
.kDzhGf:hover
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.kDzhGf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.kDzhGf:active
  .VfPpkd-gBXA9-bMcfAe:enabled:not(:checked)
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgb(32, 33, 36);
  border-color: var(
    --gm-radio-stroke-color--unchecked-stateful,
    rgb(32, 33, 36)
  );
}
.kDzhGf:hover
  .VfPpkd-gBXA9-bMcfAe:enabled:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.kDzhGf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-gBXA9-bMcfAe:enabled:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-gBXA9-bMcfAe:enabled:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo,
.kDzhGf:active
  .VfPpkd-gBXA9-bMcfAe:enabled:checked
  + .VfPpkd-RsCWK
  .VfPpkd-wVo5xe-LkdAo {
  border-color: rgb(23, 78, 166);
  border-color: var(
    --gm-radio-stroke-color--checked-stateful,
    rgb(23, 78, 166)
  );
}
.kDzhGf:hover .VfPpkd-gBXA9-bMcfAe:enabled + .VfPpkd-RsCWK .VfPpkd-Z5TpLc-LkdAo,
.kDzhGf.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-gBXA9-bMcfAe:enabled
  + .VfPpkd-RsCWK
  .VfPpkd-Z5TpLc-LkdAo,
.kDzhGf:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-gBXA9-bMcfAe:enabled
  + .VfPpkd-RsCWK
  .VfPpkd-Z5TpLc-LkdAo,
.kDzhGf:active
  .VfPpkd-gBXA9-bMcfAe:enabled
  + .VfPpkd-RsCWK
  .VfPpkd-Z5TpLc-LkdAo {
  border-color: rgb(23, 78, 166);
  border-color: var(--gm-radio-ink-color--stateful, rgb(23, 78, 166));
}
.wHsUjf {
  will-change: unset;
}
.VfPpkd-NLUYnc-V67aGc {
  position: absolute;
  left: 0;
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform;
}
[dir="rtl"] .VfPpkd-NLUYnc-V67aGc,
.VfPpkd-NLUYnc-V67aGc[dir="rtl"] {
  right: 0;
  left: auto;
  transform-origin: right top;
  text-align: right;
}
.VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  cursor: auto;
}
.VfPpkd-NLUYnc-V67aGc-OWXEXe-ztc6md:not(.VfPpkd-NLUYnc-V67aGc-OWXEXe-ZYIfFd-ztc6md-vXpfLb)::after {
  margin-left: 1px;
  margin-right: 0;
  content: "*";
}
[dir="rtl"]
  .VfPpkd-NLUYnc-V67aGc-OWXEXe-ztc6md:not(.VfPpkd-NLUYnc-V67aGc-OWXEXe-ZYIfFd-ztc6md-vXpfLb)::after,
.VfPpkd-NLUYnc-V67aGc-OWXEXe-ztc6md:not(.VfPpkd-NLUYnc-V67aGc-OWXEXe-ZYIfFd-ztc6md-vXpfLb)[dir="rtl"]::after {
  margin-left: 0;
  margin-right: 1px;
}
.VfPpkd-NLUYnc-V67aGc {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle1-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
.VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  transform: translateY(-106%) scale(0.75);
}
.VfPpkd-NLUYnc-V67aGc-OWXEXe-bF1zU {
  animation: mdc-floating-label-shake-float-above-standard 0.25s 1;
}
@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(0) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(4%) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-4%) translateY(-106%) scale(0.75);
  }
  100% {
    transform: translateX(0) translateY(-106%) scale(0.75);
  }
}
.VfPpkd-RWgCYc-ksKsZd::before,
.VfPpkd-RWgCYc-ksKsZd::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}
.VfPpkd-RWgCYc-ksKsZd::before {
  z-index: 1;
}
.VfPpkd-RWgCYc-ksKsZd::after {
  transform: scaleX(0);
  opacity: 0;
  z-index: 2;
}
.VfPpkd-RWgCYc-ksKsZd-OWXEXe-auswjd::after {
  transform: scaleX(1);
  opacity: 1;
}
.VfPpkd-RWgCYc-ksKsZd-OWXEXe-JD038d::after {
  opacity: 0;
}
.VfPpkd-RWgCYc-ksKsZd::before {
  border-bottom-width: 1px;
}
.VfPpkd-RWgCYc-ksKsZd::after {
  border-bottom-width: 2px;
}
.VfPpkd-RWgCYc-ksKsZd::after {
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1);
}
.VfPpkd-NSFCdd-i5vt6e {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
}
[dir="rtl"] .VfPpkd-NSFCdd-i5vt6e,
.VfPpkd-NSFCdd-i5vt6e[dir="rtl"] {
  text-align: right;
}
.VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-NSFCdd-MpmGFe {
  box-sizing: border-box;
  height: 100%;
  pointer-events: none;
}
.VfPpkd-NSFCdd-MpmGFe {
  flex-grow: 1;
}
.VfPpkd-NSFCdd-Ra9xwd {
  flex: 0 0 auto;
  width: auto;
}
.VfPpkd-NSFCdd-i5vt6e .VfPpkd-NLUYnc-V67aGc {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.VfPpkd-NSFCdd-i5vt6e .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  text-overflow: clip;
}
.VfPpkd-NSFCdd-i5vt6e-OWXEXe-mWPk3d .VfPpkd-NLUYnc-V67aGc-OWXEXe-TATcMc-KLRBe {
  max-width: 133.3333333333%;
}
.VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd .VfPpkd-NSFCdd-Ra9xwd {
  padding-left: 0;
  padding-right: 8px;
  border-top: none;
}
[dir="rtl"] .VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd .VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd .VfPpkd-NSFCdd-Ra9xwd[dir="rtl"] {
  padding-left: 8px;
  padding-right: 0;
}
.VfPpkd-NSFCdd-i5vt6e-OWXEXe-di8rgd-V67aGc .VfPpkd-NSFCdd-Ra9xwd {
  display: none;
}
.VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-NSFCdd-Ra9xwd,
.VfPpkd-NSFCdd-MpmGFe {
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.VfPpkd-NSFCdd-Brv4Fb {
  border-left: 1px solid;
  border-right: none;
  width: 12px;
}
[dir="rtl"] .VfPpkd-NSFCdd-Brv4Fb,
.VfPpkd-NSFCdd-Brv4Fb[dir="rtl"] {
  border-left: none;
  border-right: 1px solid;
}
.VfPpkd-NSFCdd-MpmGFe {
  border-left: none;
  border-right: 1px solid;
}
[dir="rtl"] .VfPpkd-NSFCdd-MpmGFe,
.VfPpkd-NSFCdd-MpmGFe[dir="rtl"] {
  border-left: 1px solid;
  border-right: none;
}
.VfPpkd-NSFCdd-Ra9xwd {
  max-width: calc(100% - 24px);
}
html {
  -webkit-tap-highlight-color: transparent;
}
body,
input,
textarea,
select,
button {
  color: #202124;
  font-family: roboto, "Noto Sans Myanmar UI", arial, sans-serif;
}
body {
  background: #fff;
  direction: ltr;
  font-size: 14px;
  line-height: 1.4286;
  margin: 0;
  padding: 0;
}
[data-style="heading"],
h2:not(.z4F7Yc) {
  color: #202124;
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 1.5;
}
.g98c7c a:not(.TrZEUc):not(.VfPpkd-mRLv6) {
  margin: 0 -3px;
  padding: 0 3px;
  text-decoration: none;
  border-radius: 4px;
  color: #1a73e8;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 0.25px;
  outline: 0;
  position: relative;
}
.g98c7c button:not(.TrZEUc) {
  border-radius: 4px;
  color: #1a73e8;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 0.25px;
  outline: 0;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  font-size: inherit;
  text-align: left;
  margin: -2px -3px;
  padding: 2px 3px;
  border: 0;
}
.g98c7c button::-moz-focus-inner {
  border: 0;
}
.g98c7c a:not(.TrZEUc):not(.VfPpkd-mRLv6):focus,
.g98c7c button:not(.TrZEUc):focus {
  background-color: rgba(26, 115, 232, 0.149);
  border-radius: 2px;
  color: #174ea6;
}
.g98c7c a:not(.TrZEUc):not(.VfPpkd-mRLv6):active,
.g98c7c button:not(.TrZEUc):active {
  color: #174ea6;
}
.g98c7c a:not(.TrZEUc):not(.VfPpkd-mRLv6):active::after,
.g98c7c button:not(.TrZEUc):active::after {
  background-color: rgba(26, 115, 232, 0.251);
}
.g98c7c img:not(.TrZEUc) {
  border: 0;
  max-height: 1.3em;
  vertical-align: middle;
}
.AORPd a:not(.TrZEUc):not(.VfPpkd-mRLv6),
.AORPd button:not(.TrZEUc) {
  color: #1a73e8;
}
.lJXwje,
.lJXwje:before,
.lJXwje:after {
  box-sizing: border-box;
}
.lJXwje *,
.lJXwje *:before,
.lJXwje *:after {
  box-sizing: inherit;
}
@media all and (min-width: 601px) {
  .lJXwje {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  }
  .lJXwje:before,
  .lJXwje:after {
    box-flex: 1;
    flex-grow: 1;
    content: "";
    display: block;
    height: 24px;
  }
  .lJXwje:before {
    min-height: 30px;
  }
  .lJXwje:after {
    min-height: 24px;
  }
  .lJXwje.LZgQXe:after {
    min-height: 63.9999996px;
  }
}
.lJXwje .rn19nc,
.lJXwje .t3Tdm {
  position: fixed;
}
@media all and (min-width: 601px) {
  .lJXwje .rn19nc,
  .lJXwje .t3Tdm {
    position: absolute;
  }
}
.lJXwje a:not(.TrZEUc):not(.VfPpkd-mRLv6):focus-visible,
.lJXwje button:not(.TrZEUc):focus-visible,
.g98c7c a:not(.TrZEUc):not(.VfPpkd-mRLv6):focus-visible,
.g98c7c button:not(.TrZEUc):focus-visible {
  outline: none;
  position: relative;
}
.lJXwje a:not(.TrZEUc):not(.VfPpkd-mRLv6):focus-visible::after,
.lJXwje button:not(.TrZEUc):focus-visible::after,
.g98c7c a:not(.TrZEUc):not(.VfPpkd-mRLv6):focus-visible::after,
.g98c7c button:not(.TrZEUc):focus-visible::after {
  border: 2px solid #185abc;
  border-radius: 6px;
  bottom: -4px;
  box-shadow: 0 0 0 2px #e8f0fe;
  content: "";
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
}
.lJXwje a:not(.TrZEUc):not(.VfPpkd-mRLv6):focus:not(:focus-visible) {
  box-shadow: 0 1px 1px 0 rgba(66, 133, 244, 0.3),
    0 1px 3px 1px rgba(66, 133, 244, 0.15);
}
.lJXwje a:not(.TrZEUc):not(.VfPpkd-mRLv6):hover:not(:focus-visible) {
  box-shadow: 0 1px 1px 0 rgba(66, 133, 244, 0.45),
    0 1px 3px 1px rgba(66, 133, 244, 0.3);
}
.g98c7c a:not(.TrZEUc):not(.VfPpkd-mRLv6):focus:not(:focus-visible) {
  box-shadow: 0 1px 1px 0 rgba(66, 133, 244, 0.3),
    0 1px 3px 1px rgba(66, 133, 244, 0.15);
}
.g98c7c a:not(.TrZEUc):not(.VfPpkd-mRLv6):hover:not(:focus-visible) {
  box-shadow: 0 1px 1px 0 rgba(66, 133, 244, 0.45),
    0 1px 3px 1px rgba(66, 133, 244, 0.3);
}
.iNstf {
  background: #fff;
  bottom: 0;
  direction: ltr;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
@media all and (min-width: 601px) {
  .iNstf {
    background: #fff;
  }
}
.fPxNlf {
  background: #fff;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  z-index: 2;
}
.fPxNlf.LZgQXe {
  min-height: 100vh;
}
@media all and (min-width: 601px) {
  .fPxNlf.LZgQXe {
    min-height: 0;
  }
  .fPxNlf,
  .rhhJr {
    transition: 0.2s;
  }
  .fPxNlf {
    background: #fff;
    border: 1px solid #dadce0;
    border-radius: 8px;
    display: block;
    flex-shrink: 0;
    margin: 0 auto;
    min-height: 0;
    width: 450px;
  }
  .fPxNlf.qmmlRd {
    width: 450px;
  }
  .fPxNlf.qmmlRd .rhhJr {
    height: auto;
    min-height: 500px;
  }
}
.imil7d {
  display: none;
}
@media all and (min-width: 901px) {
  .imil7d {
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    padding-left: 48px;
    width: 300px;
  }
  .fPxNlf.RELBvb {
    width: 750px;
  }
  .fPxNlf.RELBvb .rhhJr {
    display: flex;
  }
  .fPxNlf.RELBvb .j663ec {
    flex-basis: 450px;
    margin: 0 -48px;
    overflow: hidden;
    padding: 0 48px;
  }
  .fPxNlf.RELBvb .zOO9Bf {
    justify-content: flex-start;
  }
}
@media all and (min-width: 601px) and (orientation: landscape) {
  .fPxNlf.v7usYb:not(.RELBvb) {
    width: 450px;
  }
  .fPxNlf.v7usYb .rhhJr {
    height: auto;
    min-height: 500px;
  }
}
.rhhJr {
  box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  padding: 24px 24px 36px;
}
@media all and (min-width: 450px) {
  .rhhJr {
    padding: 48px 40px 36px;
  }
}
@media all and (min-width: 601px) {
  .rhhJr {
    height: auto;
    min-height: 500px;
    overflow-y: auto;
  }
}
.rhhJr.kxzXfb {
  overflow: visible;
  position: relative;
  z-index: 1;
}
.aDGQwe {
  margin: auto -24px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
}
@media all and (min-width: 450px) {
  .aDGQwe {
    margin: auto -40px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
.rhhJr.kxzXfb .aDGQwe {
  overflow: visible;
}
.eKnrVb {
  margin: auto -24px;
  font-size: 0.1px;
  white-space: nowrap;
}
@media all and (min-width: 450px) {
  .eKnrVb {
    margin: auto -40px;
  }
}
.CYBold {
  border: 0 solid transparent;
  border-width: 0 24px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  padding: 24px 0 0;
  vertical-align: top;
  white-space: normal;
  width: 100%;
}
@media (forced-colors: active) {
  .CYBold {
    forced-color-adjust: none;
  }
  .j663ec,
  .Z6Ep7d {
    forced-color-adjust: auto;
  }
}
@media all and (min-width: 450px) {
  .CYBold {
    border-left-width: 40px;
    border-right-width: 40px;
  }
}
.rhhJr.kxzXfb .eKnrVb {
  transform: none;
}
.tCpFMc.RDPZE {
  opacity: 0.5;
  pointer-events: none;
}
.eKnrVb.hAfgic {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.eKnrVb.QsjdCb {
  transform: translate3d(0, 0, 0);
}
.eKnrVb.GEcl0c {
  transform: translate3d(-100%, 0, 0);
}
[dir="rtl"] .eKnrVb.GEcl0c {
  transform: translate3d(100%, 0, 0);
}
.Y01Ccd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.g9mjtf {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  justify-content: space-between;
  line-height: 1.3333333;
  padding: 0 24px;
}
@media all and (min-width: 450px) {
  .g9mjtf {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (min-width: 601px) {
  .g9mjtf {
    padding: 8px 0 0;
    position: absolute;
    width: 100%;
  }
}
.V7i3mf {
  margin-right: 16px;
}
.V7i3mf .VfPpkd-t08AT-Bz112c {
  margin: 0;
}
.V7i3mf .VfPpkd-t08AT-Bz112c-Bd00G {
  fill: currentColor;
}
.V7i3mf .ReCbLb .VfPpkd-NSFCdd-Brv4Fb,
.V7i3mf .ReCbLb .VfPpkd-NSFCdd-MpmGFe {
  border: none;
}
.V7i3mf .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic {
  border-radius: 4px;
  height: 48px;
}
.V7i3mf .VfPpkd-O1htCb {
  margin: 0 0 0 -16px;
}
.V7i3mf .r6B9Fd .VfPpkd-rymPhb-fpDzbe-fmcmS,
.V7i3mf .ReCbLb .VfPpkd-uusGie-fmcmS {
  font-family: roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 12px;
}
.V7i3mf .VfPpkd-TkwUic:focus {
  background-color: #eee;
}
.kJOS4 {
  display: flex;
  list-style: none;
  margin: 0 -16px;
  padding: 0;
}
.Z3Coxe.fVfPj .kJOS4 {
  padding-bottom: 24px;
}
.r7XTUb {
  align-items: flex-start;
  display: flex;
  margin: 0;
}
.NUwdAb {
  border-radius: 4px;
  color: #3c4043;
  outline: none;
  padding: 16.0000002px 16px;
  text-decoration: none;
  transition: background 0.2s;
}
.kJOS4 .r7XTUb .NUwdAb:focus-visible::after {
  border-color: #3c4043;
}
.NUwdAb:focus {
  background: #eee;
}
@media all and (min-width: 601px) {
  .NUwdAb:focus {
    background: #e0e0e0;
  }
}
.rQszV:not(.EKBXjc) {
  text-align: center;
}
.oO8pQe {
  padding-bottom: 0;
  padding-top: 16px;
  color: #202124;
  font-size: 24px;
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
  font-weight: 400;
  line-height: 1.3333;
  margin-bottom: 0;
  margin-top: 0;
  word-break: break-word;
}
.IXbypd {
  text-indent: -1px;
}
.tosRNd,
.ebYT4d {
  margin-bottom: 0;
  margin-top: 0;
}
.tosRNd {
  padding-bottom: 1px;
  padding-top: 7px;
  color: #202124;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.5;
}
.tosRNd:empty {
  display: none;
}
.ebYT4d {
  padding-bottom: 0;
  padding-top: 8px;
  display: -webkit-inline-box;
  display: inline-flex;
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.25px;
  min-height: 24px;
}
.Wz0RKd {
  height: 32px;
  margin-top: 8px;
}
.utPwyc {
  box-flex: 1;
  flex-grow: 1;
}
.fsv3tf {
  margin: auto;
  max-width: 380px;
  overflow: hidden;
  position: relative;
}
.fsv3tf .tgnCOd {
  position: relative;
  text-align: center;
}
.qQWzTd {
  border-radius: 50%;
  color: #5f6368;
  overflow: hidden;
}
.w1I7fb {
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
  line-height: 1.4286;
}
.d2laFc {
  width: 100%;
}
.d2laFc .qQWzTd {
  flex: none;
  height: 28px;
  margin-right: 12px;
  width: 28px;
}
.d2laFc .tgnCOd,
.s2n8Rd .tgnCOd {
  display: flex;
  align-items: center;
}
.d2laFc .tgnCOd {
  justify-content: center;
}
.fsv3tf .qQWzTd {
  height: 64px;
  margin: 0 auto 8px;
  width: 64px;
}
.r78aae {
  border-radius: 50%;
  display: block;
}
.d2laFc .r78aae,
.d2laFc .stUf5b,
.d2laFc .G5XIyb {
  max-height: 100%;
  max-width: 100%;
}
.fsv3tf .r78aae,
.fsv3tf .stUf5b,
.fsv3tf .G5XIyb {
  height: 64px;
  width: 64px;
}
.s2n8Rd .qQWzTd {
  margin-right: 8px;
  height: 20px;
  min-width: 20px;
}
.s2n8Rd .r78aae,
.kWC5Rd .r78aae,
.s2n8Rd .stUf5b,
.s2n8Rd .G5XIyb {
  color: #3c4043;
  height: 20px;
  width: 20px;
}
.s2n8Rd .WBW9sf {
  overflow: hidden;
}
.s2n8Rd .wLBAL {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.d2laFc .WBW9sf {
  box-flex: 1;
  flex-grow: 1;
}
.d2laFc .w1I7fb {
  color: #3c4043;
  font-size: 14px;
  font-weight: 500;
}
.fsv3tf .w1I7fb {
  color: #202124;
  font-size: 14px;
}
.wLBAL,
.dS3dzf {
  direction: ltr;
  font-size: 12px;
  text-align: left;
  line-height: 1.3333333;
  word-break: break-all;
}
.PdSZIe {
  direction: ltr;
  font-size: 12px;
  text-align: left;
  line-height: 1.3333333;
  word-break: break-all;
  color: #5f6368;
}
.s2n8Rd .wLBAL {
  color: #3c4043;
}
.d2laFc .wLBAL,
.d2laFc .dS3dzf {
  color: #5f6368;
}
.fsv3tf .wLBAL {
  color: #5f6368;
  text-align: center;
}
.cRiDhf {
  color: #5f6368;
  font-size: 12px;
}
.d2laFc .cRiDhf {
  align-self: flex-start;
  flex: none;
  line-height: 1.3333333;
}
.Z6Ep7d {
  align-items: flex-start;
  display: flex;
  box-flex: 0;
  flex-grow: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -8px;
  margin-top: 32px;
  min-height: 48px;
  padding-bottom: 20px;
}
.Z6Ep7d.fXx9Lc {
  margin: 0;
  min-height: 0;
  padding: 0;
}
.MSQKuf {
  align-self: auto;
  flex-shrink: 0;
  margin-bottom: 32px;
  width: 100%;
}
.dqyqtf {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  width: 100%;
}
.F9NWFb,
.XOrBDc {
  box-flex: 1;
  flex-grow: 1;
}
.Z6Ep7d.NNItQ:not(.F8PBrb) .F9NWFb,
.Z6Ep7d.NNItQ:not(.F8PBrb) .XOrBDc {
  text-align: center;
}
.F9NWFb {
  text-align: right;
}
.Z6Ep7d.NNItQ .F9NWFb,
.Z6Ep7d.F8PBrb .F9NWFb {
  padding-left: 8px;
}
.XOrBDc {
  display: flex;
  flex-direction: column;
}
.Z6Ep7d.F8PBrb .F9NWFb {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
}
.Z6Ep7d.NNItQ .XOrBDc,
.Z6Ep7d.F8PBrb .F9NWFb + .XOrBDc {
  margin-top: 16px;
}
.Z6Ep7d:not(.NNItQ) .XOrBDc .aYjiFe,
.Z6Ep7d:not(.NNItQ) .XOrBDc .sbGPcf,
.Z6Ep7d:not(.NNItQ) .XOrBDc .Xf9GD {
  text-align: left;
}
.F9NWFb .snByac,
.XOrBDc .snByac {
  max-width: 300px;
}
.FmFZVc .snByac {
  margin: 8px 16px;
}
.Z6Ep7d.F8PBrb .dqyqtf {
  margin: 0 -2px;
  width: calc(100% + 4px);
}
.Z6Ep7d.F8PBrb .XOrBDc {
  margin: 0 2px;
}
.fIPjt {
  box-flex: 1;
  flex-grow: 1;
  margin: 0 2px;
  min-width: calc(50% - 4px);
}
.jIX3bf {
  white-space: nowrap;
  width: 100%;
}
.jIX3bf .xYnMae {
  width: 100%;
}
.aYjiFe + .ZOeJnf,
.sbGPcf + .ZOeJnf {
  margin-top: 32px;
}
.Z6Ep7d .Xf9GD.u3bW4e {
  background-color: transparent;
}
@keyframes primary-indeterminate-translate {
  0% {
    transform: translateX(-145.166611%);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(-145.166611%);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-61.495191%);
  }
  to {
    transform: translateX(55.444446%);
  }
}
@keyframes primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(145.166611%);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(145.166611%);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(61.495191%);
  }
  to {
    transform: translateX(-55.4444461%);
  }
}
@keyframes primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08);
  }
  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }
  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.661479);
  }
  to {
    transform: scaleX(0.08);
  }
}
@keyframes auxiliary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(-54.888891%);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(-17.236978%);
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(29.497274%);
  }
  to {
    transform: translateX(105.388891%);
  }
}
@keyframes auxiliary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(54.888891%);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(17.236978%);
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(-29.497274%);
  }
  to {
    transform: translateX(-105.388891%);
  }
}
@keyframes auxiliary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(
      0.205028,
      0.057051,
      0.57661,
      0.453971
    );
    transform: scaleX(0.08);
  }
  19.15% {
    animation-timing-function: cubic-bezier(
      0.152313,
      0.196432,
      0.648374,
      1.004315
    );
    transform: scaleX(0.457104);
  }
  44.15% {
    animation-timing-function: cubic-bezier(
      0.257759,
      0.003163,
      0.211762,
      1.38179
    );
    transform: scaleX(0.72796);
  }
  to {
    transform: scaleX(0.08);
  }
}
@keyframes buffering {
  to {
    transform: translateX(-10px);
  }
}
@keyframes buffering-reverse {
  to {
    transform: translateX(10px);
  }
}
@keyframes indeterminate-translate-ie {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes indeterminate-translate-reverse-ie {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.sZwd7c {
  height: 4px;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  transition: opacity 250ms linear;
  width: 100%;
}
.w2zcLc {
  position: absolute;
}
.xcNBHc,
.MyvhI,
.l3q5xe {
  height: 100%;
  position: absolute;
  width: 100%;
}
.w2zcLc {
  transform-origin: top left;
  transition: transform 250ms ease;
  -webkit-transition: transform 250ms ease, -webkit-transform 250ms ease;
  transition: transform 250ms ease, -webkit-transform 250ms ease;
}
.MyvhI {
  transform-origin: top left;
  transition: transform 250ms ease;
  -webkit-transition: transform 250ms ease, -webkit-transform 250ms ease;
  transition: transform 250ms ease, -webkit-transform 250ms ease;
  animation: none;
}
.l3q5xe {
  animation: none;
}
.w2zcLc {
  background-color: #e6e6e6;
  height: 100%;
  transform-origin: top left;
  transition: transform 250ms ease;
  -webkit-transition: transform 250ms ease, -webkit-transform 250ms ease;
  transition: transform 250ms ease, -webkit-transform 250ms ease;
  width: 100%;
}
.TKVRUb {
  transform: scaleX(0);
}
.sUoeld {
  visibility: hidden;
}
.l3q5xe {
  background-color: #000;
  display: inline-block;
}
.xcNBHc {
  background-size: 10px 4px;
  background-repeat: repeat-x;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20x%3D%270px%27%20y%3D%270px%27%20enable-background%3D%27new%200%200%205%202%27%20xml%3Aspace%3D%27preserve%27%20viewBox%3D%270%200%205%202%27%20preserveAspectRatio%3D%27none%20slice%27%3E%3Ccircle%20cx%3D%271%27%20cy%3D%271%27%20r%3D%271%27%20fill%3D%27%23e6e6e6%27%2F%3E%3C%2Fsvg%3E");
  visibility: hidden;
}
.sZwd7c.B6Vhqe .MyvhI {
  transition: none;
}
.sZwd7c.B6Vhqe .TKVRUb {
  animation: primary-indeterminate-translate 2s infinite linear;
}
.sZwd7c.B6Vhqe .TKVRUb > .l3q5xe {
  animation: primary-indeterminate-scale 2s infinite linear;
}
.sZwd7c.B6Vhqe .sUoeld {
  animation: auxiliary-indeterminate-translate 2s infinite linear;
  visibility: visible;
}
.sZwd7c.B6Vhqe .sUoeld > .l3q5xe {
  animation: auxiliary-indeterminate-scale 2s infinite linear;
}
.sZwd7c.B6Vhqe.ieri7c .l3q5xe {
  transform: scaleX(0.45);
}
.sZwd7c.B6Vhqe.ieri7c .sUoeld {
  animation: none;
  visibility: hidden;
}
.sZwd7c.B6Vhqe.ieri7c .TKVRUb {
  animation: indeterminate-translate-ie 2s infinite ease-out;
}
.sZwd7c.B6Vhqe.ieri7c .TKVRUb > .l3q5xe,
.sZwd7c.B6Vhqe.ieri7c .sUoeld > .l3q5xe {
  animation: none;
}
.sZwd7c.juhVM .w2zcLc,
.sZwd7c.juhVM .MyvhI {
  right: 0;
  transform-origin: center right;
}
.sZwd7c.juhVM .TKVRUb {
  animation-name: primary-indeterminate-translate-reverse;
}
.sZwd7c.juhVM .sUoeld {
  animation-name: auxiliary-indeterminate-translate-reverse;
}
.sZwd7c.juhVM.ieri7c .TKVRUb {
  animation-name: indeterminate-translate-reverse-ie;
}
.sZwd7c.qdulke {
  opacity: 0;
}
.sZwd7c.jK7moc .sUoeld,
.sZwd7c.jK7moc .TKVRUb,
.sZwd7c.jK7moc .sUoeld > .l3q5xe,
.sZwd7c.jK7moc .TKVRUb > .l3q5xe {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.sZwd7c.D6TUi .xcNBHc {
  animation: buffering 250ms infinite linear;
  visibility: visible;
}
.sZwd7c.D6TUi.juhVM .xcNBHc {
  animation: buffering-reverse 250ms infinite linear;
}
.rn19nc {
  height: 4px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.t3Tdm {
  background: #fff;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.rn19nc .sZwd7c {
  height: 8px;
}
.rn19nc .l3q5xe {
  background-color: #e0e0e0;
}
.rn19nc .w2zcLc {
  background-color: #1a73e8;
}
@media all and (min-width: 601px) {
  .rn19nc .sZwd7c {
    border-radius: 8px 8px 0 0;
  }
}
.rn19nc .um3FLe {
  background-size: 20px 8px;
}
.CX6Ruf {
  color: #5f6368;
  font-size: 14px;
  line-height: 1.4286;
  margin-top: 32px;
}
.Ih3FE {
  height: 4px;
  overflow: hidden;
}
.kPY6ve {
  background: #fff;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  outline: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}
.Ih3FE .l3q5xe {
  background-color: #0b57d0;
}
.Ih3FE .w2zcLc {
  background-color: #e0e0e0;
}
.ObDc3 {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
@media all and (min-width: 840px) {
  .ObDc3 {
    margin-bottom: 40px;
  }
}
.wsArZ[data-ss-mode="1"] .ObDc3 {
  margin-bottom: 0;
}
@media all and (orientation: landscape) {
  .NQ5OL .ObDc3 {
    margin-bottom: 0;
  }
}
.Su9bff {
  align-items: center;
}
.vAV9bf {
  color: #1f1f1f;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 0;
  margin-bottom: var(--c-ts-b, 0);
  margin-top: 24px;
  margin-top: var(--c-ts-t, 24px);
  word-break: break-word;
  font-size: 2rem;
  font-size: var(--wf-tfs, 2rem);
}
@media all and (min-width: 960px) {
  .vAV9bf {
    line-height: 1.22222222;
    font-size: 2.25rem;
    font-size: var(--wf-tfs-bp3, 2.25rem);
  }
}
@media all and (min-width: 1600px) {
  .vAV9bf {
    line-height: 1.18181818;
    font-size: 2.75rem;
    font-size: var(--wf-tfs-bp5, 2.75rem);
  }
}
.gNJDp {
  color: #1f1f1f;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.5;
  margin-bottom: 0;
  margin-bottom: var(--c-sts-b, 0);
  margin-top: 16px;
  margin-top: var(--c-sts-t, 16px);
  font-size: 1rem;
  font-size: var(--wf-stfs, 1rem);
}
@media all and (min-width: 840px) {
  .gNJDp {
    margin-top: 24px;
    margin-top: var(--c-sts-t, 24px);
  }
}
@media all and (min-width: 1600px) {
  .gNJDp {
    line-height: 1.33333333;
    font-size: 1.125rem;
    font-size: var(--wf-stfs-bp5, 1.125rem);
  }
}
.gNJDp:empty {
  display: none;
}
.I7GnLc {
  font-weight: 500;
  letter-spacing: 0.25px;
  min-height: 24px;
}
.SOeSgb {
  height: 32px;
}
.I7GnLc,
.SOeSgb {
  margin-bottom: 0;
  margin-bottom: var(--c-sts-b, 0);
  margin-top: 16px;
  margin-top: var(--c-sts-t, 16px);
}
@media all and (min-width: 840px) {
  .I7GnLc,
  .SOeSgb {
    margin-top: 24px;
    margin-top: var(--c-sts-t, 24px);
  }
}
.ObDc3.ZYOIke .I7GnLc,
.ObDc3.ZYOIke .SOeSgb {
  margin-bottom: 0;
  margin-top: 16px;
}
@media all and (min-width: 840px) {
  .ObDc3.ZYOIke .I7GnLc,
  .ObDc3.ZYOIke .SOeSgb {
    margin-top: 24px;
  }
}
.SfkAJe {
  box-flex: 1;
  flex-grow: 1;
}
:root {
  --wf-gutw-hlf: calc(var(--c-gutw, 24px) / 2);
  --wf-gutw-hlf-bp2: calc(var(--c-gutw, 76px) / 2);
  --wf-brsz: calc(var(--c-brsz, 48px) + 24px);
  --wf-ps-t: calc(var(--c-ps-t, 32px) + var(--wf-brsz, 72px));
  --wf-ps-t-bp2: calc(var(--c-ps-t, 24px) + var(--wf-brsz, 72px));
  --wf-stage-container-min-height: calc(100vh - var(--wf-ps-t, 104px));
}
.JAdVbc {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 104px;
  margin-top: var(--wf-ps-t, 104px);
  min-height: calc(100vh - 104px);
  min-height: var(--wf-stage-container-min-height, calc(100vh - 104px));
  margin-left: 24px;
  margin-left: var(--c-ps-s, 24px);
  margin-right: 24px;
  margin-right: var(--c-ps-e, 24px);
  width: auto;
}
.wmGw4 {
  margin-left: 24px;
  margin-left: var(--c-ps-s, 24px);
  margin-right: 24px;
  margin-right: var(--c-ps-e, 24px);
  width: auto;
}
@media all and (min-width: 600px) {
  .JAdVbc,
  .wmGw4 {
    margin-left: 32px;
    margin-left: var(--c-ps-s, 32px);
    margin-right: 32px;
    margin-right: var(--c-ps-e, 32px);
    width: auto;
  }
}
@media all and (min-width: 840px) {
  .JAdVbc,
  .wmGw4 {
    margin-left: 120px;
    margin-left: var(--c-ps-s, 120px);
    margin-right: 120px;
    margin-right: var(--c-ps-e, 120px);
    width: auto;
  }
  .JAdVbc {
    margin-top: 96px;
    margin-top: var(--wf-ps-t-bp2, 96px);
  }
}
@media all and (min-width: 960px) {
  .JAdVbc,
  .wmGw4 {
    margin-left: auto;
    margin-left: var(--c-ps-s, auto);
    margin-right: auto;
    margin-right: var(--c-ps-e, auto);
    width: 840px;
  }
}
@media all and (min-width: 1240px) {
  .JAdVbc,
  .wmGw4 {
    margin-left: 200px;
    margin-left: var(--c-ps-s, 200px);
    margin-right: 200px;
    margin-right: var(--c-ps-e, 200px);
    width: auto;
  }
}
@media all and (min-width: 1600px) {
  .JAdVbc,
  .wmGw4 {
    margin-left: auto;
    margin-left: var(--c-ps-s, auto);
    margin-right: auto;
    margin-right: var(--c-ps-e, auto);
    width: 1200px;
  }
}
@media all and (min-width: 840px) and (orientation: landscape) {
  .JAdVbc,
  .wmGw4 {
    margin-left: 48px;
    margin-left: var(--c-ps-s, 48px);
    margin-right: 48px;
    margin-right: var(--c-ps-e, 48px);
    width: auto;
  }
}
.Ih3FE {
  left: 24px;
  left: var(--c-ps-s, 24px);
  position: fixed;
  right: 24px;
  right: var(--c-ps-e, 24px);
  top: 0;
  width: auto;
  z-index: 5;
}
@media all and (min-width: 600px) {
  .Ih3FE {
    left: 32px;
    left: var(--c-ps-s, 32px);
    right: 32px;
    right: var(--c-ps-e, 32px);
    width: auto;
  }
}
@media all and (min-width: 840px) {
  .Ih3FE {
    left: 120px;
    left: var(--c-ps-s, 120px);
    right: 120px;
    right: var(--c-ps-e, 120px);
    width: auto;
  }
}
@media all and (min-width: 960px) {
  .Ih3FE {
    left: auto;
    left: var(--c-ps-s, auto);
    right: auto;
    right: var(--c-ps-e, auto);
    width: 840px;
  }
}
@media all and (min-width: 1240px) {
  .Ih3FE {
    left: 200px;
    left: var(--c-ps-s, 200px);
    right: 200px;
    right: var(--c-ps-e, 200px);
    width: auto;
  }
}
@media all and (min-width: 1600px) {
  .Ih3FE {
    left: auto;
    left: var(--c-ps-s, auto);
    right: auto;
    right: var(--c-ps-e, auto);
    width: 1200px;
  }
}
@media all and (min-width: 840px) and (orientation: landscape) {
  .Ih3FE {
    left: 48px;
    left: var(--c-ps-s, 48px);
    right: 48px;
    right: var(--c-ps-e, 48px);
    width: auto;
  }
}
.A77ntc,
.Svhjgc,
.fAlnEc {
  display: flex;
  flex-direction: column;
  box-flex: 1;
  flex-grow: 1;
}
.zIgDIc {
  margin-top: -72px;
  margin-top: calc(var(--wf-brsz, 72px) * -1);
}
.wsArZ[data-ss-mode="1"] .zIgDIc {
  padding-right: 12px;
  padding-right: var(--wf-gutw-hlf, 12px);
}
@media all and (min-width: 840px) {
  .wsArZ[data-ss-mode="1"] .zIgDIc {
    padding-right: 38px;
    padding-right: var(--wf-gutw-hlf-bp2, 38px);
  }
}
@media all and (orientation: landscape) {
  .NQ5OL .zIgDIc {
    padding-right: 12px;
    padding-right: var(--wf-gutw-hlf, 12px);
  }
}
@media all and (min-width: 840px) and (orientation: landscape) {
  .NQ5OL .zIgDIc {
    padding-right: 38px;
    padding-right: var(--wf-gutw-hlf, 12px);
  }
}
.JAdVbc.nnGvjf .Svhjgc {
  overflow: visible;
}
.wsArZ[data-ss-mode="1"] .Svhjgc,
.UXFQgc {
  flex-direction: row;
  flex-wrap: wrap;
}
.UXFQgc {
  box-flex: unset;
  flex-grow: unset;
}
.wsArZ[data-ss-mode="1"] .Svhjgc {
  box-flex: unset;
  flex-grow: unset;
}
@media all and (orientation: landscape) {
  .NQ5OL .Svhjgc {
    flex-direction: row;
    box-flex: unset;
    flex-grow: unset;
    flex-wrap: wrap;
  }
}
.wsArZ[data-ss-mode="1"] .UXFQgc {
  padding-left: 12px;
  padding-left: var(--wf-gutw-hlf, 12px);
}
@media all and (min-width: 840px) {
  .wsArZ[data-ss-mode="1"] .UXFQgc {
    padding-left: 38px;
    padding-left: var(--wf-gutw-hlf-bp2, 38px);
  }
}
.wsArZ[data-ss-mode="1"] .zIgDIc {
  flex-basis: 0;
}
.wsArZ[data-ss-mode="1"] .UXFQgc {
  flex-basis: 0;
}
.wsArZ[data-ss-mode="1"] .zIgDIc,
.wsArZ[data-ss-mode="1"] .UXFQgc {
  box-flex: 1;
  flex-grow: 1;
}
@media all and (orientation: landscape) {
  .NQ5OL .zIgDIc {
    flex-basis: 0;
    box-flex: 1;
    flex-grow: 1;
  }
  .NQ5OL .UXFQgc {
    flex-basis: 0;
    box-flex: 1;
    flex-grow: 1;
  }
}
@media all and (orientation: landscape) {
  .NQ5OL .UXFQgc {
    padding-left: 12px;
    padding-left: var(--wf-gutw-hlf, 12px);
  }
}
@media all and (min-width: 840px) and (orientation: landscape) {
  .NQ5OL .UXFQgc {
    padding-left: 38px;
    padding-left: var(--wf-gutw-hlf-bp2, 38px);
  }
}
.JAdVbc.nnGvjf .UXFQgc {
  transform: none;
}
.xKcayf {
  flex-shrink: 0;
  width: 100%;
}
.Hai8t .Em2Ord:not(.eLNT1d) {
  margin-bottom: 16px;
}
.AcKKx.RDPZE {
  opacity: 0.5;
  pointer-events: none;
}
.qWK5J {
  display: flex;
  box-flex: 1;
  flex-grow: 1;
}
.UXFQgc.hAfgic {
  overflow-x: hidden;
}
.UXFQgc.hAfgic .qWK5J {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.UXFQgc.QsjdCb .qWK5J {
  transform: translate3d(0, 0, 0);
}
.UXFQgc.GEcl0c .qWK5J {
  transform: translate3d(-100%, 0, 0);
}
[dir="rtl"] .UXFQgc.GEcl0c .qWK5J {
  transform: translate3d(100%, 0, 0);
}
.FZfKCe {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.HwzH1e {
  display: flex;
  list-style: none;
  margin: 0 -16px;
  padding: 0;
}
.qKvP1b {
  display: flex;
  min-height: 48px;
}
.AVAq4d {
  align-content: center;
  border-radius: 8px;
  color: #1f1f1f;
  display: flex;
  flex-wrap: wrap;
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
  line-height: 1.33333333;
  outline-color: #0b57d0;
  padding: 0 16px;
  text-decoration: none;
}
.AVAq4d:focus {
  background: #eee;
}
.eXa0v {
  margin-right: 16px;
}
.dEoyBf {
  margin-left: -16px;
}
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Brv4Fb,
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Ra9xwd,
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-MpmGFe {
  border-color: transparent;
}
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Brv4Fb,
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Ra9xwd,
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-MpmGFe {
  border-width: 0;
}
.dEoyBf .VfPpkd-OkbHre .VfPpkd-rymPhb-fpDzbe-fmcmS {
  color: #1f1f1f;
}
.dEoyBf .VfPpkd-OkbHre .VfPpkd-rymPhb-fpDzbe-fmcmS {
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  line-height: 1.33333333;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
}
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-t08AT-Bz112c {
  fill: currentColor;
}
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me).VfPpkd-O1htCb-OWXEXe-XpnDCe
  .VfPpkd-t08AT-Bz112c {
  fill: currentColor;
}
.dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-uusGie-fmcmS {
  color: #1f1f1f;
}
.dEoyBf .VfPpkd-uusGie-fmcmS {
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  line-height: 1.33333333;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.00625rem;
}
.dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb {
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 8px;
}
[dir="rtl"] .dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb,
.dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb[dir="rtl"] {
  border-top-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 0;
}
@supports (top: max(0%)) {
  .dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb {
    width: max(12px, 8px);
  }
}
@supports (top: max(0%)) {
  .dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Ra9xwd {
    max-width: calc(100% - max(12px, 8px) * 2);
  }
}
.dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-MpmGFe {
  border-top-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 0;
}
[dir="rtl"] .dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-MpmGFe,
.dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-MpmGFe[dir="rtl"] {
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 8px;
}
@supports (top: max(0%)) {
  .dEoyBf .VfPpkd-TkwUic {
    padding-left: max(16px, 12px);
  }
}
[dir="rtl"] .dEoyBf .VfPpkd-TkwUic,
.dEoyBf .VfPpkd-TkwUic[dir="rtl"] {
  padding-left: 0;
}
@supports (top: max(0%)) {
  [dir="rtl"] .dEoyBf .VfPpkd-TkwUic,
  .dEoyBf .VfPpkd-TkwUic[dir="rtl"] {
    padding-right: max(16px, 12px);
  }
}
@supports (top: max(0%)) {
  .dEoyBf + .VfPpkd-O1htCb-W0vJo-fmcmS {
    margin-left: max(16px, 12px);
  }
}
[dir="rtl"] .dEoyBf + .VfPpkd-O1htCb-W0vJo-fmcmS,
.dEoyBf + .VfPpkd-O1htCb-W0vJo-fmcmS[dir="rtl"] {
  margin-left: 0;
}
@supports (top: max(0%)) {
  [dir="rtl"] .dEoyBf + .VfPpkd-O1htCb-W0vJo-fmcmS,
  .dEoyBf + .VfPpkd-O1htCb-W0vJo-fmcmS[dir="rtl"] {
    margin-right: max(16px, 12px);
  }
}
.JYXaTc {
  display: flex;
  flex-direction: column;
  box-flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-top: 26px;
}
.JYXaTc.fXx9Lc {
  margin: 0;
  min-height: 0;
  padding: 0;
}
.wsArZ[data-ss-mode="1"] .JYXaTc {
  width: 100%;
}
@media all and (orientation: landscape) {
  .NQ5OL .JYXaTc {
    width: 100%;
  }
}
.wsArZ[data-ss-mode="1"] .TNTaPb {
  margin-left: 40px;
  margin-right: 0;
}
.wsArZ[data-ss-mode="1"] .TNTaPb:empty {
  margin-left: 0;
}
@media all and (orientation: landscape) {
  .NQ5OL .TNTaPb {
    margin-left: 40px;
    margin-right: 0;
  }
  .NQ5OL .TNTaPb:empty {
    margin-left: 0;
    margin-right: 0;
  }
}
.wsArZ[data-ss-mode="1"] .FO2vFd {
  margin-left: 0;
  margin-right: -16px;
}
@media all and (orientation: landscape) {
  .NQ5OL .FO2vFd {
    margin-left: 0;
    margin-right: -16px;
  }
}
@media all and (min-width: 840px) {
  .wsArZ[data-ss-mode="1"] .TNTaPb,
  .wsArZ[data-ss-mode="1"] .FO2vFd {
    box-flex: unset;
    flex-grow: unset;
  }
}
@media all and (min-width: 840px) and (orientation: landscape) {
  .NQ5OL .TNTaPb,
  .NQ5OL .FO2vFd {
    box-flex: unset;
    flex-grow: unset;
  }
}
.S1zJGd {
  align-self: flex-start;
  flex-shrink: 0;
  margin-bottom: 24px;
}
.O1Slxf {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  width: 100%;
}
.TNTaPb {
  display: flex;
  box-flex: 1;
  flex-grow: 1;
}
.FO2vFd {
  display: flex;
  box-flex: 1;
  flex-grow: 1;
  justify-content: flex-start;
}
.TNTaPb {
  justify-content: flex-end;
}
.JYXaTc.NNItQ:not(.F8PBrb) .TNTaPb,
.JYXaTc.NNItQ:not(.F8PBrb) .FO2vFd {
  justify-content: center;
}
.JYXaTc.NNItQ .TNTaPb {
  padding-left: 16px;
}
.JYXaTc.F8PBrb .TNTaPb {
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
}
.JYXaTc.NNItQ .FO2vFd,
.JYXaTc.F8PBrb .TNTaPb + .FO2vFd {
  margin-top: 16px;
}
.JYXaTc:not(.NNItQ) .FO2vFd .mWv92d,
.JYXaTc:not(.NNItQ) .FO2vFd .JLt0ke,
.JYXaTc:not(.NNItQ) .FO2vFd .J7pUA {
  text-align: left;
}
.o3Yfjb {
  box-flex: 1;
  flex-grow: 1;
}
.BbN10e {
  display: block;
}
.JYXaTc.F8PBrb .O1Slxf {
  margin: 0 -2px;
  width: calc(100% + 4px);
}
.JYXaTc.F8PBrb .FO2vFd {
  margin: 0 2px;
}
.o3Yfjb {
  box-flex: 1;
  flex-grow: 1;
  margin: 0 2px;
  min-width: calc(50% - 4px);
}
.BbN10e {
  white-space: nowrap;
  width: 100%;
}
.BbN10e .pIzcPc {
  display: block;
}
.mWv92d + .n3Clv,
.JLt0ke + .n3Clv {
  margin-top: 26px;
}
.n3Clv .q6oraf {
  border-radius: 16px;
}
.JYXaTc .J7pUA.u3bW4e {
  background-color: transparent;
}
.J7pUA .snByac {
  color: #0b57d0;
  line-height: 1.42857143;
  margin: 16px;
  text-transform: none;
}
.J7pUA [jsslot] {
  margin: 0;
}
.BbN10e .pIzcPc {
  width: 100%;
}
.RDsYTb {
  color: #444746;
  font-size: 0.875rem;
  line-height: 1.42857143;
  margin-top: 32px;
}
@media all and (min-width: 601px) {
  .SdBahf.DbQnIe .eEgeR {
    display: flex;
    justify-content: space-between;
  }
}
.SdBahf:first-child .hDp5Db:first-child .ze9ebf {
  padding-top: 8px;
}
@media all and (min-width: 601px) {
  .SdBahf.DbQnIe:first-child .hDp5Db .ze9ebf {
    padding-top: 8px;
  }
  .SdBahf.DbQnIe .hDp5Db {
    box-flex: 1;
    flex-grow: 1;
    margin-right: 8px;
    width: 0;
  }
  .SdBahf.DbQnIe .hDp5Db:last-child {
    margin-right: 0;
  }
}
.ze9ebf.ze9ebf {
  box-sizing: content-box;
}
.ze9ebf {
  padding-bottom: 0;
  padding-top: 24px;
  width: 100%;
}
.ze9ebf .oJeWuf.oJeWuf {
  height: 56px;
  padding-top: 0;
}
.ze9ebf.OcVpRe .oJeWuf.oJeWuf {
  height: 36px;
}
.ze9ebf .Wic03c {
  align-items: center;
  position: static;
}
.ze9ebf .snByac {
  background-color: transparent;
  bottom: 18px;
  box-sizing: border-box;
  color: #5f6368;
  font-size: 16px;
  font-weight: 400;
  left: 8px;
  max-width: calc(100% - (2 * 8px));
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  width: auto;
  z-index: 1;
}
.ze9ebf.OcVpRe .snByac {
  bottom: 10px;
  color: #5f6368;
  font-size: 14px;
  left: 4px;
  line-height: 16px;
  padding: 0 6px;
}
.ze9ebf.u3bW4e .snByac.snByac,
.ze9ebf.CDELXb .snByac.snByac {
  background-color: #fff;
  transform: scale(0.75) translatey(-41px);
}
.ze9ebf.OcVpRe.u3bW4e .snByac,
.ze9ebf.OcVpRe.CDELXb .snByac {
  transform: scale(0.75) translatey(-165%);
}
.ze9ebf .zHQkBf:not([disabled]):focus ~ .snByac {
  color: #1a73e8;
}
.ze9ebf.IYewr.u3bW4e .zHQkBf:not([disabled]) ~ .snByac,
.ze9ebf.IYewr.CDELXb .zHQkBf:not([disabled]) ~ .snByac {
  color: #d93025;
}
.ze9ebf .zHQkBf {
  border-radius: 4px;
  color: #202124;
  font-size: 16px;
  height: 28px;
  margin: 2px;
  padding: 12px 14px;
  z-index: 1;
}
.ze9ebf.OcVpRe .zHQkBf {
  font-size: 14px;
  height: 20px;
  padding: 6px 8px;
}
.ze9ebf.YKooDc .zHQkBf,
.ze9ebf.YKooDc .MQL3Ob {
  direction: ltr;
  text-align: left;
}
.ze9ebf .iHd5yb {
  padding-left: 14px;
}
.ze9ebf.OcVpRe .iHd5yb {
  padding-left: 8px;
}
.ze9ebf .MQL3Ob {
  padding-right: 14px;
  z-index: 1;
}
.ze9ebf.OcVpRe .MQL3Ob {
  padding-right: 8px;
}
.ze9ebf .mIZh1c,
.ze9ebf .cXrdqd {
  border-radius: 4px;
  box-sizing: border-box;
}
.ze9ebf .mIZh1c,
.ze9ebf .cXrdqd,
.ze9ebf.IYewr .mIZh1c,
.ze9ebf.IYewr .cXrdqd {
  background-color: transparent;
  bottom: 0;
  height: auto;
  top: 0;
}
.ze9ebf .mIZh1c {
  border: 1px solid #dadce0;
  padding: 1px;
}
.ze9ebf .cXrdqd {
  border: 1px solid #1a73e8;
  opacity: 0;
  transform: none;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
.ze9ebf.u3bW4e .cXrdqd {
  border-width: 2px;
  animation: none;
  opacity: 1;
}
.ze9ebf.IYewr .cXrdqd {
  animation: none;
  opacity: 1;
  border-color: #d93025;
}
.ze9ebf .ndJi5d,
.ze9ebf .ovnfwe {
  pointer-events: auto;
}
.ze9ebf .RxsGPe,
.ze9ebf .Is7Fhb {
  display: none;
}
.OyEIQ {
  display: flex;
  font-size: 12px;
}
.OyEIQ:empty,
.gSlDTe:empty {
  display: none;
}
.SdBahf.Jj6Lae .OyEIQ {
  color: #d93025;
}
.EjBTad {
  display: none;
  margin-right: 8px;
}
.LxE1Id {
  height: 16px;
  width: 16px;
}
.SdBahf.Jj6Lae .EjBTad {
  display: block;
}
.SdBahf .OyEIQ {
  color: #5f6368;
  margin-top: 4px;
}
.SdBahf .ZqBZAc {
  margin-left: 16px;
}
.SdBahf.OcVpRe .ZqBZAc {
  margin-left: 10px;
}
.gSlDTe .XI8DZd {
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
}
.XI8DZd {
  background-color: transparent;
  border: none;
  color: #1a73e8;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: 500;
  letter-spacing: 0.25px;
  outline: 0;
  padding: 0;
  position: relative;
  text-align: left;
}
.XI8DZd:focus {
  background-color: rgba(26, 115, 232, 0.149);
  border-radius: 2px;
  color: #174ea6;
}
.XI8DZd:active {
  color: #174ea6;
}
.XI8DZd:active::after {
  background-color: rgba(26, 115, 232, 0.251);
}
.o6cuMc {
  align-items: flex-start;
  display: flex;
  font-size: 12px;
  line-height: normal;
  margin-top: 2px;
}
.o6cuMc.Jj6Lae {
  color: #d93025;
}
.jibhHc {
  margin-right: 8px;
  margin-top: -2px;
}
.qpSchb {
  display: block;
  height: 16px;
  width: 16px;
}
.N3Hzgf.N3Hzgf {
  box-sizing: content-box;
}
.Qzm34b,
.N3Hzgf {
  padding: 16px 0 0;
  width: 100%;
  display: block;
}
.d2CFce.OcVpRe .N3Hzgf,
.d2CFce.OcVpRe .Qzm34b {
  padding: 24px 0 0;
  padding-bottom: 0;
}
:first-child > .N3Hzgf,
:first-child > .Qzm34b,
:first-child.OcVpRe > .N3Hzgf,
:first-child.OcVpRe > .Qzm34b {
  padding: 8px 0 0;
}
.d2CFce .N3Hzgf .oJeWuf {
  height: 56px;
  padding-top: 0;
}
.d2CFce.OcVpRe .N3Hzgf .oJeWuf {
  height: 36px;
}
.N3Hzgf .Wic03c {
  align-items: center;
  position: static;
  top: 0;
}
.N3Hzgf .snByac {
  background: #fff;
  bottom: 17px;
  box-sizing: border-box;
  color: #5f6368;
  font-size: 16px;
  font-weight: 400;
  left: 8px;
  max-width: calc(100% - (2 * 8px));
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  width: auto;
  z-index: 1;
}
.N3Hzgf.IYewr.u3bW4e .zHQkBf:not([disabled]) ~ .snByac {
  color: #d93025;
}
.d2CFce.OcVpRe .N3Hzgf .snByac {
  bottom: 9px;
  color: #5f6368;
  font-size: 14px;
  left: 4px;
  line-height: 16px;
  padding: 0 6px;
}
.d2CFce.OcVpRe .N3Hzgf.u3bW4e .snByac,
.d2CFce.OcVpRe .N3Hzgf.CDELXb .snByac {
  transform: scale(0.75) translateY(-155%);
}
.N3Hzgf .ndJi5d {
  top: inherit;
  pointer-events: auto;
}
.N3Hzgf .ovnfwe {
  pointer-events: auto;
}
.N3Hzgf .Is7Fhb,
.N3Hzgf .RxsGPe {
  opacity: 1;
  padding-top: 4px;
}
.d2CFce .Is7Fhb {
  color: #5f6368;
  margin-left: 16px;
}
.d2CFce.OcVpRe .Is7Fhb {
  margin-left: 10px;
}
.N3Hzgf .RxsGPe {
  color: #d93025;
}
.N3Hzgf .Is7Fhb:empty,
.N3Hzgf .RxsGPe:empty {
  display: none;
}
.N3Hzgf .zHQkBf {
  border-radius: 4px;
  color: #202124;
  font-size: 16px;
  height: 28px;
  margin: 1px 1px 0 1px;
  padding: 13px 15px;
  z-index: 1;
}
.N3Hzgf.u3bW4e .zHQkBf,
.N3Hzgf.IYewr .zHQkBf {
  margin: 2px 2px 0 2px;
  padding: 12px 14px;
}
.d2CFce.OcVpRe .N3Hzgf .zHQkBf {
  font-size: 14px;
  height: 20px;
  padding: 7px 9px;
}
.d2CFce.OcVpRe .u3bW4e .zHQkBf,
.d2CFce.OcVpRe .IYewr .zHQkBf {
  height: 20px;
  padding: 6px 8px;
}
.jjwyfe .Wic03c,
.jjwyfe .zHQkBf,
.jjwyfe .iHd5yb,
.jjwyfe .MQL3Ob {
  direction: ltr;
  text-align: left;
}
.jjwyfe .Wic03c .snByac {
  direction: ltr;
}
.N3Hzgf .iHd5yb {
  padding-left: 15px;
}
.N3Hzgf.u3bW4e .iHd5yb {
  padding-left: 14px;
}
.N3Hzgf .MQL3Ob {
  padding-right: 15px;
  z-index: 1;
}
.N3Hzgf.u3bW4e .MQL3Ob {
  padding-right: 15px;
}
.d2CFce.OcVpRe .N3Hzgf .iHd5yb {
  padding-left: 9px;
}
.d2CFce.OcVpRe .N3Hzgf.u3bW4e .iHd5yb {
  padding-left: 8px;
}
.d2CFce.OcVpRe .N3Hzgf .MQL3Ob,
.d2CFce.OcVpRe .N3Hzgf.u3bW4e .MQL3Ob {
  padding-right: 9px;
}
.N3Hzgf .AxOyFc {
  font-family: roboto, "Noto Sans Myanmar UI", arial, sans-serif;
}
.N3Hzgf .whsOnd:not([disabled]):focus ~ .AxOyFc {
  color: #1a73e8;
}
.N3Hzgf .mIZh1c {
  border: 1px solid #dadce0;
  border-radius: 4px;
  bottom: 0;
  box-sizing: border-box;
}
.N3Hzgf .cXrdqd {
  border-radius: 4px;
  bottom: 0;
  opacity: 0;
  transform: none;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: calc(100% - 2 * 2px);
}
.N3Hzgf .mIZh1c,
.N3Hzgf .cXrdqd,
.N3Hzgf.IYewr .mIZh1c,
.N3Hzgf.IYewr .cXrdqd {
  background-color: transparent;
}
.N3Hzgf .mIZh1c,
.N3Hzgf.k0tWj .mIZh1c {
  height: 100%;
}
.N3Hzgf.IYewr .cXrdqd {
  height: calc(100% - 2 * 1px);
  width: calc(100% - 2 * 1px);
}
.N3Hzgf .cXrdqd,
.N3Hzgf.IYewr.u3bW4e .cXrdqd {
  height: calc(100% - 2 * 2px);
  width: calc(100% - 2 * 2px);
}
.N3Hzgf.u3bW4e .cXrdqd,
.N3Hzgf.IYewr .cXrdqd {
  animation: none;
  opacity: 1;
}
.N3Hzgf.u3bW4e .cXrdqd {
  border: 2px solid #1a73e8;
}
.N3Hzgf.IYewr.u3bW4e .cXrdqd {
  border-width: 2px;
}
.N3Hzgf.IYewr .cXrdqd {
  border: 1px solid #d93025;
}
.N3Hzgf.IYewr.CDELXb .snByac {
  color: #d93025;
}
.N3Hzgf .zHQkBf[disabled] {
  color: rgba(32, 33, 36, 0.502);
}
.Qzm34b .mIZh1c {
  background-color: #dadce0;
}
.Qzm34b .cXrdqd {
  background-color: #1a73e8;
}
.Qzm34b .snByac {
  color: #5f6368;
}
.Qzm34b.u3bW4e .snByac.snByac {
  color: #1a73e8;
}
.JAdVbc.hyqGzc {
  margin-left: 120px;
  margin-left: var(--c-ps-s, 120px);
  margin-right: 120px;
  margin-right: var(--c-ps-e, 120px);
}
.JAdVbc.hyqGzc.wsArZ[data-ss-mode="1"] {
  margin-left: 48px;
  margin-left: var(--c-ps-s, 48px);
  margin-right: 120px;
  margin-right: var(--c-ps-e, 48px);
}
@media all and (orientation: landscape) {
  .JAdVbc.hyqGzc.NQ5OL {
    margin-left: 48px;
    margin-left: var(--c-ps-s, 48px);
    margin-right: 120px;
    margin-right: var(--c-ps-e, 48px);
  }
}
@media all and (min-aspect-ratio: 2/3) and (orientation: portrait) {
  .JAdVbc.AdRpFc {
    margin-left: 64px;
    margin-left: var(--c-ps-s, 64px);
    margin-right: 64px;
    margin-right: var(--c-ps-e, 64px);
  }
}
@media all and (max-aspect-ratio: 3/2) and (orientation: landscape) {
  .JAdVbc.AdRpFc.wsArZ[data-ss-mode="1"] {
    margin-left: 48px;
    margin-left: var(--c-ps-s, 48px);
    margin-right: 48px;
    margin-right: var(--c-ps-e, 48px);
  }
  .JAdVbc.AdRpFc.NQ5OL {
    margin-left: 48px;
    margin-left: var(--c-ps-s, 48px);
    margin-right: 48px;
    margin-right: var(--c-ps-e, 48px);
  }
}
.d2laFc .wLBAL[data-email$="@glimitedaccount.com"] {
  display: none;
}
.lPxAeb .yAlK0b[data-email$="@glimitedaccount.com"] {
  display: none;
}
.Wzzww {
  margin-top: 11px;
}
.Wzzww.eLNT1d {
  display: none;
}
.OMvKPe {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.yz7Gpc {
  margin: 0 8px;
}
.yz7Gpc .uRo0Xe:not(:disabled) {
  color: #202124;
}
.F29zPe {
  display: none;
}
.sQecwc {
  display: hidden;
}
.QBQrY .MQL3Ob {
  padding-left: 6px;
  padding-right: 15px;
}
.cxMOTc.OcVpRe .MQL3Ob {
  padding-right: 9px;
}
.QBQrY.CDELXb.YuII8b .MQL3Ob {
  opacity: 0;
  padding: 0;
  width: 0;
}
.ID74Jc {
  color: #202124;
  direction: ltr;
  display: inline-block;
  white-space: nowrap;
}
sentinel {
}
/*# sourceURL=/_/mss/boq-identity/_/ss/k=boq-identity.AccountsSignInUi.YkO4jMsre-Y.L.B1.O/am=XxzAHI8eOIj_qc7PODkMAAAAAABAAACwMNwR/d=1/ed=1/rs=AOaEmlG2oPwUXOgYaHqO334Yp6h-rUcwQg/m=identifierview,_b,_tp,_r */
