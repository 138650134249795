.N7rBcd {
  overflow-x: auto;
}
sentinel {
}

.VfPpkd-scr2fc {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  position: relative;
}
.VfPpkd-scr2fc[hidden] {
  display: none;
}
.VfPpkd-scr2fc:disabled {
  cursor: default;
  pointer-events: none;
}
.VfPpkd-l6JLsf {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.VfPpkd-l6JLsf::before,
.VfPpkd-l6JLsf::after {
  border: 1px solid transparent;
  border-radius: inherit;
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
@media screen and (forced-colors: active) {
  .VfPpkd-l6JLsf::before,
  .VfPpkd-l6JLsf::after {
    border-color: currentColor;
  }
}
.VfPpkd-l6JLsf::before {
  transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateX(0);
}
.VfPpkd-l6JLsf::after {
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transform: translateX(-100%);
}
[dir="rtl"] .VfPpkd-l6JLsf::after,
.VfPpkd-l6JLsf[dir="rtl"]::after {
  transform: translateX(100%);
}
.VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-l6JLsf::before {
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transform: translateX(100%);
}
[dir="rtl"] .VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-l6JLsf::before,
.VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-l6JLsf[dir="rtl"]::before {
  transform: translateX(-100%);
}
.VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-l6JLsf::after {
  transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateX(0);
}
.VfPpkd-uMhiad-u014N {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  left: 0;
  right: auto;
  transform: translateX(0);
}
[dir="rtl"] .VfPpkd-uMhiad-u014N,
.VfPpkd-uMhiad-u014N[dir="rtl"] {
  left: auto;
  right: 0;
}
.VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-uMhiad-u014N {
  transform: translateX(100%);
}
[dir="rtl"] .VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-uMhiad-u014N,
.VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-uMhiad-u014N[dir="rtl"] {
  transform: translateX(-100%);
}
.VfPpkd-uMhiad {
  display: flex;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: auto;
}
[dir="rtl"] .VfPpkd-uMhiad,
.VfPpkd-uMhiad[dir="rtl"] {
  left: auto;
  right: 0;
}
.VfPpkd-uMhiad::before,
.VfPpkd-uMhiad::after {
  border: 1px solid transparent;
  border-radius: inherit;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}
@media screen and (forced-colors: active) {
  .VfPpkd-uMhiad::before,
  .VfPpkd-uMhiad::after {
    border-color: currentColor;
  }
}
.VfPpkd-VRSVNe {
  border-radius: inherit;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.VfPpkd-BFbNVe-bF1uUb {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.VfPpkd-Qsb3yd {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.VfPpkd-scr2fc:disabled .VfPpkd-Qsb3yd {
  display: none;
}
.VfPpkd-lw9akd {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}
.VfPpkd-pafCAf {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  transition: opacity 30ms 0ms cubic-bezier(0.4, 0, 1, 1);
}
.VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-pafCAf-OWXEXe-IT5dJd,
.VfPpkd-scr2fc-OWXEXe-uqeOfd .VfPpkd-pafCAf-OWXEXe-Xhs9z {
  opacity: 1;
  transition: opacity 45ms 30ms cubic-bezier(0, 0, 0.2, 1);
}
.VfPpkd-scr2fc {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.VfPpkd-scr2fc .VfPpkd-Qsb3yd::before,
.VfPpkd-scr2fc .VfPpkd-Qsb3yd::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.VfPpkd-scr2fc .VfPpkd-Qsb3yd::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.VfPpkd-scr2fc .VfPpkd-Qsb3yd::after {
  z-index: 0;
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Qsb3yd::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Qsb3yd::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd .VfPpkd-Qsb3yd::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-lJfZMc .VfPpkd-Qsb3yd::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards,
    mdc-ripple-fg-opacity-in 75ms forwards;
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-Tv8l5d-OmS1vf .VfPpkd-Qsb3yd::after {
  animation: mdc-ripple-fg-opacity-out 0.15s;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0))
    scale(var(--mdc-ripple-fg-scale, 1));
}
.VfPpkd-scr2fc .VfPpkd-Qsb3yd::before,
.VfPpkd-scr2fc .VfPpkd-Qsb3yd::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Qsb3yd::before,
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Qsb3yd::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Qsb3yd::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.VfPpkd-scr2fc .VfPpkd-DVBDLb-LhBDec-sM5MNb {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe .VfPpkd-DVBDLb-LhBDec,
.VfPpkd-scr2fc:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-DVBDLb-LhBDec {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
    .VfPpkd-DVBDLb-LhBDec,
  .VfPpkd-scr2fc:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-DVBDLb-LhBDec {
    border-color: CanvasText;
  }
}
.VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-DVBDLb-LhBDec::after,
.VfPpkd-scr2fc:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-DVBDLb-LhBDec::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .VfPpkd-scr2fc.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
    .VfPpkd-DVBDLb-LhBDec::after,
  .VfPpkd-scr2fc:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-DVBDLb-LhBDec::after {
    border-color: CanvasText;
  }
}
.LXctle {
  width: 36px;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled .VfPpkd-uMhiad::after {
  background: rgb(26, 115, 232);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe):not(:active)
  .VfPpkd-uMhiad::after {
  background: rgb(23, 78, 166);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:active)
  .VfPpkd-uMhiad::after {
  background: rgb(23, 78, 166);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:active .VfPpkd-uMhiad::after {
  background: rgb(23, 78, 166);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:disabled .VfPpkd-uMhiad::after {
  background: rgb(60, 64, 67);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled .VfPpkd-uMhiad::after {
  background: rgb(95, 99, 104);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe):not(:active)
  .VfPpkd-uMhiad::after {
  background: rgb(32, 33, 36);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:active)
  .VfPpkd-uMhiad::after {
  background: rgb(32, 33, 36);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:active .VfPpkd-uMhiad::after {
  background: rgb(32, 33, 36);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:disabled .VfPpkd-uMhiad::after {
  background: rgb(60, 64, 67);
}
.LXctle .VfPpkd-uMhiad::before {
  background: rgb(255, 255, 255);
}
.LXctle:enabled .VfPpkd-VRSVNe {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.LXctle:enabled .VfPpkd-VRSVNe .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0.05;
}
.LXctle:enabled .VfPpkd-VRSVNe .VfPpkd-BFbNVe-bF1uUb {
  background-color: transparent;
}
.LXctle:disabled .VfPpkd-VRSVNe {
  box-shadow: none;
}
.LXctle:disabled .VfPpkd-VRSVNe .VfPpkd-BFbNVe-bF1uUb {
  opacity: 0;
}
.LXctle:disabled .VfPpkd-VRSVNe .VfPpkd-BFbNVe-bF1uUb {
  background-color: transparent;
}
.LXctle .VfPpkd-DVBDLb-LhBDec-sM5MNb,
.LXctle .VfPpkd-uMhiad {
  height: 20px;
}
.LXctle:disabled .VfPpkd-uMhiad::after {
  opacity: 0.38;
}
.LXctle .VfPpkd-uMhiad {
  border-radius: 10px 10px 10px 10px;
}
.LXctle .VfPpkd-uMhiad {
  width: 20px;
}
.LXctle .VfPpkd-uMhiad-u014N {
  width: calc(100% - 20px);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled .VfPpkd-pafCAf {
  fill: rgb(255, 255, 255);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:disabled .VfPpkd-pafCAf {
  fill: rgb(255, 255, 255);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled .VfPpkd-pafCAf {
  fill: rgb(255, 255, 255);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:disabled .VfPpkd-pafCAf {
  fill: rgb(255, 255, 255);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:disabled .VfPpkd-lw9akd {
  opacity: 0.38;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:disabled .VfPpkd-lw9akd {
  opacity: 0.38;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd .VfPpkd-pafCAf,
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd .VfPpkd-pafCAf {
  width: 18px;
  height: 18px;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe)
  .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe)
  .VfPpkd-Qsb3yd::after {
  background-color: rgb(26, 115, 232);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Qsb3yd::after {
  background-color: rgb(26, 115, 232);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:active .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:active .VfPpkd-Qsb3yd::after {
  background-color: rgb(26, 115, 232);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe)
  .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe)
  .VfPpkd-Qsb3yd::after {
  background-color: rgb(60, 64, 67);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Qsb3yd::after {
  background-color: rgb(60, 64, 67);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:active .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:active .VfPpkd-Qsb3yd::after {
  background-color: rgb(60, 64, 67);
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe):hover
  .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe).VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-Qsb3yd::before {
  opacity: 0.04;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-Qsb3yd::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:active:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-Qsb3yd::after {
  transition: opacity 0.15s linear;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:active:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-Qsb3yd::after {
  transition-duration: 75ms;
  opacity: 0.1;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled:active.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(
    --mdc-switch-selected-pressed-state-layer-opacity,
    0.1
  );
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe):hover
  .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe).VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-Qsb3yd::before {
  opacity: 0.04;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe
  .VfPpkd-Qsb3yd::before,
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(.VfPpkd-ksKsZd-mWPk3d):focus
  .VfPpkd-Qsb3yd::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:active:not(.VfPpkd-ksKsZd-mWPk3d)
  .VfPpkd-Qsb3yd::after {
  transition: opacity 0.15s linear;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:active:not(.VfPpkd-ksKsZd-mWPk3d):active
  .VfPpkd-Qsb3yd::after {
  transition-duration: 75ms;
  opacity: 0.1;
}
.LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled:active.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(
    --mdc-switch-unselected-pressed-state-layer-opacity,
    0.1
  );
}
.LXctle .VfPpkd-Qsb3yd {
  height: 48px;
  width: 48px;
}
.LXctle .VfPpkd-l6JLsf {
  height: 14px;
}
.LXctle:disabled .VfPpkd-l6JLsf {
  opacity: 0.12;
}
.LXctle:enabled .VfPpkd-l6JLsf::after {
  background: rgb(138, 180, 248);
}
.LXctle:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe):not(:active)
  .VfPpkd-l6JLsf::after {
  background: rgb(138, 180, 248);
}
.LXctle:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:active)
  .VfPpkd-l6JLsf::after {
  background: rgb(138, 180, 248);
}
.LXctle:enabled:active .VfPpkd-l6JLsf::after {
  background: rgb(138, 180, 248);
}
.LXctle:disabled .VfPpkd-l6JLsf::after {
  background: rgb(60, 64, 67);
}
.LXctle:enabled .VfPpkd-l6JLsf::before {
  background: rgb(218, 220, 224);
}
.LXctle:enabled:hover:not(.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe):not(:active)
  .VfPpkd-l6JLsf::before {
  background: rgb(218, 220, 224);
}
.LXctle:enabled.VfPpkd-ksKsZd-mWPk3d-OWXEXe-AHe6Kc-XpnDCe:not(:active)
  .VfPpkd-l6JLsf::before {
  background: rgb(218, 220, 224);
}
.LXctle:enabled:active .VfPpkd-l6JLsf::before {
  background: rgb(218, 220, 224);
}
.LXctle:disabled .VfPpkd-l6JLsf::before {
  background: rgb(60, 64, 67);
}
.LXctle .VfPpkd-l6JLsf {
  border-radius: 7px 7px 7px 7px;
}
@media (-ms-high-contrast: active), screen and (forced-colors: active) {
  .LXctle:disabled .VfPpkd-uMhiad::after {
    opacity: 1;
  }
  .LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:enabled .VfPpkd-pafCAf {
    fill: ButtonText;
  }
  .LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:disabled .VfPpkd-pafCAf {
    fill: GrayText;
  }
  .LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:enabled .VfPpkd-pafCAf {
    fill: ButtonText;
  }
  .LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:disabled .VfPpkd-pafCAf {
    fill: GrayText;
  }
  .LXctle.VfPpkd-scr2fc-OWXEXe-gk6SMd:disabled .VfPpkd-lw9akd {
    opacity: 1;
  }
  .LXctle.VfPpkd-scr2fc-OWXEXe-uqeOfd:disabled .VfPpkd-lw9akd {
    opacity: 1;
  }
  .LXctle:disabled .VfPpkd-l6JLsf {
    opacity: 1;
  }
}
.uVccjd {
  box-flex: 0;
  flex-grow: 0;
  -webkit-user-select: none;
  transition: border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-tap-highlight-color: transparent;
  border: 10px solid rgba(0, 0, 0, 0.54);
  border-radius: 3px;
  box-sizing: content-box;
  cursor: pointer;
  display: inline-block;
  max-height: 0;
  max-width: 0;
  outline: none;
  overflow: visible;
  position: relative;
  vertical-align: middle;
  z-index: 0;
}
.uVccjd.ZdhN5b {
  border-color: rgba(255, 255, 255, 0.7);
}
.uVccjd.ZdhN5b[aria-disabled="true"] {
  border-color: rgba(255, 255, 255, 0.3);
}
.uVccjd[aria-disabled="true"] {
  border-color: #bdbdbd;
  cursor: default;
}
.uHMk6b {
  transition: all 0.1s 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform, border-radius;
  border: 8px solid white;
  left: -8px;
  position: absolute;
  top: -8px;
}
[aria-checked="true"] > .uHMk6b,
[aria-checked="mixed"] > .uHMk6b {
  transform: scale(0);
  transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 100%;
}
.B6Vhqe .TCA6qd {
  left: 5px;
  top: 2px;
}
.N2RpBe .TCA6qd {
  left: 10px;
  transform: rotate(-45deg);
  transform-origin: 0;
  top: 7px;
}
.TCA6qd {
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.rq8Mwb {
  animation: quantumWizPaperAnimateCheckMarkOut 0.2s forwards;
  clip: rect(0, 20px, 20px, 0);
  height: 20px;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 20px;
}
[aria-checked="true"] > .rq8Mwb,
[aria-checked="mixed"] > .rq8Mwb {
  animation: quantumWizPaperAnimateCheckMarkIn 0.2s 0.1s forwards;
  clip: rect(0, 20px, 20px, 20px);
}
@media print {
  [aria-checked="true"] > .rq8Mwb,
  [aria-checked="mixed"] > .rq8Mwb {
    clip: auto;
  }
}
.B6Vhqe .MbUTNc {
  display: none;
}
.MbUTNc {
  border: 1px solid #fff;
  height: 5px;
  left: 0;
  position: absolute;
}
.B6Vhqe .Ii6cVc {
  width: 8px;
  top: 7px;
}
.N2RpBe .Ii6cVc {
  width: 11px;
}
.Ii6cVc {
  border: 1px solid #fff;
  left: 0;
  position: absolute;
  top: 5px;
}
.PkgjBf {
  transform: scale(2.5);
  transition: opacity 0.15s ease;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  height: 20px;
  left: -10px;
  opacity: 0;
  outline: 0.1px solid transparent;
  pointer-events: none;
  position: absolute;
  top: -10px;
  width: 20px;
  z-index: -1;
}
.ZdhN5b .PkgjBf {
  background-color: rgba(255, 255, 255, 0.2);
}
.qs41qe > .PkgjBf {
  animation: quantumWizRadialInkSpread 0.3s;
  animation-fill-mode: forwards;
  opacity: 1;
}
.i9xfbb > .PkgjBf {
  background-color: rgba(0, 150, 136, 0.2);
}
.u3bW4e > .PkgjBf {
  animation: quantumWizRadialInkFocusPulse 0.7s infinite alternate;
  background-color: rgba(0, 150, 136, 0.2);
  opacity: 1;
}
@keyframes quantumWizPaperAnimateCheckMarkIn {
  0% {
    clip: rect(0, 0, 20px, 0);
  }
  to {
    clip: rect(0, 20px, 20px, 0);
  }
}
@keyframes quantumWizPaperAnimateCheckMarkOut {
  0% {
    clip: rect(0, 20px, 20px, 0);
  }
  to {
    clip: rect(0, 20px, 20px, 20px);
  }
}
.hdGwMb .BudEQ {
  border-bottom: 1px solid #dadce0;
}
.sSzDje {
  display: -webkit-inline-box;
  display: inline-flex;
  padding: 16px 0 15px;
  width: 100%;
}
.sSzDje.PlAvif,
.sSzDje.STFd6 {
  padding: 0;
}
.sSzDje.PlAvif.N3snbf {
  margin-left: -24px;
  width: calc(100% + 24px);
}
.sSzDje.PlAvif:not(.N3snbf) {
  margin-right: -24px;
  width: calc(100% + 24px);
}
@media all and (min-width: 450px) {
  .sSzDje.PlAvif.N3snbf {
    margin-left: -40px;
    width: calc(100% + 40px);
  }
  .sSzDje.PlAvif:not(.N3snbf) {
    margin-right: -40px;
    width: calc(100% + 40px);
  }
}
.sSzDje.STFd6 {
  align-items: flex-start;
}
.sSzDje.cd29Sd {
  display: flex;
}
.sSzDje.RDPZE {
  pointer-events: none;
}
.enBDyd {
  align-items: center;
  display: flex;
  flex: none;
  height: 24px;
  justify-content: center;
}
.sSzDje:not(.Msforc) .enBDyd {
  width: 24px;
}
.sSzDje.Msforc .enBDyd {
  width: 36px;
}
.sSzDje.N3snbf .enBDyd {
  margin-left: 16px;
}
.sSzDje.PlAvif .enBDyd,
.sSzDje.STFd6 .enBDyd {
  position: relative;
  top: 16px;
}
.y5MMGc.y5MMGc {
  box-sizing: content-box;
}
.y5MMGc {
  display: block;
}
.y5MMGc .fsHoPb,
.y5MMGc .oyD5Oc {
  border-color: #fff;
}
.y5MMGc,
.sSzDje,
.y5MMGc .Id5V1 {
  border-color: #5f6368;
}
.sD2Hod.N2RpBe,
.sD2Hod.N2RpBe .Id5V1,
.sD2Hod .nQOrEb {
  border-color: #1a73e8;
}
.y5MMGc.i9xfbb > .MbhUzd,
.y5MMGc.u3bW4e > .MbhUzd {
  background-color: rgba(26, 115, 232, 0.149);
}
.y5MMGc.Ne8lhe .VfPpkd-YQoJzd {
  box-sizing: border-box;
}
.IhH7Wd.Jj6Lae .sD2Hod,
.IhH7Wd.Jj6Lae .sD2Hod .Id5V1,
.IhH7Wd.Jj6Lae .sD2Hod .nQOrEb,
.IhH7Wd.Jj6Lae
  .sD2Hod.Ne8lhe
  .VfPpkd-muHVFf-bMcfAe:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
  ~ .VfPpkd-YQoJzd {
  border-color: #d93025;
}
.IhH7Wd.Jj6Lae
  .sD2Hod.Ne8lhe
  .VfPpkd-muHVFf-bMcfAe:enabled:checked
  ~ .VfPpkd-YQoJzd {
  border-color: #d93025;
  background-color: #d93025;
}
.y5MMGc.RDPZE,
.sSzDje.RDPZE,
.y5MMGc.RDPZE .Id5V1,
.sSzDje.RDPZE .nQOrEb {
  border-color: rgba(0, 0, 0, 0.26);
}
.TKwxld {
  align-items: center;
  color: #5f6368;
  display: flex;
  flex: none;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.sSzDje.PlAvif .TKwxld,
.sSzDje.STFd6 .TKwxld {
  position: relative;
  top: 16px;
}
.DVnhEd {
  align-items: flex-start;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  min-width: 0;
  width: 100%;
}
.sSzDje.N3snbf:not(.cd29Sd) .DVnhEd {
  margin-left: 0;
}
.qognrc {
  background: transparent;
  border: none;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 20px;
  margin-left: 0;
  padding: 0;
  position: relative;
  text-align: left;
  text-decoration: none;
}
.sSzDje.N3snbf .qognrc {
  padding-left: 24px;
}
.sSzDje:not(.N3snbf) .qognrc {
  padding-right: 24px;
}
@media all and (min-width: 450px) {
  .sSzDje.N3snbf .qognrc {
    padding-left: 40px;
  }
  .sSzDje:not(.N3snbf) .qognrc {
    padding-right: 40px;
  }
}
.sSzDje:not(.RDPZE) .qognrc:hover:before,
.sSzDje:not(.RDPZE) .qognrc:focus:before {
  background: rgba(26, 115, 232, 0.149);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.sSzDje.STFd6:not(.RDPZE) .qognrc:hover:before,
.sSzDje.STFd6:not(.RDPZE) .qognrc:focus:before {
  height: 100%;
  top: 0;
}
.qognrc:hover,
.qognrc:focus {
  outline: none;
}
.vEZpFf {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 18px 16px 17px 16px;
  width: 100%;
}
.sSzDje:not(.cd29Sd).N3snbf .vEZpFf {
  padding-left: 0;
}
.sSzDje.STFd6 .vEZpFf {
  padding-bottom: 0;
  padding-top: 0;
}
.sSzDje.N3snbf .qognrc::after {
  background: #dadce0;
  content: "";
  height: calc(100% - 30px + 1px);
  position: absolute;
  right: 0;
  top: 15px;
  width: 1px;
}
.jGAaxb:not(.RDPZE),
.sSzDje:not(.RDPZE) .jGAaxb {
  cursor: pointer;
}
.wFCloc {
  padding-bottom: 3px;
  padding-top: -3px;
  color: #202124;
  display: inline-block;
  font-size: 14px;
  max-width: 100%;
}
.wFCloc .d3GVvd {
  overflow: hidden;
  text-overflow: ellipsis;
}
.sSzDje.cd29Sd .wFCloc {
  display: block;
}
.sSzDje .wFCloc {
  padding-top: 0;
  padding-bottom: 0;
}
.sSzDje.STFd6 .wFCloc {
  padding-bottom: 0;
  padding-top: 10px;
}
.sSzDje.RDPZE .wFCloc {
  color: rgba(32, 33, 36, 0.502);
}
.Ooz8dc {
  padding-bottom: 9px;
  padding-top: 0;
  color: #5f6368;
  flex: 0 1 auto;
  font-size: 12px;
  line-height: 1.3333333;
  width: 100%;
}
.sSzDje.RDPZE .Ooz8dc {
  color: rgba(32, 33, 36, 0.502);
}
.Df4rGb {
  padding-bottom: 0;
  padding-top: 8px;
  display: none;
  font-size: 12px;
}
.IhH7Wd.hpxoof .Df4rGb {
  display: block;
}
.IhH7Wd.Jj6Lae .Df4rGb {
  color: #d93025;
}
.Wh5NTe.hdGwMb {
  border-bottom: 1px solid #dadce0;
}
.Wh5NTe.hdGwMb.jVwmLb {
  border-bottom: none;
}
.Wh5NTe.hdGwMb .DEB4cc {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.Wh5NTe.hdGwMb.jVwmLb .DEB4cc {
  border-bottom: none;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}
.mlcKZ {
  margin-left: 16px;
}
.mlcKZ > .jW1oEc {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 24px;
}
.Wh5NTe.hdGwMb.jVwmLb .mlcKZ > .jW1oEc {
  transform: rotate(-180deg);
}
.n7GKTd {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: 14px 0;
  position: relative;
  text-align: left;
  width: 100%;
}
.n7GKTd::before {
  background: rgba(26, 115, 232, 0.149);
  bottom: 0;
  content: "";
  display: block;
  left: -40px;
  margin: auto;
  opacity: 0;
  position: absolute;
  right: -40px;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}
.IhH7Wd.u3bW4e .n7GKTd::before {
  opacity: 1;
}
.n7GKTd .TKwxld {
  margin-right: 16px;
  position: relative;
  top: 6px;
}
.Wh5NTe.hdGwMb .wFCloc {
  display: block;
  padding-bottom: 0;
}
.Wh5NTe.hdGwMb .BudEQ {
  border-bottom: none;
}
.Wh5NTe.hdGwMb .ci67pc {
  padding-left: 40px;
}
.QiCvwd {
  padding-bottom: 0;
  padding-top: 0;
  color: #5f6368;
  display: block;
  font-size: 12px;
  line-height: 1.3333333;
}
.n7GKTd .q3GwHc {
  box-flex: 1;
  flex-grow: 1;
}
.sSzDje.NEk0Ve .enBDyd {
  justify-content: flex-start;
}
.sSzDje.NEk0Ve .y5MMGc {
  margin-left: -8px;
}
.zJKIV {
  -webkit-user-select: none;
  transition: border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-tap-highlight-color: transparent;
  border-radius: 3px;
  box-sizing: content-box;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  outline: none;
  position: relative;
  vertical-align: middle;
  width: 20px;
  z-index: 0;
}
.SCWude {
  animation: quantumWizPaperAnimateSelectOut 0.2s forwards;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
[aria-checked="true"] > .SCWude {
  animation: quantumWizPaperAnimateSelectIn 0.2s 0.1s forwards;
}
.t5nRo {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 2px;
  border-color: rgba(0, 0, 0, 0.54);
}
.N2RpBe .t5nRo {
  border-color: #009688;
}
.wEIpqb {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 5px solid #009688;
  transition: transform ease 0.28s;
  -webkit-transition: transform ease 0.28s, -webkit-transform ease 0.28s;
  transition: transform ease 0.28s, -webkit-transform ease 0.28s;
  transform: translateX(-50%) translateY(-50%) scale(0);
}
[aria-checked="true"] .wEIpqb {
  transform: translateX(-50%) translateY(-50%) scale(1);
}
.zJKIV[aria-disabled="true"] {
  cursor: default;
  pointer-events: none;
}
[aria-disabled="true"][aria-checked="true"] .wEIpqb {
  border-color: rgba(0, 0, 0, 0.26);
}
[aria-disabled="true"] .t5nRo {
  border-color: rgba(0, 0, 0, 0.26);
}
.k5cvGe {
  transform: scale(3);
  transition: opacity 0.15s ease;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  height: 20px;
  left: 0;
  opacity: 0;
  outline: 0.1px solid transparent;
  pointer-events: none;
  position: absolute;
  width: 20px;
  z-index: -1;
}
.qs41qe > .k5cvGe {
  animation: quantumWizRadialInkSpread 0.3s;
  animation-fill-mode: forwards;
  opacity: 1;
}
.i9xfbb > .k5cvGe {
  background-color: rgba(0, 150, 136, 0.2);
}
.u3bW4e > .k5cvGe {
  animation: quantumWizRadialInkFocusPulse 0.7s infinite alternate;
  background-color: rgba(0, 150, 136, 0.2);
  opacity: 1;
}
@keyframes quantumWizPaperAnimateSelectIn {
  0% {
    height: 0;
    width: 0;
  }
  to {
    height: 100%;
    width: 100%;
  }
}
@keyframes quantumWizPaperAnimateSelectOut {
  0% {
    height: 0;
    width: 0;
  }
  to {
    height: 100%;
    width: 100%;
  }
}
.DPChp {
  display: flex;
  width: 100%;
}
.Txuhic {
  box-flex: 1;
  flex-grow: 1;
  min-width: 0;
}
@media all and (min-width: 601px) {
  .Fjk18.DbQnIe .Txuhic {
    display: flex;
    justify-content: space-between;
  }
}
.Fjk18 .sSzDje {
  padding: 8px 0 0;
}
.Fjk18 .DVnhEd {
  padding: 0;
}
sentinel {
}
