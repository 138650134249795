:root {
  --primary-color: #081c4c;
}

:root {
  --button-font-color: #ffffff;
}

:root {
  --secondary-button-border-color: #c9cace;
  --social-button-border-color: #c9cace;
  --radio-button-border-color: #c9cace;
}

:root {
  --secondary-button-text-color: #1e212a;
}

:root {
  --link-color: #4a82da;
}

:root {
  --title-font-color: #1e212a;
}

:root {
  --font-default-color: #1e212a;
}

:root {
  --widget-background-color: #ffffff;
}

:root {
  --box-border-color: #c9cace;
}

:root {
  --font-light-color: #65676e;
}

:root {
  --input-text-color: #000000;
}

:root {
  --input-border-color: #c9cace;
  --border-default-color: #c9cace;
}

:root {
  --input-background-color: #ffffff;
}

:root {
  --icon-default-color: #65676e;
}

:root {
  --error-color: #d03c38;
  --error-text-color: #ffffff;
}

:root {
  --success-color: #13a688;
}

:root {
  --base-focus-color: #635dff;
  --transparency-focus-color: rgba(99, 93, 255, 0.15);
}

.roott {
  --transparency-focus-color: rgba(99, 93, 255, 0.15);
}

:root {
  --base-hover-color: #000000;
  --transparency-hover-color: rgba(0, 0, 0, var(--hover-transparency-value));
}

html,
:root {
  font-size: 16px;
  --default-font-size: 16px;
}

body {
  --title-font-size: 1.5rem;
  --title-font-weight: var(--font-default-weight);
}

.cfdfdef49 {
  font-size: 0.875rem;
  font-weight: var(--font-default-weight);
}

.ca920f895 {
  font-size: 0.875rem;
  font-weight: var(--font-default-weight);
}

.c89f1057d,
.c23436cee {
  font-size: 1rem;
  font-weight: var(--font-default-weight);
}

.c8db587cf {
  font-size: 1rem;
  font-weight: var(--font-default-weight);
}

.c76e25763,
.cea0519b1,
[id^="ulp-container-"] a {
  font-size: 0.875rem;
  font-weight: var(--font-bold-weight) !important;
}

:root {
  --button-border-width: 1px;
  --social-button-border-width: 1px;
  --radio-border-width: 1px;
}

body {
  --button-border-radius: 3px;
  --radio-border-radius: 3px;
}

:root {
  --input-border-width: 1px;
}

body {
  --input-border-radius: 3px;
}

:root {
  --border-radius-outer: 5px;
}

:root {
  --box-border-width: 0px;
}

body {
  --logo-alignment: 0 auto;
}

.c41e1d961 {
  content: url("./../images/logo-smart.png");
}

body {
  --logo-height: 100px;
}
.c41e1d961 {
  height: var(--logo-height);
}

body {
  --header-alignment: center;
}

.c75147bfb {
  --page-background-alignment: center;
}

body {
  --page-background-color: #044bb4;
}

.c75147bfb {
  --page-background-image: url("./../images/fondo-smart.png");
}
