.Mh0NNb {
  background-color: #323232;
  bottom: 0;
  box-sizing: border-box;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-size: 14px;
  left: 0;
  min-height: 48px;
  position: fixed;
  right: 0;
  transform: translate(0, 100%);
  visibility: hidden;
  z-index: 99999;
}
.M6tHv {
  -webkit-box-align: center;
  box-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  min-height: inherit;
  padding: 0;
}
.aGJE1b {
  box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: normal;
  overflow: hidden;
  padding: 14px 24px;
  text-overflow: ellipsis;
  word-break: break-word;
}
.x95qze {
  align-self: center;
  color: #eeff41;
  box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  float: right;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  outline: none;
  padding: 14px 24px;
}
.KYZn9b {
  background-color: #4285f4;
}
.misTTe {
  transform: translate(0, 0);
}
@media screen and (min-width: 481px) {
  .Mh0NNb {
    min-width: 288px;
    max-width: 568px;
    border-radius: 2px;
  }
  .Mp2Z0b {
    left: 24px;
    margin-right: 24px;
    right: auto;
  }
  .VcC8Fc {
    left: 50%;
    right: auto;
    transform: translate(-50%, 100%);
  }
  .Mp2Z0b.misTTe {
    bottom: 24px;
  }
  .VcC8Fc.misTTe {
    bottom: 0;
    transform: translate(-50%, 0);
  }
  .M6tHv {
    padding: 0;
  }
  .aGJE1b {
    padding-right: 24px;
  }
}
@media screen and (max-width: 480px) {
  .xbgI6e .aGJE1b,
  .xbgI6e .x95qze {
    padding-bottom: 24px;
    padding-top: 24px;
  }
}
@media screen and (min-width: 481px) and (max-width: 568px) {
  .Mh0NNb {
    max-width: 90%;
  }
}
@media screen and (min-width: 569px) {
  .Mh0NNb {
    max-width: 568px;
  }
}
.pQ0lne {
  position: relative;
}
.OVnw0d > :first-child {
  margin-top: 0;
}
.OVnw0d > :last-child {
  margin-bottom: 0;
}
.vxx8jf {
  color: #202124;
  font-size: 14px;
}
.vxx8jf .PrDSKc {
  margin: 0;
  padding: 0;
}
.vxx8jf > :first-child {
  margin-top: 0;
  padding-top: 0;
}
.vxx8jf > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.OVnw0d {
  margin: 0;
  padding: 0;
  position: relative;
}
.OVnw0d > .SmR8:only-child {
  padding-top: 1px;
}
.OVnw0d > .SmR8:only-child:before {
  top: 0;
}
.OVnw0d > .SmR8:not(first-child) {
  padding-bottom: 1px;
}
.OVnw0d > .SmR8:after {
  bottom: 0;
}
.OVnw0d > .SmR8:only-child:before,
.OVnw0d > .SmR8:after {
  border-bottom: 1px solid #dadce0;
  content: "";
  height: 0;
  left: 24px;
  right: 24px;
  position: absolute;
}
@media all and (min-width: 450px) {
  .OVnw0d > .SmR8:only-child:before,
  .OVnw0d > .SmR8:after {
    left: 40px;
    right: 40px;
  }
}
.JDAKTe {
  margin-top: 8px;
  margin-left: 25px;
  padding-left: 15px;
}
.JDAKTe.W7Aapd,
.JDAKTe.SmR8,
.JDAKTe.cd29Sd {
  margin: 0 -24px;
  list-style: none;
  padding: 0;
  position: relative;
}
@media all and (min-width: 450px) {
  .JDAKTe.cd29Sd,
  .JDAKTe.SmR8 {
    margin: 0 -40px;
  }
}
.JDAKTe.zpCp3 {
  margin: auto -24px;
}
@media all and (min-width: 450px) {
  .JDAKTe.zpCp3 {
    margin: auto -40px;
  }
}
.lCoei {
  display: flex;
  padding-bottom: 18px;
  padding-top: 18px;
}
.OVnw0d > .SmR8 .lCoei {
  padding-bottom: 17px;
  padding-top: 17px;
}
.lCoei.SmR8 {
  cursor: pointer;
}
.lCoei.RDPZE {
  cursor: default;
  opacity: 0.5;
  outline: 0;
  pointer-events: none;
}
.lCoei.YZVTmd {
  padding-left: 24px;
  padding-right: 24px;
}
@media all and (min-width: 450px) {
  .lCoei.YZVTmd {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.lCoei[role="button"],
.lCoei[role="link"] {
  cursor: pointer;
  transition: background 0.2s;
}
.lCoei:focus {
  outline: 0;
}
.lCoei[role="button"]:focus,
.lCoei[role="link"]:focus {
  background: #e8f0fe;
}
@media (hover) {
  .lCoei[role="button"]:hover,
  .lCoei[role="link"]:hover {
    background: #e8f0fe;
  }
}
.wupBIe {
  color: #5f6368;
  flex-shrink: 0;
  height: 24px;
  margin: -2px 0 0;
  overflow: hidden;
  width: 24px;
}
.wupBIe .stUf5b,
.wupBIe .G5XIyb {
  height: 100%;
  width: 100%;
}
.JDAKTe.SmR8 .wupBIe {
  color: #1a73e8;
}
.JDAKTe.cd29Sd .vxx8jf {
  margin: 0 0 0 16px;
  box-flex: 1;
  flex-grow: 1;
}
.JDAKTe.riGH9 .vxx8jf {
  box-flex: 1;
  flex-grow: 1;
}
.n4LT9 {
  color: #5f6368;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
}
.R1xbyb {
  color: #5f6368;
  font-size: 12px;
  line-height: 1.3333333;
  padding-top: 3px;
}
.R1xbyb > :first-child {
  margin-top: 0;
  padding-top: 0;
}
.R1xbyb > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.O0WRkf {
  -webkit-user-select: none;
  transition: background 0.2s 0.1s;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  min-width: 4em;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;
  z-index: 0;
}
.A9jyad {
  font-size: 13px;
  line-height: 16px;
}
.zZhnYe {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  background: #dfdfdf;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.zZhnYe.qs41qe {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background 0.8s;
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
}
.e3Duub,
.e3Duub a,
.e3Duub a:hover,
.e3Duub a:link,
.e3Duub a:visited {
  background: #4285f4;
  color: #fff;
}
.HQ8yf,
.HQ8yf a {
  color: #4285f4;
}
.UxubU,
.UxubU a {
  color: #fff;
}
.ZFr60d {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}
.O0WRkf.u3bW4e .ZFr60d {
  background-color: rgba(0, 0, 0, 0.12);
}
.UxubU.u3bW4e .ZFr60d {
  background-color: rgba(255, 255, 255, 0.3);
}
.e3Duub.u3bW4e .ZFr60d {
  background-color: rgba(0, 0, 0, 0.122);
}
.HQ8yf.u3bW4e .ZFr60d {
  background-color: rgba(66, 133, 244, 0.15);
}
.Vwe4Vb {
  transform: translate(-50%, -50%) scale(0);
  transition: opacity 0.2s ease, visibility 0s ease 0.2s, transform 0s ease 0.2s;
  -webkit-transition: opacity 0.2s ease, visibility 0s ease 0.2s,
    transform 0s ease 0.2s, -webkit-transform 0s ease 0.2s;
  transition: opacity 0.2s ease, visibility 0s ease 0.2s, transform 0s ease 0.2s,
    -webkit-transform 0s ease 0.2s;
  background-size: cover;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  visibility: hidden;
}
.O0WRkf.qs41qe .Vwe4Vb {
  transform: translate(-50%, -50%) scale(2.2);
  opacity: 1;
  visibility: visible;
}
.O0WRkf.qs41qe.M9Bg4d .Vwe4Vb {
  -webkit-transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.O0WRkf.j7nIZb .Vwe4Vb {
  transform: translate(-50%, -50%) scale(2.2);
  visibility: visible;
}
.oG5Srb .Vwe4Vb,
.zZhnYe .Vwe4Vb {
  background-image: radial-gradient(
    circle farthest-side,
    rgba(0, 0, 0, 0.12),
    rgba(0, 0, 0, 0.12) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}
.HQ8yf .Vwe4Vb {
  background-image: radial-gradient(
    circle farthest-side,
    rgba(66, 133, 244, 0.25),
    rgba(66, 133, 244, 0.25) 80%,
    rgba(66, 133, 244, 0) 100%
  );
}
.e3Duub .Vwe4Vb {
  background-image: radial-gradient(
    circle farthest-side,
    #3367d6,
    #3367d6 80%,
    rgba(51, 103, 214, 0) 100%
  );
}
.UxubU .Vwe4Vb {
  background-image: radial-gradient(
    circle farthest-side,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.O0WRkf.RDPZE {
  box-shadow: none;
  color: rgba(68, 68, 68, 0.502);
  cursor: default;
  fill: rgba(68, 68, 68, 0.502);
}
.zZhnYe.RDPZE {
  background: rgba(153, 153, 153, 0.1);
}
.UxubU.RDPZE {
  color: rgba(255, 255, 255, 0.502);
  fill: rgba(255, 255, 255, 0.502);
}
.UxubU.zZhnYe.RDPZE {
  background: rgba(204, 204, 204, 0.1);
}
.CwaK9 {
  position: relative;
}
.RveJvd {
  display: inline-block;
  margin: 0.5em;
}
.mUbCce {
  -webkit-user-select: none;
  transition: background 0.3s;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 48px;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  width: 48px;
  z-index: 0;
}
.mUbCce > .TpQm9d {
  height: 48px;
  width: 48px;
}
.mUbCce.u3bW4e,
.mUbCce.qs41qe,
.mUbCce.j7nIZb {
  -webkit-transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}
.YYBxpf {
  border-radius: 0;
  overflow: visible;
}
.YYBxpf.u3bW4e,
.YYBxpf.qs41qe,
.YYBxpf.j7nIZb {
  -webkit-mask-image: none;
}
.fKz7Od {
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
}
.p9Nwte {
  color: rgba(255, 255, 255, 0.75);
  fill: rgba(255, 255, 255, 0.75);
}
.fKz7Od.u3bW4e {
  background-color: rgba(0, 0, 0, 0.12);
}
.p9Nwte.u3bW4e {
  background-color: rgba(204, 204, 204, 0.25);
}
.YYBxpf.u3bW4e {
  background-color: transparent;
}
.VTBa7b {
  transform: translate(-50%, -50%) scale(0);
  transition: opacity 0.2s ease, visibility 0s ease 0.2s, transform 0s ease 0.2s;
  -webkit-transition: opacity 0.2s ease, visibility 0s ease 0.2s,
    transform 0s ease 0.2s, -webkit-transform 0s ease 0.2s;
  transition: opacity 0.2s ease, visibility 0s ease 0.2s, transform 0s ease 0.2s,
    -webkit-transform 0s ease 0.2s;
  background-size: cover;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  visibility: hidden;
}
.YYBxpf.u3bW4e .VTBa7b {
  animation: quantumWizIconFocusPulse 0.7s infinite alternate;
  height: 100%;
  left: 50%;
  top: 50%;
  width: 100%;
  visibility: visible;
}
.mUbCce.qs41qe .VTBa7b {
  transform: translate(-50%, -50%) scale(2.2);
  opacity: 1;
  visibility: visible;
}
.mUbCce.qs41qe.M9Bg4d .VTBa7b {
  -webkit-transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.mUbCce.j7nIZb .VTBa7b {
  transform: translate(-50%, -50%) scale(2.2);
  visibility: visible;
}
.fKz7Od .VTBa7b {
  background-image: radial-gradient(
    circle farthest-side,
    rgba(0, 0, 0, 0.12),
    rgba(0, 0, 0, 0.12) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}
.p9Nwte .VTBa7b {
  background-image: radial-gradient(
    circle farthest-side,
    rgba(204, 204, 204, 0.25),
    rgba(204, 204, 204, 0.25) 80%,
    rgba(204, 204, 204, 0) 100%
  );
}
.mUbCce.RDPZE {
  color: rgba(0, 0, 0, 0.26);
  fill: rgba(0, 0, 0, 0.26);
  cursor: default;
}
.p9Nwte.RDPZE {
  color: rgba(255, 255, 255, 0.502);
  fill: rgba(255, 255, 255, 0.502);
}
.xjKiLb {
  position: relative;
  top: 50%;
}
.xjKiLb > span {
  display: inline-block;
  position: relative;
}
.llhEMd {
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
  background-color: rgba(31, 31, 31, 0.6);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5000;
}
.llhEMd.iWO5td {
  transition: opacity 0.05s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}
.mjANdc {
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-box-align: center;
  box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  bottom: 0;
  left: 0;
  padding: 0 5%;
  position: absolute;
  right: 0;
  top: 0;
}
.x3wWge,
.ONJhl {
  display: block;
  height: 3em;
}
.eEPege > .x3wWge,
.eEPege > .ONJhl {
  box-flex: 1;
  flex-grow: 1;
}
.J9Nfi {
  flex-shrink: 1;
  max-height: 100%;
}
.g3VIld {
  -webkit-box-align: stretch;
  box-align: stretch;
  align-items: stretch;
  display: flex;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  transition: transform 0.225s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: transform 0.225s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.225s cubic-bezier(0, 0, 0.2, 1);
  transition: transform 0.225s cubic-bezier(0, 0, 0.2, 1),
    -webkit-transform 0.225s cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24);
  max-width: 24em;
  outline: 1px solid transparent;
  overflow: hidden;
}
.vcug3d .g3VIld {
  padding: 0;
}
.g3VIld.kdCdqc {
  transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1);
  -webkit-transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1),
    -webkit-transform 0.15s cubic-bezier(0.4, 0, 1, 1);
  transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1),
    -webkit-transform 0.15s cubic-bezier(0.4, 0, 1, 1);
}
.Up8vH.CAwICe {
  transform: scale(0.8);
}
.Up8vH.kdCdqc {
  transform: scale(0.9);
}
.vcug3d {
  -webkit-box-align: stretch;
  box-align: stretch;
  align-items: stretch;
  padding: 0;
}
.vcug3d > .g3VIld {
  box-flex: 2;
  flex-grow: 2;
  border-radius: 0;
  left: 0;
  right: 0;
  max-width: 100%;
}
.vcug3d > .ONJhl,
.vcug3d > .x3wWge {
  box-flex: 0;
  flex-grow: 0;
  height: 0;
}
.tOrNgd {
  display: flex;
  flex-shrink: 0;
  font: 500 20px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  padding: 24px 24px 20px 24px;
}
.vcug3d .tOrNgd {
  display: none;
}
.TNczib {
  box-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-shrink: 0;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: #455a64;
  color: white;
  display: none;
  font: 500 20px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
}
.vcug3d .TNczib {
  display: flex;
}
.PNenzf {
  box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  word-wrap: break-word;
}
.TNczib .PNenzf {
  margin: 16px 0;
}
.VY7JQd {
  height: 0;
}
.TNczib .VY7JQd,
.tOrNgd .bZWIgd {
  display: none;
}
.R6Lfte .Wtw8H {
  flex-shrink: 0;
  display: block;
  margin: -12px -6px 0 0;
}
.PbnGhe {
  box-flex: 2;
  flex-grow: 2;
  flex-shrink: 2;
  display: block;
  font: 400 14px/20px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  padding: 0 24px;
  overflow-y: auto;
}
.Whe8ub .PbnGhe {
  padding-top: 24px;
}
.hFEqNb .PbnGhe {
  padding-bottom: 24px;
}
.vcug3d .PbnGhe {
  padding: 16px;
}
.XfpsVe {
  display: flex;
  flex-shrink: 0;
  box-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 24px 24px 16px 24px;
}
.vcug3d .XfpsVe {
  display: none;
}
.OllbWe {
  box-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  display: none;
}
.vcug3d .OllbWe {
  display: flex;
  -webkit-box-align: start;
  box-align: start;
  align-items: flex-start;
  margin: 0 16px;
}
.kHssdc.O0WRkf.C0oVfc,
.XfpsVe .O0WRkf.C0oVfc {
  min-width: 64px;
}
.kHssdc + .kHssdc {
  margin-left: 8px;
}
.TNczib .kHssdc {
  color: #fff;
  margin-top: 10px;
}
.TNczib .Wtw8H {
  margin: 4px 24px 4px 0;
}
.TNczib .kHssdc.u3bW4e,
.TNczib .Wtw8H.u3bW4e {
  background-color: rgba(204, 204, 204, 0.25);
}
.TNczib .kHssdc > .Vwe4Vb,
.TNczib .Wtw8H > .VTBa7b {
  background-image: radial-gradient(
    circle farthest-side,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.TNczib .kHssdc.RDPZE,
.TNczib .Wtw8H.RDPZE {
  color: rgba(255, 255, 255, 0.5);
  fill: rgba(255, 255, 255, 0.5);
}
.CJRWze {
  color: #5f6368;
  box-flex: 1;
  flex-grow: 1;
  font-size: 12px;
  font-weight: 400;
}
.CwHtnf {
  border: none;
  display: block;
  height: 100%;
  padding: 0;
  width: 100%;
}
.bYmtV.g3VIld {
  background: #fff;
  border-radius: 8px;
  color: #5f6368;
  letter-spacing: 0.25px;
}
.bYmtV.g3VIld .qRUolc {
  color: #202124;
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3333;
  padding-bottom: 1px;
  padding-top: 23px;
}
.bYmtV .XfpsVe.J9fJmf {
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.bYmtV .oJeWuf {
  font-family: roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: 14px;
  line-height: 1.4286;
  margin-top: 16px;
}
.bYmtV .J9fJmf {
  align-items: center;
  padding: 16px 24px;
}
.bYmtV .J9fJmf .O0WRkf {
  font-family: "Google Sans", "Noto Sans Myanmar UI", arial, sans-serif;
  font-size: inherit;
  letter-spacing: 0.25px;
  line-height: 36px;
  border-radius: 4px;
  text-transform: none;
}
.bYmtV .J9fJmf .oG5Srb {
  box-shadow: none;
  color: #1a73e8;
}
.bYmtV .J9fJmf .oG5Srb:hover {
  background: #f6fafe;
}
.bYmtV .J9fJmf .oG5Srb.u3bW4e {
  background: #e8f0fd;
}
.bYmtV .J9fJmf .snByac {
  line-height: 1.4286;
}
.bYmtV .CxRgyd {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.bYmtV.Zttm2 {
  border-radius: 0;
}
.bYmtV.Zttm2 .OllbWe {
  display: none;
}
.bYmtV.Zttm2 .J9fJmf {
  display: inherit;
}
.bYmtV.Zttm2 .oJeWuf {
  padding: 0 24px;
  margin-top: 0;
}
.bYmtV.Zttm2 .TNczib {
  background-color: transparent;
  box-shadow: none;
  color: inherit;
  padding: 24px 24px 0;
}
.bYmtV.Zttm2 .pPQgvf .Ce1Y1c {
  fill: #5f6368;
}
.bYmtV.Zttm2 .bZWIgd {
  display: none;
}
.bYmtV.UPqaPb .bZWIgd {
  display: block;
}
.bYmtV.UPqaPb .Wtw8H {
  margin: -12px -6px 0 0;
}
.bYmtV.CSqzt .oJeWuf {
  margin-top: 24px;
}
.bYmtV.CSqzt .X1clqd {
  display: none;
}
.bYmtV.CSqzt.kdCdqc,
.bYmtV.CSqzt {
  transition-property: none;
}
.bYmtV.gGihsd .lw1w4b {
  position: relative;
}
.bYmtV.gGihsd .lw1w4b:focus-visible::before {
  border-radius: 8px;
  border: 2px solid #1a73e8;
  bottom: 0;
  content: "";
  left: 0;
  outline: none;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
}
.FKF6mc,
.FKF6mc:focus {
  display: block;
  outline: none;
  text-decoration: none;
}
.FKF6mc:visited {
  fill: inherit;
  stroke: inherit;
}
.U26fgb.u3bW4e {
  outline: 1px solid transparent;
}
.fb0g6 {
  position: relative;
}
.C0oVfc {
  line-height: 20px;
  min-width: 88px;
}
.C0oVfc .RveJvd {
  margin: 8px;
}
sentinel {
}
