/* By default, hide features for javascript-disabled browsing */
/* We use !important to override any css with higher specificity */
/* It is also overriden by the styles in <noscript> in the header file */
.no-js {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
